import React, { useState, useEffect } from "react";
import { Container, Card, Button, Table, InputGroup, FormControl } from "react-bootstrap";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";
import PaymentForm from '../../components/PaymentForm';
import "../css/payment.css";
import app from "../../components/base";
import "../../components/constants";
import { PROGRAM_PAYMENT, SANDBOX_APPID } from "../../components/constants";
import { useHistory } from "react-router-dom";

export default function ProgramPayment() {
  let history = useHistory();
  const [confname, setConfName] = useState("");
  const [confabbr, setConfAbbr] = useState("");

  app.auth().onAuthStateChanged(function (user) {
    if (user != null) {
      app.database().ref('/users/' + user.uid).once('value').then(function (snapshot) {
        setConfName(snapshot.val().confname);
        setConfAbbr(snapshot.val().confabbr);
      });
    } else {
      this.name = "Unknown";
    }
  });


  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let sqPaymentScript = document.createElement('script');
    sqPaymentScript.crossOrigin = "";
    sqPaymentScript.src = "https://js.squareupsandbox.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript"
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      if (!loaded) {
        setLoaded(true);
      }
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }, [loaded]);

  const handleCupon = () => {
    history.push("/add-new");
  }

  const handleSquare = () => {
    history.push("/add-new");
  }

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          style={{ background: "white" }}
        >
          <Container>
            <div
              className="row"
            >
              <div className="col-md-12" style={{ textAlign: "center" }}>
                <h1
                  style={{ fontFamily: "Open Sans", fontWeight: "400", marginTop: "40px", marginBottom: "30px", color: "#3493ee" }}
                >
                  Enjoy your Program
              </h1>
              </div>
              <div className="col-md-6">
                <Card style={{ borderRadius: "20px" }}>
                  <Card.Body>
                    <Card.Title>{confname}</Card.Title>
                    <Card.Text>
                      {confabbr}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card bg="light" style={{ marginTop: "30px", textAlign: "center", borderRadius: "20px" }}>
                  <Card.Body>
                    <Card.Title style={{ color: "#3493ee", fontFamily: "Open Sans" }}>Order Overview</Card.Title>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>Yearly Subscription</td>
                          <td>$25</td>
                        </tr>
                        <tr>
                          <td>Sales Tax(7.975%)</td>
                          <td>$1.99</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>$26.99</td>
                        </tr>
                      </tbody>
                    </Table>
                    <InputGroup className="mb-3" style={{ borderRadius: "20px" }}>
                      <FormControl
                        placeholder="Coupon Code"
                        aria-label="Coupon Code"
                        aria-describedby="basic-addon2"
                        style={{
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                      />
                      <InputGroup.Append>
                        <Button variant="outline-primary"
                          style={{
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                          }}
                          onClick={handleCupon}
                        >
                          Apply
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Card.Body>
                </Card>


              </div>
              <div className="col-md-6">

                {loaded ?
                  <PaymentForm
                    paymentForm={window.SqPaymentForm}
                    handleSquare={handleSquare}
                    paymentUrl={PROGRAM_PAYMENT}
                    appId={SANDBOX_APPID}
                  /> : null
                }
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
