import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { Card, CardHeader, IconButton, CardContent } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { shallowEqual, useSelector } from "react-redux";
import app from '../../components/base';
import LargeSearchBar from './common_component/LargeSearchBar';
import StatisticsSlider from "./dashboard_component/StatisticsSlider";
import * as auth from "../../store/ducks/auth.duck";
import "../../pages/css/payment.css";
import "./program_component/css/cuponstyle.scss";
import "./css/all_programs.scss";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";


function YearProgram() {

    const { year } = useParams();

    const { currentUser } = useSelector(({ auth }) => ({ currentUser: auth.user }), shallowEqual);
    const [showState, setShowState] = useState(false);
    const [yearPrograms, setYearPrograms] = useState([]);

    useEffect(() => {
        if (!showState) {
            setShowState(true);
            const adminId = currentUser.user.uid;
            app.database().ref(`admins/${adminId}`).once('value', adminSnap => {
                app.database().ref(`programs`).orderByChild('adminId').equalTo(adminId).once('value', programSnap => {
                    app.database().ref(`transactions`).orderByChild('adminId').equalTo(adminId).once('value', transactionSnap => {
                        app.database().ref(`users`).orderByChild('adminId').equalTo(adminId).once('value', userSnap => {
                            const users = userSnap.val();
                            const admin = adminSnap.val();
                            const programs = programSnap.val();
                            const transactions = transactionSnap.val();
                            const programIds = admin.programIds;
                            // const yearsArray = Object.keys(programIds).sort((a, b) => { return a - b });
                            // setYears(yearsArray);

                            // yearsArray.forEach(year => {
                            const yearKeys = programIds[year];
                            const yearPrograms = yearKeys.map(key => {
                                let oneProgram = programs[key];
                                oneProgram.programId = key;
                                let totalAmount = 0;
                                if (transactions) {
                                    Object.keys(transactions).forEach(transactionId => {
                                        const transaction = transactions[transactionId];
                                        if (transaction.programId === key) {
                                            totalAmount += transaction.transaction.amount_money.amount / 100;
                                        }
                                    })
                                }
                                oneProgram.totalAmount = totalAmount;
                                let studentCount = 0;
                                if (users) {
                                    Object.keys(users).forEach(userId => {
                                        const user = users[userId];
                                        if (user.programId === key && user.role === "Student") {
                                            studentCount++;
                                        }
                                    })
                                }
                                const goalAmount = studentCount * 3000;
                                oneProgram.goalAmount = goalAmount;
                                const percent = !isNaN(totalAmount / goalAmount * 100) ? totalAmount / goalAmount * 100 : 0;
                                oneProgram.percent = Math.round(percent);
                                return oneProgram;
                            });
                            // })
                            setYearPrograms(yearPrograms);

                        })

                    })
                })
            })
        }
    }, [showState, currentUser.user.uid, year])

    return (
        <>
            <div className="row inventory_submenu">
                <div className="col-md-3">
                    <h4
                        style={{
                            fontSize: "30px",
                            fontWeight: "normal",
                            color: "#111111",
                            marginRight: "36px"
                        }}
                    >
                        All Programs in {year}
                    </h4>
                </div>
                <div className="col-md-9">
                    <LargeSearchBar placeholder="Search programs, year" />
                </div>
            </div>

            {
                // Object.keys(allPrograms).length > 0 ?
                //     years.map(year => {
                <div className="row" style={{ marginTop: "53px" }}>
                    <div className="col-xl-9 program_cards">
                        <div className="row">
                            {
                                yearPrograms.map((program, i) => {
                                    return <div key={i} className={`col-md-4 program_card_type${i % 3}`} style={{ marginTop: "20px" }}>
                                        <Card style={{ paddingTop: "19px", paddingBottom: "30px", fontSize: "20px", fontWeight: "bold" }}>
                                            <CardHeader
                                                style={{
                                                    paddingLeft: "30px",
                                                    paddingRight: "10px",
                                                    paddingBottom: "9px"
                                                }}
                                                action={
                                                    <IconButton aria-label="Settings">
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                }
                                                title={program.name}
                                                titleTypographyProps={{ variant: 'h4' }}
                                                subheader={program.year}
                                            >
                                            </CardHeader>

                                            <CardContent
                                                style={{
                                                    paddingTop: "9px",
                                                    paddingLeft: "30px",
                                                    paddingRight: "30px",
                                                }}
                                            >
                                                <StatisticsSlider title={program.totalAmount.toFixed(2)} value={program.percent} />
                                                <div>
                                                    <div style={{ float: "left" }}>
                                                        <span>{program.percent}%</span> Of program Goal
                                                            </div>
                                                    <div style={{ float: "right" }}>
                                                        To Goal: <span>${program.goalAmount}</span>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                // }) : null
            }

        </>
    );
}


export default connect(
    null,
    auth.actions
)(YearProgram);
