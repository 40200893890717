import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import {toAbsoluteUrl} from '../../../../_metronic';

export default function Header() {
  return (
    <div>
      <Navbar
        style={{
          paddingTop: 18,
          paddingBottom: 16,
          paddingLeft: 20,
          paddingRight: 20,
          borderBottom: "1px solid #d3d3d3", 
          background: "white"
        }}
      >
        <Navbar.Brand href="#home">
          <img
            src={toAbsoluteUrl("/media/logos/logo-main.png")}
            style={{ maxWidth: 232 }}
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
      </Navbar>
    </div>
  );
}