import React from "react";
import WithHeaderWindow from '../common_component/WithHeaderWindow';
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CancelIcon from '@material-ui/icons/Cancel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function SettingsBill3(props) {
    return (
        <div style={{ marginTop: 30, maxWidth: 1024 }}>
            <WithHeaderWindow title="Cancel your subscription">
                <div
                    style={{
                        padding: 40
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",

                        }}
                    >
                        <CancelIcon style={{ fontSize: 55, color: "#36435C", marginRight: 20 }} />
                        <h3
                            style={{
                                fontSize: 29,
                                fontWeight: "bold",
                                color: "#576377",
                                opacity: "0.7"
                            }}
                        >We really hate to see you go! How can we improve?</h3>
                    </div>
                    <div style={{
                        padding: "10px 40px"
                    }}>
                        <h3
                            style={{
                                fontSize: 29,
                                fontWeight: "bold",
                                color: "#111111",
                            }}
                        >Why are you canceling Youth Rush Pro?</h3>

                        <textarea rows="10" style={{ width: "100%", marginTop: 30, marginBottom: 15 }}></textarea>
                        <p style={{ fontSize: 14, color: "rgba(17, 17, 17, 0.7)" }}>
                            We will use this information to improve our system. Thank you for your honest feedback.
                        </p>
                        <hr />
                        <h4 style={{ marginTop: 30, marginBottom: 30, fontSize: 26, fontWeight: "bold", color: "rgba(17, 17, 17, 0.7)" }}>
                            If you cancel your subscription,
                        </h4>
                        <h5 style={{ fontSize: 18, fontWeight: "bold", color: "rgba(17, 17, 17, 0.7)" }}>You will forfeit access to:</h5>
                        <ul style={{ fontSize: 18, fontWeight: "normal", color: "rgba(17, 17, 17, 0.7)", lineHeight: "40px" }}>
                            <li>
                                Subscription to Youth Rush Pro
                            </li>
                            <li>
                                Ability to see all sales analytics data
                            </li>
                            <li>
                                Ability to add/ users/ products/ inventory
                            </li>
                        </ul>
                        <h5 style={{ fontSize: 18, fontWeight: "bold", color: "rgba(17, 17, 17, 0.7)" }}>You will not forfeit access to:</h5>
                        <ul style={{ fontSize: 18, fontWeight: "normal", color: "rgba(17, 17, 17, 0.7)", lineHeight: "40px" }}>
                            <li>
                                Your Youth Rush account
                            </li>
                            <li>
                                Any programs/ users you have created
                            </li>
                            <li>
                                Any saved payment methods
                            </li>
                        </ul>

                        <hr />

                        <h4 style={{ marginTop: 30, marginBottom: 10, fontSize: 26, fontWeight: "bold", color: "rgba(17, 17, 17, 0.7)" }}>
                            Confirm cancellation
                        </h4>
                        <p style={{ fontSize: 18, color: "rgba(17, 17, 17, 0.7)" }}>
                            <i>We will email you concerning the cancellation</i>
                        </p>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} sm="6" controlId="formGridPassword" style={{ marginBottom: 0 }}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="" />
                                </Form.Group>
                            </Form.Row>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // checked={state.checkedB}
                                        // onChange={handleChange('checkedB')}
                                        // value="checkedB"
                                        // color="primary"
                                    />
                                }
                                label="Yes, I really do want to cancel"
                                style={{ fontSize: 18, color: "rgba(17, 17, 17, 0.7)" }}
                            />

                            <div style={{
                                marginTop: 105,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Button
                                    style={{
                                        background: "none",
                                        color: "rgba(54, 146, 238, 0.7)",
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        maxWidth: "238px",
                                        height: "44px",
                                        borderRadius: "3px",
                                        border: "none"
                                    }}
                                >
                                    Just kidding, Don’t Cancel
                                </Button>
                                <Button
                                    style={{
                                        background: "#36435C",
                                        color: "white",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        maxWidth: "238px",
                                        height: "44px",
                                        borderRadius: "3px",
                                        paddingLeft: 40,
                                        paddingRight: 40
                                    }}
                                    onClick={props.handleCancelSubscription}
                                >
                                    Cancel Subscription
                                </Button>
                            </div>
                        </Form>

                    </div>
                </div>
            </WithHeaderWindow>
        </div>
    );
}
