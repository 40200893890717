import React from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";
import app from "../../components/base";
import ls from "local-storage";
import * as auth from "../../store/ducks/auth.duck";
import "../../pages/css/payment.css";
import "../../pages/css/cuponstyle.css";

// const styles = {
//     cupon: {
//         borderTopLeftRadius: "30px",
//         borderBottomLeftRadius: "30px",
//         height: "72px",
//         textAlign: "center",
//         fontSize: "27px",
//         fontWeight: "bold",
//     },
// }

function ReviewPay(props) {
    const { programCount } = useSelector(({ auth }) =>
        ({
            // currentStep: auth.activeStep,
            programCount: auth.programCount
        }),
        shallowEqual
    );

    // const yearlySubscription = 99;
    // const programCost = 49 * programCount;
    // const subTotal = yearlySubscription + programCost;
    // const tax = subTotal * 7.975 / 100;
    // const total = subTotal + tax;


    const handleClick = () => {
        app.auth().onAuthStateChanged(function (user) {
            if (user != null) {
                // var adminsRef = app.database().ref().child("admins");
                // adminsRef.child(user.uid).update({ "subflag": true });
                ls.set("youthrush_program_count", null);
                ls.set("youthrush_program_values", null);
                ls.set("youthrush_org", null);
                props.login(user.uid, true);
            } else {
                // alert("Login failed");
            }
        });
    }

    return (
        <>
            <div
                style={{ textAlign: "center", marginTop: "50px" }}
            >
                <h3
                    style={{ fontSize: "21px", fontWeight: "normal", color: "#014386" }}
                >
                    Congratulations. Your Account is now live!
                </h3>
                <p
                    style={{ fontSize: "18px", fontWeight: "light", color: "black" }}
                >
                    And You created {programCount} programs.
                </p>
            </div>
            <div
                style={{
                    textAlign: "center",
                    marginTop: "50px", 
                    marginBottom: "100px"
                }}
            >
                <button
                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                    style={{
                        height: "50px",
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        borderRadius: "25px"
                    }}
                    onClick={handleClick}
                >
                    Please Start
                </button>
            </div>
        </>
    );
}


export default connect(
    null,
    auth.actions
)(ReviewPay);
