import React, { useState, useRef, useEffect } from 'react';
import WhiteButton from "./common_component/WhiteButton";
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button"
import "./css/add_new_product.scss";
import MyDropDownField from "./inventory_component/MyDropDownField";
import Fab from '@material-ui/core/Fab';
import PublishIcon from '@material-ui/icons/Publish';
import Switch from '@material-ui/core/Switch';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { toAbsoluteUrl } from "../../../_metronic";
import { GET_CATEGORY } from "../../components/constants";
// import { GET_PRODUCT } from "../../components/constants";
import app from "../../components/base";
import { useParams } from 'react-router'
import { useHistory } from "react-router-dom";
import CircularIntegration from "./common_component/CircularIntegration";
// import ls from 'local-storage';

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
        background: "#36425C",
        color: "white"
    },
}));

const CustomSwitch = withStyles({
    root: {
        width: "69px",
        height: "44px",
    },
    switchBase: {
        color: 'white',
        // backgroundColor: "#CDDC39",
        '&$checked': {
            color: '#CDDC39 !important',
        },
        '&$checked + $track': {
            backgroundColor: '#CDDC39 !important',
            opacity: "1",
        },
    },
    checked: {
        // backgroundColor: "black",
    },
    track: {
        backgroundColor: "#f1f2f8",
        height: "20px",
        marginTop: "-3px",
        opacity: "1",
        border: "1px solid #CDDC39"
    },
    thumb: {
        border: "4px solid white",
        width: "28px",
        height: "28px",
        marginTop: "-4px",
    }
})(Switch);


const catBackColor = {};
const categoryOption = [];
const categoryIds = [];

fetch(GET_CATEGORY, {
    method: "GET"
})
    .then(response => {
        return response.text();
    })
    .then(data => {
        // setRows(JSON.parse(data));
        const categories = JSON.parse(data);
        if (categories) {
            Object.keys(categories).forEach(key => {
                var category = categories[key];
                categoryOption.push(category.category);
                categoryIds.push(key);
                catBackColor[category.category] =
                    typeof category.color === "string" ?
                        category.color :
                        `rgba(${category.color.r}, ${category.color.g}, ${category.color.b}, ${category.color.a})`;
            })
        }
    })


export default function EditProduct() {
    const history = useHistory();
    let { edit_key } = useParams();

    // const main_product = ls.get('edit_product');

    const fileForUpload = useRef(null);
    const classes = useStyles();

    const [title, setTitle] = useState("");
    const [price, setPrice] = useState(0);
    const [tags, setTags] = useState("");
    const [stock, setStock] = useState(0);
    const [category, setCategory] = useState("");
    const [image, setImage] = useState(null);
    const [active, setActive] = useState(false);
    const [showImage, setShowImage] = useState("");

    const [showState, setShowState] = useState(false);
    const [showDelete, setShowDelete] = useState(false);


    useEffect(() => {
        if (!showState) {
            setShowState(true);
            app.auth().onAuthStateChanged(user => {
                app.database().ref().child("admins/" + user.uid).once("value")
                    .then(adminSnap => {
                        const activeProductIds = adminSnap.val().activeProductIds ? adminSnap.val().activeProductIds : [];
                        var productRef = app.database().ref().child("products/" + edit_key);
                        productRef.once("value")
                            .then(snapshot => {
                                const product = snapshot.val();
                                setTitle(product.product);
                                setPrice(product.price);
                                setTags(product.tags);
                                setStock(product.stock);
                                setCategory(categoryOption[categoryIds.indexOf(product.categoryId)]);
                                setShowImage(product.image);
                                setActive(activeProductIds.indexOf(edit_key) > -1);
                                setShowDelete(product.adminId !== "master");
                            })
                    })
            })
        }
    }, [showState, edit_key])

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleCategoryClick = category => {
        setCategory(category);
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    }

    const handleStockChange = (e) => {
        setStock(e.target.value);
    }

    const handleTagsChange = (e) => {
        setTags(e.target.value);
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();

            reader.onload = function (ev) {
                setShowImage(ev.target.result);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleDelete = () => {
        var productRef = app.database().ref().child("products/" + edit_key);
        productRef.remove()
            .then(function () {
                history.push("/inventory");
            })
            .catch(error => {
                console.log("Remove failed:" + error.message);
            })
    }

    const handleSwitch = () => {
        setActive(!active);
    }


    function createData(image, product, stock, price, tags, active, categoryId) {
        return { image, product, stock, price, tags, active, categoryId };
    }

    const handleSubmit = () => {
        if (title === "") {
            alert("Please fill the Title textfield.");
        }

        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }

        app.auth().onAuthStateChanged(function (user) {
            if (image != null) {
                const uploadTask = app.storage().ref(`book_covers/${image.name}`).put(image);
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {

                }, () => {
                    app.storage().ref('book_covers').child(image.name).getDownloadURL().then(url => {
                        var productRef = app.database().ref().child("products/" + edit_key);
                        productRef.update(createData(url, title, stock, price, tags, active, categoryIds[categoryOption.indexOf(category)]));

                        app.database().ref().child("admins/" + user.uid).once('value')
                            .then(snapshot => {
                                const beforeProductIds = snapshot.val().activeProductIds ? snapshot.val().activeProductIds : [];
                                const value = beforeProductIds.indexOf(edit_key);

                                if (active) {
                                    if (value === -1) {
                                        app.database().ref().child("admins/" + user.uid).update({ activeProductIds: [...beforeProductIds, edit_key] })
                                    }
                                } else {
                                    if (value > -1) {
                                        beforeProductIds.splice(value, 1);
                                        app.database().ref().child("admins/" + user.uid).update({ activeProductIds: [beforeProductIds] })
                                    }
                                }

                                setSuccess(true);
                                setLoading(false);
                            })
                    });
                })
            } else {
                var productRef = app.database().ref().child("products/" + edit_key);
                productRef.update(createData(showImage, title, stock, price, tags, active, categoryIds[categoryOption.indexOf(category)]));
                app.database().ref().child("admins/" + user.uid).once('value')
                    .then(snapshot => {
                        const beforeProductIds = snapshot.val().activeProductIds ? snapshot.val().activeProductIds : [];
                        const value = beforeProductIds.indexOf(edit_key);

                        if (active) {
                            if (value === -1) {
                                app.database().ref().child("admins/" + user.uid).update({ activeProductIds: [...beforeProductIds, edit_key] })
                            }
                        } else {
                            if (value > -1) {
                                beforeProductIds.splice(value, 1);
                                app.database().ref().child("admins/" + user.uid).update({ activeProductIds: [...beforeProductIds] })
                            }
                        }

                        setSuccess(true);
                        setLoading(false);
                    })
            }
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-9">
                    <div className="row inventory_submenu">
                        <h4
                            className="col-md-4"
                            style={{
                                fontSize: "30px",
                                fontWeight: "normal",
                                color: "#111111",
                                marginRight: "36px"
                            }}
                        >
                            Edit Product
                    </h4>
                        <div className="col-md-8" style={{ maxWidth: "375px" }}>
                            <WhiteButton handleClick={() => { history.push("/inventory") }} caption="View all Products" addIcon />
                        </div>
                    </div>
                    <div
                        className="content"
                        style={{
                            border: "1px solid #D4D6D9",
                            marginTop: "112px",
                            padding: "35px",
                            borderRadius: "5px"
                        }}
                    >
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} sm="7" controlId="formGridTitle">
                                    <Form.Label>Product Title</Form.Label>
                                    <Form.Control value={title} onChange={handleTitleChange} type="text" placeholder="" />
                                </Form.Group>

                                <Form.Group as={Col} sm="5" controlId="formGridPrice" style={{ paddingLeft: "50px" }}>
                                    <Form.Label>Price</Form.Label><br />
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ fontSize: 20, fontWeight: "bold", color: "rgba(17, 17, 17, 0.6)" }}>$</span>
                                        <Form.Control value={price} onChange={handlePriceChange} type="number" placeholder="" />
                                    </div>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="7" controlId="formGridTags">
                                    <Form.Label>Product Tags</Form.Label>
                                    <Form.Control value={tags} onChange={handleTagsChange} type="text" placeholder="" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="5" controlId="formGridStock">
                                    <Form.Label>Product Stock</Form.Label>
                                    <Form.Control value={stock} onChange={handleStockChange} type="text" placeholder="" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="5" controlId="formGridCategory">
                                    <Form.Label>Select Category</Form.Label>
                                    <MyDropDownField value={category} options={categoryOption} handleClick={handleCategoryClick} defaultIndex={categoryOption.indexOf(category)} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="5" controlId="formGridImage">
                                    <Form.Label>Add Product Image</Form.Label>
                                    <div style={{ width: "100%", border: "1px solid #B8B9BC", borderRadius: "3px", textAlign: "center" }}>
                                        <Fab aria-label="Upload" className={classes.fab} onClick={() => { fileForUpload.current.click() }}>
                                            <PublishIcon />
                                        </Fab>
                                        <span>{image != null ? image.name : null}</span>
                                    </div>
                                    <Form.Control ref={fileForUpload} onChange={handleImageChange} style={{ display: "none" }} type="file" placeholder="" />
                                </Form.Group>
                            </Form.Row>

                            <CircularIntegration
                                caption="Save"
                                loading={loading}
                                success={success}
                                handleButtonClick={handleSubmit}
                            />
                        </Form>

                    </div>
                </div>
                <div className="col-xl-3">
                    <div
                        className="right_content"
                        style={{
                            border: "1px solid #D4D6D9",
                            padding: "20px 40px",
                            borderRadius: "5px",
                            marginTop: "20px",
                            paddingBottom: "60px"
                        }}
                    >
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-xl-12">
                                <img
                                    className="img-fluid"
                                    // style={{ maxWidth: "349px" }}
                                    alt="book"
                                    src={showImage}
                                />
                            </div>
                            <div className="col-sm-6 col-md-6 col-xl-12">
                                <h1
                                    style={{
                                        fontSize: "30px",
                                        fontWeight: "bold",
                                        color: "#707070",
                                        marginTop: "20px",
                                        marginBottom: "20px"
                                    }}
                                >{title}</h1>

                                <span
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "500",
                                        color: "white",
                                        background: catBackColor[category],
                                        padding: "5px 30px",
                                        borderRadius: "3px",
                                    }}
                                >{category}</span>
                                <p
                                    style={{
                                        fontSize: "28px",
                                        fontWeight: "normal",
                                        color: "#707070",
                                        marginTop: "20px"
                                    }}
                                >
                                    {tags}<br />
                                    Current stock. # {stock}
                                </p>
                                <span
                                    style={{
                                        fontSize: "35px",
                                        fontWeight: "normal",
                                        color: "#36425C",
                                        marginTop: "20px"
                                    }}
                                >${price}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div style={{ float: "right", marginTop: "213px" }}>
                <span
                    style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#36425C",
                        marginRight: "30px"
                    }}
                >
                    Active Product
                </span>
                <CustomSwitch checked={active} onClick={handleSwitch} /><br />
                {
                    showDelete ?
                        <Button
                            type="button"
                            onClick={handleDelete}
                            style={{
                                background: "#C52129",
                                color: "white",
                                fontSize: "16px",
                                fontWeight: "bold",
                                width: "216px",
                                height: "44px",
                                borderRadius: "3px",
                                float: "right"
                            }}
                        >
                            Delete Product
                    </Button> : null
                }
            </div>

        </>
    )
}
