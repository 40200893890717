import React from "react";
import WithHeaderWindow from '../common_component/WithHeaderWindow';
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { toAbsoluteUrl } from "../../../../_metronic";

export default function SettingsBill3(props) {
    return (
        <div style={{ marginTop: 30, maxWidth: 1024 }}>
            <WithHeaderWindow title="Add New Payment Method">
                <div
                    style={{
                        padding: 40
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "center",
                            marginTop: 40
                        }}
                    >
                        <img
                            alt="notification"
                            width="80px"
                            // style={{border: "3px solid white"}}
                            className="img-fluid"
                            src={toAbsoluteUrl("/media/logos/credit-card@2x.png")}
                        />
                        <div style={{ marginLeft: "10px" }}>
                            <h3
                                style={{ fontSize: 29, fontWeight: "bold", color: "#111111", opacity: "0.7" }}
                            >Add a card to your account</h3>
                        </div>
                    </div>
                    <Form style={{ marginTop: 20 }}>
                        <Form.Row>
                            <Form.Group as={Col} sm="12" controlId="formGridCardholder">
                                <Form.Label>Cardholder's Name</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="8" controlId="formGridCardNumber">
                                <Form.Label>Credit Card Number</Form.Label>
                                <Form.Control type="text" placeholder="4111 1111 1111 1111" />
                            </Form.Group>
                            <Form.Group as={Col} sm="4" controlId="formGridCardIcon"
                                style={{
                                    fontSize: "50px",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "space-between",
                                    paddingLeft: 30,
                                    paddingRight: 10
                                }}>
                                <i className="fab fa-cc-visa" />
                                <i className="fab fa-cc-mastercard" />
                                <i className="fab fa-cc-amex" />
                                <i className="fab fa-cc-discover" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="4" controlId="formGridMonth">
                                <Form.Label>Exp. Month(MM)</Form.Label>
                                <Form.Control type="text" placeholder="MM" />
                            </Form.Group>
                            <Form.Group as={Col} sm="4" controlId="formGridYear">
                                <Form.Label>Exp. Year(YYYY)</Form.Label>
                                <Form.Control type="text" placeholder="YYYY" />
                            </Form.Group>
                            <Form.Group as={Col} sm="4" controlId="formGridCVV">
                                <Form.Label>CVV</Form.Label>
                                <Form.Control type="text" placeholder="123" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="8" controlId="formGridCountry">
                                <Form.Label>Country</Form.Label>
                                <Form.Control type="text" placeholder="United States of America" />
                            </Form.Group>
                            <Form.Group as={Col} sm="4" controlId="formGridZip">
                                <Form.Label>Zip/Postal Code</Form.Label>
                                <Form.Control type="text" placeholder="11111" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="12" controlId="formGridDescription">
                                <Form.Label>Credit card description (e.g. home, work)</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                        </Form.Row>

                        <div
                            style={{
                                width: "100%",
                                height: 70,
                                borderBottom: "1px solid #EAEAEA",
                                marginBottom: 60
                            }}
                        ></div>

                        <h3 style={{ fontSize: 25, textAlign: "center", marginBottom: 30 }}>Apply to existing subscriptions?</h3>

                        <div className="row"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <label
                                className="col-sm-6"
                                style={{
                                    maxWidth: 333,
                                    height: 66,
                                    fontSize: 18,
                                    padding: "19px 20px",
                                    textAlign: "left",
                                    border: "1px solid #EAEAEA",
                                    color: "rgba(17, 17, 17, 0.6)",
                                    borderRadius: 3,
                                    cursor: "pointer"
                                }}
                            >Yes, apply to subscriptions</label>
                            <label
                                className="col-sm-6"
                                style={{
                                    maxWidth: 333,
                                    height: 70,
                                    fontSize: 18,
                                    padding: "21px 20px",
                                    textAlign: "left",
                                    border: "2px solid #70C4D8",
                                    color: "rgba(17, 17, 17, 1)",
                                    borderRadius: 3,
                                    backgroundImage: `url(${toAbsoluteUrl("/media/bg/label_check.png")})`,
                                    backgroundPosition: "top right",
                                    backgroundRepeat: "no-repeat",
                                    cursor: "pointer"
                                }}
                            >No, just add the card</label>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: 70,
                                borderBottom: "1px solid #EAEAEA",
                                marginBottom: 30
                            }}
                        ></div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <Button
                                style={{
                                    background: "none",
                                    color: "#70C4D8",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    width: "216px",
                                    height: "44px",
                                    borderRadius: "22px",
                                    border: "none"
                                }}
                                onClick={props.handleBack}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{
                                    background: "#36435C",
                                    color: "white",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    width: "216px",
                                    height: "44px",
                                    borderRadius: "22px",
                                }}
                                onClick={props.handleBack}
                            >
                                ADD CARD
                            </Button>
                        </div>
                    </Form>
                </div>
            </WithHeaderWindow>
        </div>
    );
}
