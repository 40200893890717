import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthPageHome from "./AuthPageHome";
import AuthPageLogin from "./AuthPageLogin";
import AuthPageForgotPassword from "./AuthPageForgotPassword";
import AuthPageBill from "./AuthPageBill";
import AuthPageStep from "./AuthPageStep";


export default function AuthPage() {
  return (
    <>
      <Switch>
        <Redirect from="/auth" exact={true} to="/auth/home" />

        <Route path="/auth/login" component={AuthPageLogin} />
        <Route path="/auth/home" component={AuthPageHome} />
        <Route
          path="/auth/forgot-password"
          component={AuthPageForgotPassword}
        />
        <Route path="/auth/bill" component={AuthPageBill} />
        <Route path="/auth/step" component={AuthPageStep} />
      </Switch>
    </>
  );
}
