import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TeamsToday from './teams_component/TeamsToday'
import TeamsTomorrow from './teams_component/TeamsTomorrow'
import TeamsPast from './teams_component/TeamsPast'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import app from '../../components/base';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "none",
        // backgroundColor: theme.palette.background.paper,
    },
    fab: {
        margin: theme.spacing(1),
        background: "#36425C",
        color: "white",
        width: 36,
        height: 36,
        marginLeft: 30,
        opacity: 0.74
    },
}));

function getTodayDate() {
    // Get current date
    var date = new Date();

    // Format day/month/year to two digits
    var formattedDate = ('0' + date.getDate()).slice(-2);
    var formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
    var formattedYear = date.getFullYear().toString().substr(2, 2);

    // Combine and format date string
    var dateString = formattedMonth + '/' + formattedDate + '/' + formattedYear;

    // Reference output DIV
    return dateString;
}


export default function Teams() {
    const classes = useStyles();
    const { programId } = useParams();

    const [value, setValue] = React.useState(0);
    const [programName, setProgramName] = useState("");
    const [effectFlag, setEffectFlag] = useState(false);

    useEffect(() => {
        setEffectFlag(true);
    }, []);

    if (!effectFlag) {
        app.database().ref().child(`programs/${programId}`).once("value")
            .then(snapshot => {
                setProgramName(snapshot.val().name);
            })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <div>
            <div className="row inventory_submenu">
                <div className="col-xl-4">
                    <h4
                        style={{
                            fontSize: "30px",
                            fontWeight: "normal",
                            color: "#111111",
                        }}
                    >
                        Making teams
                    </h4>
                    <Tabs value={value} onChange={handleChange} aria-label="teams" variant="scrollable">
                        <Tab label="Today" {...a11yProps(0)} />
                        <Tab label="Tomorrow" {...a11yProps(1)} />
                        <Tab label="Past" {...a11yProps(2)} />
                    </Tabs>
                </div>
                <div className="col-xl-6 col-md-8" style={{ paddingTop: 19 }}>
                    <div className="row">
                        <div className="col-sm-5">
                            <label
                                style={{
                                    fontSize: 37,
                                    color: "rgba(17, 17, 17, 0.67)",
                                }}
                            >Current Team:</label>
                        </div>
                        <div className="col-sm-7"
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <label
                                style={{
                                    fontSize: 37,
                                    color: "rgba(52, 145, 236, 0.67)"
                                }}
                            >{programName}</label>
                            <Fab aria-label="ArrowDropDown" className={classes.fab}>
                                <ArrowDropDownIcon />
                            </Fab>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-md-4"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <label
                        style={{
                            fontSize: 43,
                            fontWeight: "bold",
                            color: "#636D7F"
                        }}
                    >{getTodayDate()}</label>
                    <Fab aria-label="ArrowDropDown" className={classes.fab}>
                        <ArrowDropDownIcon />
                    </Fab>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 22,
                    marginTop: 70
                }}
            >
                <label
                    style={{
                        fontSize: 30,
                        color: "#111111"
                    }}
                >Today's Team</label>
                <IconButton>
                    <MenuIcon style={{ fontSize: 30 }} />
                </IconButton>
            </div>

            <div className={classes.root}>
                <TabPanel value={value} index={0}>
                    <TeamsToday programId={programId} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TeamsTomorrow programId={programId} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TeamsPast programId={programId} />
                </TabPanel>
            </div>
        </div>
    );
}

