import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: "25px",
        backgroundColor: "#FAFAFA",
        // '&:hover': {
        //     backgroundColor: fade(theme.palette.common.white, 1),
        // },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        height: "49px", 
        maxWidth: "556px", 
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: theme.spacing(3),
        //     width: 'auto',
        // },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        float: "right", 
        color: "#585756", 
        top: 0, 
        right: 0
    },
    inputRoot: {
        color: 'inherit',
        height: "100%", 
        width: "80%", 
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 3),
        transition: theme.transitions.create('width'),
        fontFamily: "Open Sans", 
        fontSize: "14px", 
        fontWeight: "bold", 
        width: '100%',
        color: "black", 
        '&::placeholder': {
            color: "#36445C", 
        }, 
        // [theme.breakpoints.up('md')]: {
        //     width: 200,
        // },
    },
}));

export default function LargeSearchBar(props) {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.search} style={props.style}>
                <InputBase
                    placeholder={props.placeholder}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'Search' }}
                    onChange={(event) => props.handleChange(event.target.value)}
                />
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
            </div>
        </div>
    );
}