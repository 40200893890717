import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router';
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button"
import Switch from '@material-ui/core/Switch';
// import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import WhiteButton from "./common_component/WhiteButton";
import MyColorPicker from "./common_component/MyColorPicker";
import CircularIntegration from "./common_component/CircularIntegration";
import MyDropDownField from "./inventory_component/MyDropDownField";
import MyDropDownIcons from "./inventory_component/MyDropDownIcons";
import app from "../../components/base";
import "./css/add_new_product.scss";

// const useStyles = makeStyles(theme => ({
//     fab: {
//         margin: theme.spacing(1),
//         background: "#36425C",
//         color: "white"
//     },
// }));

const CustomSwitch = withStyles({
    root: {
        width: "69px",
        height: "44px",
    },
    switchBase: {
        color: 'white',
        // backgroundColor: "#CDDC39",
        '&$checked': {
            color: '#CDDC39 !important',
        },
        '&$checked + $track': {
            backgroundColor: '#CDDC39 !important',
            opacity: "1",
        },
    },
    checked: {
        // backgroundColor: "black",
    },
    track: {
        backgroundColor: "#f1f2f8",
        height: "20px",
        marginTop: "-3px",
        opacity: "1",
        border: "1px solid #CDDC39"
    },
    thumb: {
        border: "4px solid white",
        width: "28px",
        height: "28px",
        marginTop: "-4px",
    }
})(Switch);

const parentOptions = [""];
const iconOptions = ["diabetes_undone", "health_coaching", "home_repair",
"mail_in_study", "personal_bible_study", "plant_based_cooking_class",
"prayer_partners", "revelation_seminar", "stop_vaping_class", "vbx_for_kids"];

export default function EditInterestType() {
    const history = useHistory();
    const { programId, interestId } = useParams();

    const [color, setColor] = useState({ r: 0, g: 0, b: 0, a: 1 });
    const [icon, setIcon] = useState("");
    const [parent, setParent] = useState(parentOptions[0]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(false);
    const [showState, setShowState] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    // const classes = useStyles();


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (!showState) {
            setShowState(true);
            app.database().ref().child("programs/" + programId).once("value")
                .then(programSnap => {
                    const activeIntTypeIds = programSnap.val().activeIntTypeIds ? programSnap.val().activeIntTypeIds : [];
                    var intTypeRef = app.database().ref().child("interestTypes/" + interestId);
                    intTypeRef.once("value")
                        .then(snapshot => {
                            const type = snapshot.val();
                            setColor(type.color);
                            setIcon(type.icon);
                            setParent(type.parent);
                            setName(type.interestType);
                            setDescription(type.description);
                            setActive(activeIntTypeIds.indexOf(interestId) > -1);
                            setShowDelete(type.programId !== "master");
                        })
                })
        }
    }, [showState, programId, interestId])


    function handleColorChangeComplete(value) {
        setColor(value.rgb);
    }

    function handleParentClick(value) {
        setParent(value)
    }

    function handleIconClick(value) {
        setIcon(value);
    }

    function createData(interestType, icon, description, parent, color, active, programId) {
        return { interestType, icon, description, parent, color, active, programId };
    }

    const handleSubmit = () => {
        if (name === "") {
            alert("Please fill the Name textfield.")
        } else {

            if (!loading) {
                setSuccess(false);
                setLoading(true);
            }
            var intTypeRef = app.database().ref().child(`interestTypes/${interestId}`);
            intTypeRef.update(createData(name, icon, description, parent, color, active, programId));

            app.database().ref().child("programs/" + programId).once('value')
                .then(snapshot => {
                    const beforeTypeIds = snapshot.val().activeIntTypeIds ? snapshot.val().activeIntTypeIds : [];
                    const value = beforeTypeIds.indexOf(interestId);

                    if (active) {
                        if (value === -1) {
                            app.database().ref().child("programs/" + programId).update({ activeIntTypeIds: [...beforeTypeIds, interestId] })
                        }
                    } else {
                        if (value > -1) {
                            beforeTypeIds.splice(value, 1);
                            app.database().ref().child("programs/" + programId).update({ activeIntTypeIds: [...beforeTypeIds] })
                        }
                    }

                    setSuccess(true);
                    setLoading(false);
                })
            setSuccess(true);
            setLoading(false);

        };
    }


    const handleSwitch = () => {
        setActive(!active);
    }

    
    const handleDelete = () => {
        var intTypeRef = app.database().ref().child("interestTypes/" + interestId);
        intTypeRef.remove()
            .then(function () {
                history.push(`/interest-types/${programId}`)
            })
            .catch(error => {
                console.log("Remove failed:" + error.message);
            })
    }

    return (
        <div className="row">
            <div className="col-xl-9">
                <div className="row inventory_submenu">
                    <h4
                        className="col-md-4"
                        style={{
                            fontSize: "30px",
                            fontWeight: "normal",
                            color: "#111111",
                            marginRight: "36px"
                        }}
                    >
                        Edit Interest Type
                    </h4>
                    <div className="col-md-8" style={{ maxWidth: "375px" }}>
                        <WhiteButton handleClick={() => { history.push(`/interest-types/${programId}`) }} caption="View all Interest Types" addIcon />
                    </div>
                </div>
                <div
                    className="content"
                    style={{
                        border: "1px solid #D4D6D9",
                        marginTop: "112px",
                        padding: "35px",
                        borderRadius: "5px"
                    }}
                >
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} sm="7" controlId="formGridName">
                                <Form.Label>Interest Type Name</Form.Label>
                                <Form.Control type="text" onChange={e => { setName(e.target.value) }} value={name} placeholder="" />
                            </Form.Group>
                            <Form.Group as={Col} sm="5" controlId="formGridIcon">
                                <Form.Label>Icon</Form.Label>
                                <MyDropDownIcons value={icon} options={iconOptions} handleClick={handleIconClick} defaultIndex={iconOptions.indexOf(icon)} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="7" controlId="formGridDescription">
                                <Form.Label>Interest Type Description</Form.Label>
                                <Form.Control type="text" onChange={e => { setDescription(e.target.value) }} value={description} placeholder="" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="5" controlId="formGridParent">
                                <Form.Label>Parent</Form.Label>
                                <MyDropDownField value={parent} options={parentOptions} handleClick={handleParentClick} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="5" controlId="formGridColor">
                                <Form.Label>Select Color</Form.Label>
                                <MyColorPicker value={color} handleColorChangeComplete={handleColorChangeComplete} />
                            </Form.Group>

                            <Form.Group as={Col} sm="7" style={{ paddingTop: "45px" }}>
                                <div style={{ float: "right" }}>
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            color: "#36425C"
                                        }}
                                    >
                                        Active Interest Type
                                    </span>
                                    <CustomSwitch checked={active} onClick={handleSwitch} />
                                </div>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm="6">
                                <CircularIntegration
                                    caption="Save"
                                    loading={loading}
                                    success={success}
                                    handleButtonClick={handleSubmit}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="6">
                                {
                                    showDelete ?
                                        <Button
                                            // type="button"
                                            onClick={handleDelete}
                                            style={{
                                                background: "#C52129",
                                                color: "white",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                width: "216px",
                                                height: "44px",
                                                borderRadius: "3px",
                                                float: "right"
                                            }}
                                        >
                                            Delete Interest Type
                                </Button> : null
                                }
                            </Form.Group>
                        </Form.Row>
                    </Form>

                </div>
            </div>
        </div >
    )
}
