import React, { useState } from 'react';
// import { SEND_INTEREST_EMAIL } from '../../components/constants';
import { SEND_RESET_PASSWORD_EMAIL } from '../../components/constants';
// import { SEND_TRANSACTION_EMAIL } from '../../components/constants';


function SendEmail() {

  const [email, setEmail] = useState({
    recipient: '',
    sender: '',
    subject: '',
    text: '',
    html: ``
  });

  const sendEmail = _ => {
    fetch(SEND_RESET_PASSWORD_EMAIL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify({email: "sfupwork2019@gmail.com"})
    })
      // fetch(`${SEND_EMAIL}?recipient=${email.recipient}&sender=${email.sender}&topic=${email.subject}&text=${email.text}`)
      .then(response => console.log(response))
      .catch(err => console.log(err))
  }
  //   const sendEmail = () => {
  //     fetch(SEND_EMAIL, {method: "POST"})
  //       .then(response => console.log(response))
  //       .catch(err => console.log(err))
  //   }

  return (
    <div className="App">
      <div>
        <h2>Send Email</h2>
        <label>Recipient</label>
        <br />
        <input value={email.recipient}
          onChange={e => setEmail({ ...email, recipient: e.target.value })}
        />
        <br />
        <label>Sender</label>
        <br />
        <input value={email.sender}
          onChange={e => setEmail({ ...email, sender: e.target.value })}
        />
        <br />
        <label>Subject</label>
        <br />
        <input value={email.subject}
          onChange={e => setEmail({ ...email, subject: e.target.value })}
        />
        <br />
        <label>Message</label>
        <br />
        <textarea value={email.text}
          onChange={e => setEmail({ ...email, text: e.target.value })}
        />
        <br />
        <button onClick={sendEmail}>Send Email</button>
      </div>
    </div>
  );
}

export default SendEmail;
