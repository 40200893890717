import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import Inventory from "./Inventory";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import TotalPrograms from "./TotalPrograms";
import AllPrograms from "./AllPrograms";
import OneProgram from "./OneProgram";
import ProgramSales from "./ProgramSales";
import YearPrograms from "./YearPrograms";
import AllCategories from "./AllCategories";
import AddNewCategory from "./AddNewCategory";
import EditCategory from "./EditCategory";
import ProgramPayment from "./ProgramPayment";
import ProgramSettings from "./ProgramSettings";
import ProgramInterest from "./ProgramInterest";
import ProgramMaps from "./ProgramMaps";
import EditInterest from "./EditInterest";
import InterestTypes from "./InterestTypes";
import AddNewInterestType from "./AddNewInterestType";
import EditInterestType from "./EditInterestType";
import Billing from "./Billing";
import Profile from "./Profile";
import AddNewProduct from "./AddNewProduct";
import EditProduct from "./EditProduct";
import PurchaseNewProgram from "./PurchaseNewProgram";
import Users from "./Users";
import EditUser from "./EditUser";
import AddNewUser from "./AddNewUser";
import Settings from "./Settings";
import Teams from "./Teams";
import SendEmail from "./SendEmail";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        
        <Route path="/dashboard" component={Dashboard} />

        <Route path="/inventory" component={Inventory} />
        <Route path="/add-new-product" component={AddNewProduct} />
        <Route path="/edit-product/:edit_key" component={EditProduct} />
        <Route path="/all-categories" component={AllCategories} />
        <Route path="/add-new-category" component={AddNewCategory} />
        <Route path="/edit-category/:edit_key" component={EditCategory} />

        <Route path="/users/:programId" component={Users} />
        <Route path="/add-new-user/:programId" component={AddNewUser} />
        <Route path="/edit-user/:edit_key" component={EditUser} />

        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Route path="/totalprograms" component={TotalPrograms} />
        <Route path="/billing" component={Billing} />
        <Route path="/profile" component={Profile} />

        <Route path="/all-programs" component={AllPrograms} />
        <Route path="/program/:programId" component={OneProgram} />
        <Route path="/year-programs/:year" component={YearPrograms} />
        <Route path="/purchase-new-program" component={PurchaseNewProgram} />
        <Route path="/program-settings/:programId" component={ProgramSettings} />
        <Route path="/sales/:programId" component={ProgramSales} />
        <Route path="/interest/:programId" component={ProgramInterest} />
        <Route path="/program-maps/:programId" component={ProgramMaps} />
        <Route path="/edit-interest/:programId/:interestId" component={EditInterest} />
        <Route path="/interest-types/:programId" component={InterestTypes} />
        <Route path="/add-new-interest-type/:programId" component={AddNewInterestType} />
        <Route path="/edit-interest-type/:programId/:interestId" component={EditInterestType} />

        <Route path="/settings" component={Settings} />

        <Route path="/teams/:programId" component={Teams} />

        <Route path="/program-payment" component={ProgramPayment} />

        <Route path="/send-email" component={SendEmail} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
