import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SettingsProfile from "./settings_component/SettingsProfile";
import SettingsBill from "./settings_component/SettingsBill";
import SettingsProgram from "./settings_component/SettingsProgram";
import SettingsPayments from "./settings_component/SettingsPayments";
import SettingsHelp from "./settings_component/SettingsHelp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "none",
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function Settings() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="row inventory_submenu">
        <h4
          style={{
            fontSize: "30px",
            fontWeight: "normal",
            color: "#111111",
            marginRight: "36px"
          }}
        >
          Settings
      </h4>
      </div>
      <div className={classes.root}>
        <AppBar position="static" style={{ background: "none", color: "inherit", boxShadow: "none" }}>
          <Tabs value={value} onChange={handleChange} aria-label="settings" variant="scrollable">
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Billing" {...a11yProps(1)} />
            <Tab label="Programs" {...a11yProps(2)} />
            <Tab label="Payments" {...a11yProps(3)} />
            <Tab label="Help" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <SettingsProfile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SettingsBill />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SettingsProgram />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SettingsPayments />
        </TabPanel>   
        <TabPanel value={value} index={4}>
          <SettingsHelp />
        </TabPanel>
      </div>
    </>
  );
}