import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic";
import "../pages/css/mynavbarstyle.css";

export default function MyStepNavbar(props) {

    const handleForceSignOut = props.handleForceSignOut;
    return (
        <Navbar sticky="top" expand="lg" style={{ background: "white", borderBottom: "1px solid lightgrey", fontFamily: "Open Sans" }}>
            <Navbar.Brand href="/auth/home">
                <img
                    alt="Logo"
                    className="img-fluid"
                    style={{maxWidth: "232px"}}
                    src={toAbsoluteUrl("/media/logos/logo-main.png")}
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">

                </Nav>
                <Nav>
                    {/* <Link
                        onClick={handleForceSignOut}
                        to="/auth/login"
                        className="btn btn-primary btn_signin_home"
                    >
                        Sign In
                    </Link> */}
                    <Link
                        onClick={handleForceSignOut}
                        to="/auth/home"
                        className="btn btn-primary btn_signout_home"
                    >
                        Sign Up
                    </Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
