import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { toAbsoluteUrl } from "../../../../_metronic";


export default function MyDropDownIcons(props) {
    const options = props.options;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [showState, setShowState] = React.useState(false);

    const handleMenuItemClick = (event, index) => {
        props.handleClick(options[index]);
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    React.useEffect(() => {
        if (props.defaultIndex > -1) {
            if (!showState) {
                setShowState(true);
                setSelectedIndex(props.defaultIndex);
            }
        }
    }, [props.defaultIndex, showState])

    return (
        <div>
            <div xs={12}
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                {/* <Form.Control type="text" placeholder="" ref={anchorRef} value={entryCount} readOnly /> */}
                {/* <Form.Control type="text" placeholder="" ref={anchorRef} value={props.value} readOnly /> */}
                <div
                    ref={anchorRef}
                    style={{
                        height: 52,
                        width: 100,
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                        background: "white",
                        border: "1px solid #e2e5ec",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <img
                        src={toAbsoluteUrl(`/media/survey_icons/${props.value}.png`)}
                        alt="survey_icon"
                        width="30px"
                    />
                </div>
                <button
                    // size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    // aria-expanded={open ? 'true' : undefined}
                    // aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    style={{
                        background: "white",
                        border: "1px solid #E2E2E2",
                        padding: "4px 0",
                        borderLeft: "none",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        height: "52px",
                        width: "52px"
                    }}
                    type="button"
                >
                    <MenuIcon style={{ width: "31px" }} />
                </button>
                <Popper style={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} placement="top" transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                // disabled={index === 2}
                                                selected={index === selectedIndex}
                                                onClick={event => handleMenuItemClick(event, index)}
                                                style={{ fontSize: "20px" }}
                                            >
                                                <img
                                                    src={toAbsoluteUrl(`/media/survey_icons/${option}.png`)}
                                                    alt="survey_icon"
                                                    width="30px"
                                                />
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}