import React, { useState, useRef } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import RoundTextField from "./RoundTextField";
import "./css/mydaterangepicker.css"

export default function MyDateRangePicker(props) {
    const rangepicker = useRef(null);

    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [textValue, setTextValue] = useState(props.textValue || "");

    const handlerangeChange = date => {
        setDateRange(date);
        const text = date[0].toLocaleDateString() + " - " + date[1].toLocaleDateString();
        setTextValue(text);
        props.values[`programdates${number}`] = text;
        // props.onChange();
    }

    const handleRangeClick = () => {
        rangepicker.current.toggleCalendar();
    }

    const number = props.number;
    return (
        <>
            <label style={{ fontSize: "14px", fontWeight: "500", color: "black", marginBottom: "21px" }}>{props.headerLabel}</label>

            <DateRangePicker
                ref={rangepicker}
                onChange={handlerangeChange}
                value={dateRange}
                onBlur={props.onBlur}
                name={`rangepicker${number}`}
            // onClick={handleClick}
            />
            <RoundTextField
                onClick={handleRangeClick}
                value={textValue}
                lastStyle={{ width: "100%", marginTop: "-52px", background: "white" }}
                placeholder={props.placeholder}
                onChange={props.onChange}
                name={`programdates${number}`}
                helperText={props.errors[`programdates${number}`]}
                error={Boolean(props.errors[`programdates${number}`])}
            />
        </>
    );
}