import React from 'react';

export default function Content() {
  return (
    <div
      style={{ padding: "150px 10px", background: "#f0f1f6" }}
    >
      <div
        style={{
          maxWidth: 550,
          padding: "50px 70px",
          background: "white",
          margin: "auto",
          boxShadow: "0px 0px 2px 3px #e6e6e6",
          textAlign: "center"
        }}
      >
        <h2 style={{ color: "#111111" }}>Your Password is successfully updated.</h2>
      </div>
    </div>
  );
}