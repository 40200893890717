import React from 'react';
import TeamSalesItem from './TeamSalesItem';

export default function OneTeamSale(props) {
    let userSalesInfo = {};
    let leaderId = "";
    let totalAmount = 0;
    let averageAmount = 0;

    if (props.teamSalesInfo) {
        userSalesInfo = props.teamSalesInfo.userSalesInfo;
        leaderId = props.teamSalesInfo.leaderId;

        Object.keys(userSalesInfo).forEach(key => {
            const userInfo = userSalesInfo[key];
            totalAmount += Number(userInfo.amountMoney.toFixed(1));
        })
        averageAmount = !(Object.keys(userSalesInfo).length === 1) ? Number((totalAmount / (Object.keys(userSalesInfo).length - 1)).toFixed(1)) : 0;
    }

    return (
        <div style={{ padding: "0px 38px", textAlign: "center" }}>
            <TeamSalesItem userInfo={userSalesInfo[leaderId]} leader="leader" />
            <h1
                style={{ fontSize: 40, fontWeight: "500", color: "#36445C", marginTop: 5, marginBottom: 14 }}
            >${totalAmount.toFixed(2)}</h1>
            <h5
                style={{ fontSize: 24, fontWeight: "500", color: "#36445C", marginBottom: 40 }}
            >${averageAmount.toFixed(2)}</h5>

            {
                Object.keys(userSalesInfo).map(key => {
                    return key !== leaderId ? <TeamSalesItem userInfo={userSalesInfo[key]} key={key} /> : null;
                })
            }
        </div>
    );
}