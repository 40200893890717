import React, { useState, useRef } from 'react';
import WhiteButton from "./common_component/WhiteButton";
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button"
import "./css/add_new_product.scss";
import MyDropDownField from "./inventory_component/MyDropDownField";
import Fab from '@material-ui/core/Fab';
import PublishIcon from '@material-ui/icons/Publish';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import app from "../../components/base";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import CircularIntegration from "./common_component/CircularIntegration";
import ls from 'local-storage';

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
        background: "#36425C",
        color: "white"
    },
}));

const CustomSwitch = withStyles({
    root: {
        width: "69px",
        height: "44px",
    },
    switchBase: {
        color: 'white',
        // backgroundColor: "#CDDC39",
        '&$checked': {
            color: '#CDDC39 !important',
        },
        '&$checked + $track': {
            backgroundColor: '#CDDC39 !important',
            opacity: "1",
        },
    },
    checked: {
        // backgroundColor: "black",
    },
    track: {
        backgroundColor: "#f1f2f8",
        height: "20px",
        marginTop: "-3px",
        opacity: "1",
        border: "1px solid #CDDC39"
    },
    thumb: {
        border: "4px solid white",
        width: "28px",
        height: "28px",
        marginTop: "-4px",
    }
})(Switch);

const roleOptions = ["Program Head", "Leader", "Student"];

export default function EditUser() {
    const history = useHistory();
    let { edit_key } = useParams();

    const main_user = ls.get('edit_user');
    const programId = main_user.programId;

    const fileForUpload = useRef(null);
    const classes = useStyles();

    let defaultRoleIndex;
    roleOptions.forEach((elem, i) => {
        if (elem === main_user.role) {
            defaultRoleIndex = i;
        }
    })


    const [role, setRole] = useState(main_user.role);
    const [firstName, setFirstName] = useState(main_user.firstName);
    const [lastName, setLastName] = useState(main_user.lastName);
    const [email, setEmail] = useState(main_user.email);
    const [gender, setGender] = useState(main_user.gender);
    const [password, setPassword] = useState(main_user.password);
    const [phonenumber, setPhonenumber] = useState(main_user.phonenumber);
    const [jobDescription, setJobDescription] = useState(main_user.jobDescription);
    const [image, setImage] = useState(null);
    const [showImage, setShowImage] = useState(main_user.image);
    const [active, setActive] = useState(main_user.active);
    const [changeable, setChangeable] = useState(false);
    const [programName, setProgramName] = useState("");


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);


    var programRef = app.database().ref().child(`programs/${programId}`);
    programRef.once('value', snapshot => {
        var program = snapshot.val();
        setProgramName(program.name);
    });


    const handleRoleClick = (value) => {
        setRole(value);
    }

    const handleFirstName = (event) => {
        setFirstName(event.target.value);
    }

    const handleLastName = (event) => {
        setLastName(event.target.value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handlePassword = event => {
        setPassword(event.target.value);
    }
    const handlePhonenumber = (event) => {
        setPhonenumber(event.target.value);
    }

    const handleChangeable = () => {
        setChangeable(!changeable);
    }

    const handleJobDescription = (event) => {
        setJobDescription(event.target.value);
    }


    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();

            reader.onload = function (ev) {
                setShowImage(ev.target.result);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    const handleSwitch = () => {
        setActive(!active);
    }

    function createData(image, firstName, lastName, gender, role, programId, email, password, phonenumber, jobDescription, active) {
        return { image, firstName, lastName, gender, role, programId, email, password, phonenumber, jobDescription, active };
    }

    const handleSubmit = () => {
        if (firstName === "") {
            alert("Please input your name");
        } else {

            if (!loading) {
                setSuccess(false);
                setLoading(true);
            }

            if (image != null) {
                const uploadTask = app.storage().ref(`users/${image.name}`).put(image);
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {

                }, () => {
                    app.storage().ref('users').child(image.name).getDownloadURL().then(url => {
                        var usersRef = app.database().ref().child("users/" + edit_key);
                        usersRef.update(createData(url, firstName, lastName, gender, role, programId, email, password, phonenumber, jobDescription, active));

                        setSuccess(true);
                        setLoading(false);

                    });
                })
            } else {
                var usersRef = app.database().ref().child("users/" + edit_key);
                usersRef.update(createData(showImage, firstName, lastName, gender, role, programId, email, password, phonenumber, jobDescription, active));

                setSuccess(true);
                setLoading(false);
            }
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-9">
                    <div className="row inventory_submenu">
                        <h4
                            className="col-md-4"
                            style={{
                                fontSize: "30px",
                                fontWeight: "normal",
                                color: "#111111",
                                marginRight: "36px"
                            }}
                        >
                            Edit User
                    </h4>
                        <div className="col-md-8" style={{ maxWidth: "375px" }}>
                            <WhiteButton handleClick={() => history.push(`/users/${programId}`)} caption="View all Users" addIcon />
                        </div>
                    </div>
                    <div
                        className="content"
                        style={{
                            border: "1px solid #D4D6D9",
                            marginTop: "112px",
                            padding: "35px",
                            borderRadius: "5px"
                        }}
                    >
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} sm="4" controlId="formGridFirstname">
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control value={firstName} onChange={handleFirstName} type="text" placeholder="" />
                                </Form.Group>

                                <Form.Group as={Col} sm="4" controlId="formGridLastname" style={{ display: "flex", alignItems: "flex-end" }}>
                                    <Form.Label></Form.Label>
                                    <Form.Control value={lastName} onChange={handleLastName} type="text" placeholder="" />
                                </Form.Group>
                                <Form.Group as={Col} sm="4" controlId="formGridRole">
                                    <Form.Label>Role</Form.Label>
                                    <MyDropDownField defaultIndex={defaultRoleIndex} value={role} options={roleOptions} handleClick={handleRoleClick} />
                                </Form.Group>
                            </Form.Row>

                            {/* <Form.Row>
                                <Form.Group as={Col} sm="4" controlId="formGridProgram">
                                    <Form.Label>Program</Form.Label>
                                    <MyDropDownField />
                                </Form.Group>
                                <Form.Group as={Col} sm="6" controlId="formGridGender" style={{ display: "flex", alignItems: "flex-end", paddingLeft: 30 }}>
                                    <RadioGroup defaultValue="female" aria-label="gender" name="customized-radisos" style={{ flexDirection: "row" }}>
                                        <FormControlLabel value="female" control={<Radio style={{ color: "#36435C" }} />} label="Female" style={{ marginRight: 50 }} />
                                        <FormControlLabel value="male" control={<Radio style={{ color: "#36435C" }} />} label="Male" />
                                    </RadioGroup>
                                </Form.Group>
                            </Form.Row> */}

                            <Form.Row>
                                <Form.Group as={Col} sm="4" controlId="formGridEmail">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control value={email} onChange={handleEmail} type="email" placeholder="" />
                                </Form.Group>
                                <Form.Group
                                    as={Col} sm="6"
                                    controlId="formGridGender"
                                    style={{ display: "flex", alignItems: "flex-end", paddingLeft: 30 }}
                                >
                                    <RadioGroup onChange={(event) => { setGender(event.target.value) }} defaultValue={main_user.gender} aria-label="gender" name="customized-radisos" style={{ flexDirection: "row" }}>
                                        <FormControlLabel value="male" control={<Radio style={{ color: "#36435C" }} />} label="male" style={{ marginRight: 50 }} />
                                        <FormControlLabel value="female" control={<Radio style={{ color: "#36435C" }} />} label="Female" />
                                    </RadioGroup>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="4" controlId="formGridPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control value={password} onChange={handlePassword} style={{ background: "#DEDEDE", fontSize: 20, color: "#707070" }} type="text" placeholder="" />
                                </Form.Group>
                                <Form.Group as={Col} sm="4" controlId="formGridChange" style={{ display: "flex", alignItems: "flex-end", paddingLeft: 30 }}>
                                    <FormControlLabel
                                        value="change"
                                        control={<Checkbox checked={changeable} onChange={handleChangeable} color="primary" />}
                                        label="Change?"
                                        labelPlacement="start"
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="4" controlId="formGridPhone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control value={phonenumber} onChange={handlePhonenumber} type="text" placeholder="" />
                                </Form.Group>
                                <Form.Group as={Col} sm="4" controlId="formGridJobDescription">
                                    <Form.Label>Job Description</Form.Label>
                                    <Form.Control value={jobDescription} onChange={handleJobDescription} type="text" placeholder="" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>Add Profile Image</Form.Label>
                                    <div style={{ width: "100%", border: "1px solid #B8B9BC", borderRadius: "3px", textAlign: "center" }}>
                                        <Fab aria-label="Add" className={classes.fab} onClick={() => { fileForUpload.current.click() }}>
                                            <PublishIcon />
                                        </Fab>
                                        <span>{image != null ? image.name : null}</span>
                                    </div>
                                    <Form.Control ref={fileForUpload} onChange={handleImageChange} style={{ display: "none" }} type="file" placeholder="" />
                                </Form.Group>
                            </Form.Row>

                            <CircularIntegration
                                caption="Save"
                                loading={loading}
                                success={success}
                                handleButtonClick={handleSubmit}
                            />
                        </Form>

                    </div>
                </div>
                <div className="col-xl-3">
                    <div
                        className="right_content"
                        style={{
                            border: "1px solid #D4D6D9",
                            padding: "20px 40px",
                            borderRadius: "5px",
                            marginTop: "20px",
                            paddingBottom: "60px"
                        }}
                    >
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-xl-12">
                                <img
                                    className="img-fluid rounded-circle"
                                    style={{ width: "237px" }}
                                    alt="user"
                                    src={showImage}
                                />
                            </div>
                            <div className="col-sm-6 col-md-6 col-xl-12">
                                <h1
                                    style={{
                                        fontSize: "30px",
                                        fontWeight: "bold",
                                        color: "#707070",
                                        marginTop: "20px",
                                        marginBottom: "20px"
                                    }}
                                >{`${firstName} ${lastName}`}</h1>

                                <span
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "500",
                                        color: "white",
                                        background: "#50A2E3",
                                        padding: "5px 30px",
                                        borderRadius: "3px",
                                    }}
                                >{role}</span>
                                <p
                                    style={{
                                        fontSize: "28px",
                                        fontWeight: "normal",
                                        color: "#707070",
                                        marginTop: "20px"
                                    }}
                                >
                                    {programName}
                                </p>
                                <span
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "normal",
                                        fontStyle: "italic",
                                        color: "#36435C",
                                        marginTop: "20px"
                                    }}
                                >{jobDescription}</span>
                                <p
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "normal",
                                        color: "#707070",
                                        marginTop: "20px"
                                    }}
                                >
                                    {email}<br />
                                    {phonenumber}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div style={{ float: "right", marginTop: "213px" }}>
                <span
                    style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#36425C",
                        marginRight: "55px"
                    }}
                >
                    Active User
                </span>
                <CustomSwitch checked={active} onClick={handleSwitch} /><br />
                <Button
                    type="submit"
                    style={{
                        background: "#C52129",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "bold",
                        width: "216px",
                        height: "44px",
                        borderRadius: "3px",
                        float: "right"
                    }}
                >
                    Delete User
                </Button>
            </div>
        </>
    )
}
