import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';


const columns = [
    { id: 'name', label: 'Program Name', minWidth: 153 },
    {
        id: 'year',
        label: 'Year',
        minWidth: 50,
        align: 'center',
    },
    {
        id: 'head',
        label: 'Program Head',
        minWidth: 91,
        align: 'center',
    },
    {
        id: 'students',
        label: 'Students',
        minWidth: 50,
        align: 'center',
        // format: value => value.toFixed(2),
    },
    {
        id: 'leaders',
        label: 'Leaders',
        minWidth: 50,
        align: 'center',
        // format: value => value.toFixed(2),
    },
    {
        id: 'total',
        label: 'Total Sales',
        minWidth: 60,
        align: 'center',
        // format: value => value.toFixed(2),
    },
    {
        id: 'manage',
        label: 'Manage',
        minWidth: 30,
        align: 'center',
        // format: value => value.toFixed(2),
    },
];


function createData(name, year, head, students, leaders, total) {
    return { name, year, head, students, leaders, total };
}

const rows = [
    createData('Sonoma', 2019, "Will Barrow", 17, 3, 43298),
    createData('Sonoma', 2019, "Will Barrow", 17, 3, 43298),
    createData('Sonoma', 2019, "Will Barrow", 17, 3, 43298),
    createData('Sonoma', 2019, "Will Barrow", 17, 3, 43298),
    createData('Sonoma', 2019, "Will Barrow", 17, 3, 43298),
];

// const activeBackColor = ["#C42129", "#CFDE39"];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {
        maxHeight: 440,
        overflow: 'auto',
    },
});

export default function AllProgramsTable(props) {
    // const rows = props.rows;
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontSize: "14px", fontWeight: "bold", color: "#666666" }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                            // const row = rows[key];
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={columns[0].id}>
                                    {/* {columns.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })} */}

                                    <TableCell key={columns[0].id} align={columns[0].align}>
                                        <span style={{ color: "#555555", fontSize: "14px", fontWeight: "normal" }}>{row[columns[0].id]}</span>
                                    </TableCell>
                                    <TableCell key={columns[1].id} align={columns[1].align}>
                                        <span style={{ color: "#555555", fontSize: "14px", fontWeight: "normal" }}>
                                            {row[columns[1].id]}
                                        </span>
                                    </TableCell>
                                    <TableCell key={columns[2].id} align={columns[2].align}>
                                        <span style={{ color: "#438AFE", fontSize: "14px", fontWeight: "normal" }}>
                                            {row[columns[2].id]}
                                        </span>
                                    </TableCell>
                                    <TableCell key={columns[3].id} align={columns[3].align}>
                                        <span style={{ color: "#555555", fontSize: "14px", fontWeight: "normal" }}>
                                            {row[columns[3].id]}
                                        </span>
                                    </TableCell>
                                    <TableCell key={columns[4].id} align={columns[4].align}>
                                        <span style={{ color: "#555555", fontSize: "14px", fontWeight: "normal" }}>
                                            {row[columns[4].id]}
                                        </span>
                                    </TableCell>
                                    <TableCell key={columns[5].id} align={columns[5].align}>
                                        <span style={{ color: "#555555", fontSize: "14px", fontWeight: "normal" }}>
                                            ${row[columns[5].id]}
                                        </span>
                                    </TableCell>
                                    <TableCell key={"manage"} align={columns[6].align}>
                                        <IconButton>
                                            <MenuIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                style={{ visibility: "hidden" }}
            />
        </Paper>
    );
}