import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetActiveStep: "[SetActiveStep] Action",
  SetProgramCount: "[SetProgramCount] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  subflag: true,
  activeStep: 1,
  programCount: 0,
};

export const reducer = persistReducer(
  { storage, key: "youthrush-auth", whitelist: ["user", "authToken", "subflag", "activeStep", "programCount"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        const { subflag } = action.subflag;
        return { authToken, user: undefined, subflag, activeStep: 1 };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        const { subflag } = action.subflag;
        return { authToken, user: undefined, subflag, activeStep: 1 };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetActiveStep: {
        const { activeStep } = action.payload;
        return { ...state, activeStep };
      }

      case actionTypes.SetProgramCount: {
        const { programCount } = action.payload;
        return { ...state, programCount };
      }

      default:
        return { ...state };
    }
  }
);

export const actions = {
  login: (authToken, subflag) => ({ type: actionTypes.Login, payload: { authToken }, subflag: { subflag } }),
  register: (authToken, subflag) => ({
    type: actionTypes.Register,
    payload: { authToken },
    subflag: { subflag }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setActiveStep: activeStep => ({ type: actionTypes.SetActiveStep, payload: { activeStep } }),
  setProgramCount: programCount => ({ type: actionTypes.SetProgramCount, payload: { programCount } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    // const { user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
