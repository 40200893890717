import React from 'react';
// import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { DateRange } from 'react-date-range';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

export default function MyDateRangePicker(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState(new Date().toLocaleDateString());
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleSelect(range) {
        const startDateString = new Date(range.startDate._d).toLocaleDateString();
        const endDateString = new Date(range.endDate._d).toLocaleDateString();
        setTitle(startDateString + "-" + endDateString);
        props.handleRange(startDateString, endDateString);
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div
                style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
            >
                <span
                    ref={anchorRef}
                    style={{
                        fontSize: 22,
                        fontWeight: "500",
                        color: "white",
                        marginRight: 30,
                        verticalAlign: "middle"
                    }}
                >{title}</span>
                <IconButton
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <MenuIcon style={{ color: "white", fontSize: "25px" }} />
                </IconButton>
                <Popper style={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <DateRange
                                        onInit={handleSelect}
                                        onChange={handleSelect}
                                    />
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}