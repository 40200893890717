import React from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import app from "../../../components/base";

export default function Content(props) {
  const userId = props.userId;
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const handleUpdate = () => {
    if (password.length < 6) {
      setPasswordError("Password must be longer than 6 characters.");
    } else {
      app.database().ref(`users/${userId}`).once('value', userSnap => {
        const user = userSnap.val();
        if (user.email !== email) {
          setEmailError("Please retype your correct email.");
        } else {
          user.password = password;
          app.database().ref(`users/${userId}`).set(user);
          history.push("/updated");
        }
      })
    }
  }
  return (
    <div
      style={{ padding: "20px 10px", background: "#f0f1f6" }}
    >
      <div
        style={{
          maxWidth: 550,
          padding: "50px 70px",
          background: "white",
          margin: "auto",
          boxShadow: "0px 0px 2px 3px #e6e6e6",
          textAlign: "center"
        }}
      >
        <h2 style={{ marginBottom: 40, color: "#111111" }}>Update your password</h2>
        <p style={{ marginBottom: 40, color: "#111111" }}>Your password must contain at least 6 characters.</p>
        <Form>
          <Form.Group controlId="formBasicEmail" style={{ textAlign: "left", marginBottom: 40 }}>
            <Form.Label style={{ fontWeight: "bold", fontSize: 13, color: "#111111" }}>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={event => { setEmail(event.target.value); }} />
            <span style={{ fontWeight: "bold", fontSize: 10, color: "red" }}>{emailError}</span>
          </Form.Group>

          <Form.Group controlId="formBasicPassword" style={{ textAlign: "left", marginBottom: 40 }}>
            <Form.Label style={{ fontWeight: "bold", fontSize: 13, color: "#111111" }}>Re-type Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={event => {
              setPassword(event.target.value);
              if (event.target.value.length > 5) {
                setPasswordError("");
              }
            }} />
            <span style={{ fontWeight: "bold", fontSize: 10, color: "red" }}>{passwordError}</span>
          </Form.Group>
          <hr />
          <Button
            onClick={handleUpdate}
            variant="contained"
            style={{ marginTop: 30, padding: "5px 40px", background: "#81b600", color: "white" }}
          >
            Update Password
          </Button>
        </Form>
      </div>
    </div>
  );
}