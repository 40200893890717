import React from 'react';
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export default function TopDistributedMaterial(props) {
    const data = props.data;
    return (
        <div style={{ background: "white", padding: "25px 25px", borderRadius: "5px" }}>
            <div className="row">
                <div className="col-12" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <h4 style={{ fontSize: "14px", fontWeight: "bold", color: "#333333", float: "left" }}>Top Distributed Material</h4>

                    <div style={{ marginRight: "-22px" }}>
                        <IconButton aria-label="Settings">
                            <MoreVertIcon />
                        </IconButton>
                    </div>
                </div>
            </div>

            <div className="book_table" style={{ marginTop: "33px" }}>
                {
                    data.length > 0 ?
                        data.map((row, i) => {
                            return <div key={i} className="row" style={{ marginBottom: "30px", display: "flex", alignItems: "center" }}>
                                <div className="col-2">#{i + 1}</div>
                                <div className="col-3">
                                    <img
                                        alt="avata"
                                        width="100%"
                                        src={row.image}
                                    />
                                </div>
                                <div className="col-5" style={{ fontSize: "14px", fontWeight: "500", color: "#111111" }}>
                                    {row.product}
                                </div>
                                <div className="col-2" style={{ fontSize: "14px", fontWeight: "500", color: "#111111" }}>{row.stock}</div>
                            </div>
                        })
                        : null
                }
            </div>
        </div>
    )
}
