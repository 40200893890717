import React from 'react';

export default function CategoryChartLabel(props) {
    return (
        <div style={{display: "inline-flex"}}>
            <span
                style={{
                    width: "11px",
                    height: "11px",
                    background: `${props.color}`,
                    boxShadow: "0.5px 0.87px 1px 1px rgba(0, 0, 0, 0.24)",
                    borderRadius: "3px",
                    display: "inline-flex",
                    marginRight: "8px", 
                    float: "left"
                }}
            >
            </span>
            <div style={{float: "left", textAlign: "center", marginTop: "-5px"}}>
                <span style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#7C7C7C"
                }}>{props.label}</span><br/>

                <span style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    color: "#7C7C7C",
                    margin: "0 auto"
                }}>{props.value}</span>
            </div>
        </div>
    )
}
