/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import AuthPage from "../pages/auth/AuthPage";
import AuthPageStep from "../pages/auth/AuthPageStep";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import UserUpdatePassword from "../pages/auth/UserUpdatePassword";
import SuccessUpdated from "../pages/auth/SuccessUpdated";
import { LayoutContextProvider } from "../../_metronic";
import * as routerHelpers from "../router/RouterHelpers";
import * as builder from "../../_metronic/ducks/builder";
import app from "../components/base";

export const Routes = withRouter(({ Layout, history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const {
    isAuthorized,
    isSubscribed,
    menuConfig,
    userLastLocation
  } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      isSubscribed: auth.subflag,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  app.auth().onAuthStateChanged(user => {
    if (user != null) {
      app
        .database()
        .ref("programs")
        .orderByChild("adminId")
        .equalTo(user.uid)
        .on("value", programSnap => {
          let programSubmenu = [];
          app
            .database()
            .ref("admins/" + user.uid + "/programIds")
            .once("value", adminProgramsSnap => {
              const programs = programSnap.val();
              if (programs) {
                const adminPrograms = adminProgramsSnap.val();
                const years = Object.keys(adminPrograms);
                years.forEach(year => {
                  let yearPrograms = {};
                  yearPrograms.title = year;
                  yearPrograms.page = "year-programs/" + year;
                  let submenu = [];

                  adminPrograms[year].forEach(programId => {
                    let subProgram = {};
                    const program = programs[programId];
                    subProgram.title = program.name;
                    subProgram.page = `program/${programId}`;

                    subProgram.submenu = [
                      {
                        title: "Users",
                        page: `users/${programId}`,
                        icon: "flaticon-users",
                        submenu: []
                      },
                      {
                        title: "Sales",
                        page: `sales/${programId}`,
                        icon: "flaticon-diagram",
                        submenu: []
                      },
                      {
                        title: "Teams",
                        page: `teams/${programId}`,
                        icon: "flaticon-network",
                        submenu: []
                      },
                      // {
                      //   title: "Maps",
                      //   page: `program-maps/${programId}`,
                      //   icon: "flaticon-placeholder"
                      // },
                      {
                        title: "Interest List",
                        page: `interest/${programId}`,
                        icon: "flaticon-list",
                        submenu: []
                      },
                      {
                        title: "Settings",
                        page: `program-settings/${programId}`,
                        icon: "flaticon-settings-1",
                        submenu: []
                      }
                    ];

                    submenu.push(subProgram);
                  });
                  yearPrograms.submenu = submenu;
                  programSubmenu.push(yearPrograms);
                });
                menuConfig.aside.items[4].submenu = programSubmenu;
                builder.actions.setMenuConfig(menuConfig);
              }
            });
        });
    }
  });

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          // <Route path="/" component={LandingPage} />
          <Route path="/auth" component={AuthPage} />
        ) : /* Otherwise redirect to root page (`/`) */
        !isSubscribed ? (
          <Route path="/auth" component={AuthPageStep} />
        ) : (
          <Redirect from="/auth" to={userLastLocation} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route
          path="/user-update-password/:userId"
          component={UserUpdatePassword}
        />
        <Route path="/updated" component={SuccessUpdated} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth" />
        ) : !isSubscribed ? (
          <Route path="/auth" component={AuthPageStep} />
        ) : (
          <Layout>
            <HomePage userLastLocation={userLastLocation} />
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
