import React from "react";
import WithHeaderWindow from "../common_component/WithHeaderWindow";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from "../../../../_metronic";

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
        background: "#36425C",
        color: "white"
    },
}));

export default function SettingsBill1(props) {
    const classes = useStyles();
    return (
        <WithHeaderWindow title="Manage Payment Methods" style={{ maxWidth: 1400 }}>
            <div
                style={{ padding: 40 }}
            >
                <div className="row">
                    <div className="col-sm-4">
                        <div style={{ border: "1px solid rgba(112, 112, 112, 0.57)", borderRadius: "3px", padding: 10 }}>
                            <div style={{ textAlign: "right" }}>
                                <IconButton>
                                    <MenuIcon />
                                </IconButton>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: 40
                                }}
                            >
                                <img
                                    alt="notification"
                                    width="80px"
                                    // style={{border: "3px solid white"}}
                                    className="img-fluid rounded-circle"
                                    src={toAbsoluteUrl("/media/logos/visa@2x.png")}
                                />
                                <div style={{ marginLeft: "10px" }}>
                                    <span
                                        style={{ fontSize: 25, fontWeight: "normal", color: "#707070" }}
                                    >**** **** **** 4533</span>
                                </div>
                            </div>
                            <div style={{ textAlign: "right", marginTop: 12 }}>
                                <span
                                    style={{ fontSize: 25, fontWeight: "normal", color: "#707070" }}
                                >4533| Exp 03/24</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div style={{ border: "1px solid rgba(112, 112, 112, 0.57)", borderRadius: "3px", padding: 10 }}>
                            <div style={{ textAlign: "right" }}>
                                <IconButton>
                                    <MenuIcon />
                                </IconButton>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: 40
                                }}
                            >
                                <img
                                    alt="notification"
                                    width="80px"
                                    // style={{border: "3px solid white"}}
                                    className="img-fluid rounded-circle"
                                    src={toAbsoluteUrl("/media/logos/visa@2x.png")}
                                />
                                <div style={{ marginLeft: "10px" }}>
                                    <span
                                        style={{ fontSize: 25, fontWeight: "normal", color: "#707070" }}
                                    >**** **** **** 4533</span>
                                </div>
                            </div>
                            <div style={{ textAlign: "right", marginTop: 12 }}>
                                <span
                                    style={{ fontSize: 25, fontWeight: "normal", color: "#707070" }}
                                >4533| Exp 03/24</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div style={{ border: "1px solid rgba(112, 112, 112, 0.57)", borderRadius: "3px", padding: 10 }}>
                            <div style={{ textAlign: "right" }}>
                                <IconButton>
                                    <MenuIcon />
                                </IconButton>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: 40
                                }}
                            >
                                <img
                                    alt="notification"
                                    width="80px"
                                    // style={{border: "3px solid white"}}
                                    className="img-fluid rounded-circle"
                                    src={toAbsoluteUrl("/media/logos/visa@2x.png")}
                                />
                                <div style={{ marginLeft: "10px" }}>
                                    <span
                                        style={{ fontSize: 25, fontWeight: "normal", color: "#707070" }}
                                    >**** **** **** 4533</span>
                                </div>
                            </div>
                            <div style={{ textAlign: "right", marginTop: 12 }}>
                                <span
                                    style={{ fontSize: 25, fontWeight: "normal", color: "#707070" }}
                                >4533| Exp 03/24</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 240, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div
                        style={{
                            cursor: "pointer",
                            fontSize: 25,
                            color: "#707070"
                        }}
                        onClick={props.handleBack}
                    >
                        Go back
                    </div>
                    <div
                        style={{
                            alignItems: "center", 
                            display: "flex"
                        }}
                        onClick={props.handleAddNew}
                    >
                        <span
                            style={{
                                cursor: "pointer",
                                fontSize: 25,
                                color: "#707070"
                            }}
                        >Add New</span>
                        <Fab aria-label="Add" className={classes.fab}>
                            <AddIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        </WithHeaderWindow>
    );
}
