import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { Card, CardHeader, IconButton, CardContent } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import app from '../../components/base';
import LargeSearchBar from './common_component/LargeSearchBar';
import StatisticsSlider from "./dashboard_component/StatisticsSlider";
import * as auth from "../../store/ducks/auth.duck";
import "../../pages/css/payment.css";
import "./program_component/css/cuponstyle.scss";
import "./css/all_programs.scss";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";


function OneProgram() {

    const { programId } = useParams();

    const [showState, setShowState] = useState(false);
    const [program, setProgram] = useState(null);

    useEffect(() => {
        if (!showState) {
            setShowState(true);
            app.database().ref(`programs/${programId}`).once('value', programSnap => {
                app.database().ref(`transactions`).orderByChild('programId').equalTo(programId).once('value', transactionSnap => {
                    app.database().ref(`users`).orderByChild('programId').equalTo(programId).once('value', userSnap => {
                        const users = userSnap.val();
                        const currentProgram = programSnap.val();
                        const transactions = transactionSnap.val();

                        const key = programId;
                        let oneProgram = currentProgram;
                        oneProgram.programId = key;
                        let totalAmount = 0;
                        if (transactions) {
                            Object.keys(transactions).forEach(transactionId => {
                                const transaction = transactions[transactionId];
                                totalAmount += transaction.transaction.amount_money.amount / 100;
                            })
                        }
                        oneProgram.totalAmount = totalAmount;
                        let studentCount = 0;
                        if (users) {
                            Object.keys(users).forEach(userId => {
                                const user = users[userId];
                                if (user.role === "Student") {
                                    studentCount++;
                                }
                            })
                        }
                        const goalAmount = studentCount * 3000;
                        oneProgram.goalAmount = goalAmount;
                        const percent = !isNaN(totalAmount / goalAmount * 100) ? totalAmount / goalAmount * 100 : 0;
                        oneProgram.percent = Math.round(percent);
                        setProgram(oneProgram);

                    })

                })
            })
        }
    }, [showState, programId])

    return (
        <>
            <div className="row inventory_submenu">
                <div className="col-md-3">
                    <h4
                        style={{
                            fontSize: "30px",
                            fontWeight: "normal",
                            color: "#111111",
                            marginRight: "36px"
                        }}
                    >
                        {program ? program.name : null}
                    </h4>
                </div>
                <div className="col-md-9">
                    <LargeSearchBar placeholder="Search programs, year" />
                </div>
            </div>
            {
                program ?
                    <div className="row" style={{ marginTop: "53px" }}>
                        <div className="col-xl-9 program_cards">
                            <div className="row">
                                <div className={`col-md-4 program_card_type0`} style={{ marginTop: "20px" }}>
                                    <Card style={{ paddingTop: "19px", paddingBottom: "30px", fontSize: "20px", fontWeight: "bold" }}>
                                        <CardHeader
                                            style={{
                                                paddingLeft: "30px",
                                                paddingRight: "10px",
                                                paddingBottom: "9px"
                                            }}
                                            action={
                                                <IconButton aria-label="Settings">
                                                    <MoreVertIcon />
                                                </IconButton>
                                            }
                                            title={program.name}
                                            titleTypographyProps={{ variant: 'h4' }}
                                            subheader={program.year}
                                        >
                                        </CardHeader>

                                        <CardContent
                                            style={{
                                                paddingTop: "9px",
                                                paddingLeft: "30px",
                                                paddingRight: "30px",
                                            }}
                                        >
                                            <StatisticsSlider title={program.totalAmount.toFixed(2)} value={program.percent} />
                                            <div>
                                                <div style={{ float: "left" }}>
                                                    <span>{program.percent}%</span> Of program Goal
                                                            </div>
                                                <div style={{ float: "right" }}>
                                                    To Goal: <span>${program.goalAmount}</span>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    );
}


export default connect(
    null,
    auth.actions
)(OneProgram);
