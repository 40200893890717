import React from "react";
import {InputGroup, FormControl, Button} from "react-bootstrap";

export default function NewsLetter(props) {
    return (
        <>
            <h4 style={{marginBottom: "21px", fontSize: "18px", fontWeight: "normal", color: "black"}}>{props.title}</h4>
            <p style={{marginBottom: "21px", fontSize: "14px", fontWeight: "normal", color: "#7D7D7D"}}>{props.description}</p>
            <InputGroup className="mb-3" style={{ borderRadius: "20px" }}>
                <FormControl
                    placeholder="Email address"
                    aria-label="Email address"
                    aria-describedby="basic-addon2"
                    style={{
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                    }}
                />
                <InputGroup.Append>
                    <Button variant="primary"
                        style={{
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                            fontSize: "12px", 
                            color: "white", 
                            paddingLeft: "46px", 
                            paddingRight: "46px"
                        }}
                    >
                        SUBSCRIBE
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        </>
    );
}