import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom'
import { GET_ONE_USER } from "../../../components/constants";
import ls from 'local-storage';

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Donut', 452, 25.0, 51, 4.9),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

const headCells = [
    { id: 'image', label: '', minWidth: 42, numeric: false },
    { id: 'firstName', label: 'User Full Name', minWidth: 93, numeric: false },
    {
        id: 'role',
        label: 'Role',
        minWidth: 95,
        align: 'center',
        numeric: false,
    },
    // {
    //     id: 'program',
    //     label: 'Program',
    //     minWidth: 91,
    //     align: 'center',
    //     numeric: false,
    // },
    {
        id: 'phonenumber',
        label: 'Email & Phone',
        minWidth: 154,
        align: 'center',
        numeric: true,
    },
    {
        id: 'active',
        label: 'Active',
        minWidth: 25,
        align: 'center',
        numeric: false,
        // format: value => value.toFixed(2),
    },
    {
        id: 'edit',
        label: 'Edit',
        minWidth: 30,
        align: 'center',
        numeric: false,
    },
];

function desc(a, b, orderBy) {
    let isNumeric = false;
    headCells.forEach(elem => {
        if (elem.id === orderBy) {
            isNumeric = elem.numeric;
        }
    })
    const compA = isNumeric ? Number(a[orderBy]) : a[orderBy];
    const compB = isNumeric ? Number(b[orderBy]) : b[orderBy];
    if (compB < compA) {
        return -1;
    }
    if (compB > compA) {
        return 1;
    }
    return 0;
}

function stableSort(object, cmp) {
    let array = Object.keys(object)
    const stabilizedThis = array.map((key, index) => [object[key], index, key]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => [el[0], el[2]]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// const headCells = [
//   { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
//   { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
//   { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
//   { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
//   { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
// ];


const columns = headCells;

// const catBackColor = {};

// fetch(GET_CATEGORY, {
//     method: "GET"
// })
//     .then(response => {
//         return response.text();
//     })
//     .then(data => {
//         // setRows(JSON.parse(data));
//         const categories = JSON.parse(data);
//         Object.keys(categories).forEach(key => {
//             var category = categories[key];
//             catBackColor[category.category] =
//                 typeof category.color === "string" ?
//                     category.color :
//                     `rgba(${category.color.r}, ${category.color.g}, ${category.color.b}, ${category.color.a})`;
//         })
//     })

const activeBackColor = ["#C42129", "#CFDE39"];


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// const useToolbarStyles = makeStyles(theme => ({
//     root: {
//         paddingLeft: theme.spacing(2),
//         paddingRight: theme.spacing(1),
//     },
//     highlight:
//         theme.palette.type === 'light'
//             ? {
//                 color: theme.palette.secondary.main,
//                 backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//             }
//             : {
//                 color: theme.palette.text.primary,
//                 backgroundColor: theme.palette.secondary.dark,
//             },
//     title: {
//         flex: '1 1 100%',
//     },
// }));

// const EnhancedTableToolbar = props => {
//     const classes = useToolbarStyles();
//     const { numSelected } = props;

//     return (
//         <Toolbar
//             className={clsx(classes.root, {
//                 [classes.highlight]: numSelected > 0,
//             })}
//         >
//             {numSelected > 0 ? (
//                 <Typography className={classes.title} color="inherit" variant="subtitle1">
//                     {numSelected} selected
//         </Typography>
//             ) : (
//                     <Typography className={classes.title} variant="h6" id="tableTitle">
//                         Nutrition
//         </Typography>
//                 )}

//             {numSelected > 0 ? (
//                 <Tooltip title="Delete">
//                     <IconButton aria-label="delete">
//                         <DeleteIcon />
//                     </IconButton>
//                 </Tooltip>
//             ) : (
//                     <Tooltip title="Filter list">
//                         <IconButton aria-label="filter list">
//                             <FilterListIcon />
//                         </IconButton>
//                     </Tooltip>
//                 )}
//         </Toolbar>
//     );
// };

// EnhancedTableToolbar.propTypes = {
//     numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function UserTable(props) {
    const history = useHistory();
    const rows = props.rows;
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dense = false;

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    // const handleSelectAllClick = event => {
    //     if (event.target.checked) {
    //         const newSelecteds = rows.map(n => n.name);
    //         setSelected(newSelecteds);
    //         return;
    //     }
    //     setSelected([]);
    // };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditClick = key => {
        fetch(GET_ONE_USER + '/' + key, {
            method: "GET"
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                const user = JSON.parse(data);
                ls.set('edit_user', user);
                history.push("/edit-user/" + key);
            })
    }
    // const handleChangeDense = event => {
    //     setDense(event.target.checked);
    // };

    const isSelected = name => selected.indexOf(name) !== -1;

    const rowsLength = rows != null ? Object.keys(rows).length : 0;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowsLength - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsLength}
                        />
                        {rowsLength !== 0 ?
                            <TableBody>
                                {stableSort(rows, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((oneRow, index) => {
                                        const row = oneRow[0];
                                        const row_key = oneRow[1];
                                        const isItemSelected = isSelected(row.name);
                                        // const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={event => handleClick(event, row.image)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row_key}
                                                selected={isItemSelected}
                                            >
                                                <TableCell key={columns[0].id} align={columns[0].align}>
                                                    <img
                                                        className="img-fluid rounded-circle"
                                                        alt="product"
                                                        width="42px"
                                                        src={row[columns[0].id]}
                                                    />
                                                </TableCell>
                                                <TableCell key={columns[1].id} align={columns[1].align}>
                                                    <span style={{ color: "#555555", fontSize: "14px", fontWeight: "normal" }}>
                                                        {row.firstName + " " + row.lastName}
                                                    </span>
                                                </TableCell>
                                                <TableCell key={columns[2].id} align={columns[2].align}>
                                                    <span style={{ color: "#438AFE", fontSize: "14px", fontWeight: "normal" }}>
                                                        {row[columns[2].id]}
                                                    </span>
                                                </TableCell>
                                                <TableCell key={columns[3].id} align={columns[3].align}>
                                                    <span style={{ color: "#555555", fontSize: "14px", fontWeight: "normal" }}>
                                                        {row.email}<br />{row.phonenumber}
                                                    </span>
                                                </TableCell>
                                                <TableCell key={columns[4].id} align={columns[4].align}>
                                                    <button
                                                        style={{
                                                            background: row[columns[4].id] ? activeBackColor[1] : activeBackColor[0],
                                                            borderRadius: "50%",
                                                            width: "24px",
                                                            height: "24px",
                                                            border: "none"
                                                        }}>
                                                    </button>
                                                </TableCell>
                                                <TableCell key={"edit"} align={columns[5].align}>
                                                    <IconButton
                                                        onClick={() => handleEditClick(row_key)}
                                                    >
                                                        <MenuIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            : null}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={rowsLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}