import React, { Component } from 'react';
import clsx from "clsx";

const styles = {
  name: {
    verticalAlign: 'top',
    display: 'none',
    margin: 0,
    border: 'none',
    fontSize: "16px",
    fontFamily: "Helvetica Neue",
    padding: "16px",
    color: "#373F4A",
    backgroundColor: "transparent",
    lineHeight: "1.15em",
    placeholderColor: "#959595",
    _webkitFontSmoothing: "antialiased",
    _mozOsxFontSmoothing: "grayscale",
  },
  '&:focus': {
    backgroundColor: "#3996ee"
  },
  leftCenter: {
    float: 'left',
    textAlign: 'center'
  },
  blockRight: {
    display: 'block',
    float: 'right'
  },
  center: {
    textAlign: 'center'
  }
}

export default class PaymentForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardBrand: "",
      nonce: undefined,
      googlePay: false,
      applePay: false,
      masterpass: true,
      loading: false,
      loadingButtonStyle: {
        paddingRight: "2.5rem",
        height: "50px",
        fontWeight: "500",
        fonSize: "1rem",
        paddingLeft: "2.75rem",
        borderRadius: "20px"
      },
      isSubmitting: false
    }
    this.requestCardNonce = this.requestCardNonce.bind(this);
    this.handleSquare = props.handleSquare;
  }

  enableLoading = () => {
    this.setState({
      loading: true,
      loadingButtonStyle: {
        paddingRight: "3.5rem",
        height: "50px",
        fontWeight: "500",
        fonSize: "1rem",
        paddingLeft: "2.75rem",
        borderRadius: "20px"
      }
    });
  };

  disableLoading = () => {
    this.setState({
      loading: false,
      loadingButtonStyle: {
        paddingRight: "2.5rem",
        height: "50px",
        fontWeight: "500",
        fonSize: "1rem",
        paddingLeft: "2.75rem",
        borderRadius: "20px"
      }
    });
  };

  requestCardNonce() {
    this.paymentForm.requestCardNonce();
    this.enableLoading();
  }

  componentDidMount() {
    const config = {
      applicationId: this.props.appId,
      // locationId: "GMT96A77XABR1",
      inputClass: "sq-input",
      autoBuild: false,
      inputStyles: [
        {
          fontSize: "16px",
          fontFamily: "Arial",
          padding: "16px",
          color: "#373F4A",
          backgroundColor: "transparent",
          lineHeight: "1.15em",
          placeholderColor: "#959595",
          _webkitFontSmoothing: "antialiased",
          _mozOsxFontSmoothing: "grayscale"
        }
      ],
      applePay: {
        elementId: 'sq-apple-pay'
      },
      masterpass: {
        elementId: 'sq-masterpass'
      },
      googlePay: {
        elementId: 'sq-google-pay'
      },
      cardNumber: {
        elementId: "sq-card-number",
        placeholder: "• • • •  • • • •  • • • •  • • • •"
      },
      cvv: {
        elementId: "sq-cvv",
        placeholder: "CVV"
      },
      expirationDate: {
        elementId: "sq-expiration-date",
        placeholder: "MM/YY"
      },
      postalCode: {
        elementId: "sq-postal-code",
        placeholder: "Zip"
      },
      callbacks: {
        methodsSupported: (methods) => {
          if (methods.googlePay) {
            this.setState({
              googlePay: methods.googlePay
            })
          }
          if (methods.applePay) {
            this.setState({
              applePay: methods.applePay
            })
          }
          if (methods.masterpass) {
            this.setState({
              masterpass: methods.masterpass
            })
          }
          return;
        },
        createPaymentRequest: () => {
          return {
            requestShippingAddress: false,
            requestBillingInfo: true,
            currencyCode: "USD",
            countryCode: "US",
            total: {
              label: "MERCHANT NAME",
              amount: "100",
              pending: false
            },
            lineItems: [
              {
                label: "Subtotal",
                amount: "100",
                pending: false
              }
            ]
          };
        },
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            console.log("Encountered errors:");
            errors.forEach(function (error) {
              console.log("  " + error.message);
            });

            return;
          }
          // this.setState({
          //   nonce: nonce
          // })

          //send process payment request
          // fetch(baseUrl + '/say/hello', {
          //   method: 'GET', 
          // })
          // .then(response => console.log(response));
          fetch(this.props.paymentUrl, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify({
              nonce: nonce, 
              price: this.props.price
            })
          })
            .catch(err => {
              alert('Network error: ' + err);
              this.disableLoading();
            })
            .then(response => {
              if (!response.ok) {
                return response.text().then(errorInfo => Promise.reject(errorInfo));
              }
              this.disableLoading();
              return response.text();
            })
            .then(data => {
              console.log(JSON.stringify(data));
              console.log('Payment complete successfully!\nCheck browser developer console for more details');
              this.handleSquare();
              this.disableLoading();
            })
            .catch(err => {
              console.error(err);
              console.log('Payment failed to complete!\nCheck browser developer console for more details');
              this.disableLoading();
            });


        },
        unsupportedBrowserDetected: () => {
        },
        inputEventReceived: (inputEvent) => {
          switch (inputEvent.eventType) {
            case "focusClassAdded":
              break;
            case "focusClassRemoved":
              break;
            case "errorClassAdded":
              document.getElementById("error").innerHTML =
                "Please fix card information errors before continuing.";
              break;
            case "errorClassRemoved":
              document.getElementById("error").style.display = "none";
              break;
            case "cardBrandChanged":
              if (inputEvent.cardBrand !== "unknown") {
                this.setState({
                  cardBrand: inputEvent.cardBrand
                })
              } else {
                this.setState({
                  cardBrand: ""
                })
              }
              break;
            case "postalCodeChanged":
              break;
            default:
              break;
          }
        },
        paymentFormLoaded: function () {
          // document.getElementById('name').style.display = "inline-flex";
        }
      }
    };
    this.paymentForm = new this.props.paymentForm(config);
    this.paymentForm.build();
  }

  render() {
    return (
      <div className="card_container">
        <div id="form-container">
          <div id="sq-walletbox">
            <button style={{ display: (this.state.applePay) ? 'inherit' : 'none' }}
              className="wallet-button"
              id="sq-apple-pay"></button>
            <button style={{ display: (this.state.masterpass) ? 'block' : 'none' }}
              className="wallet-button"
              id="sq-masterpass"></button>
            {/* <button style={{display: (this.state.googlePay) ? 'inherit': 'none'}}
                    className="wallet-button"
                    id="sq-google-pay"></button> */}
            <hr />
          </div>

          <div id="sq-ccbox">
            <p style={{ display: "none" }}>
              <span style={styles.leftCenter}>Enter Card Info Below </span>
              <span style={styles.blockRight}>
                {this.state.cardBrand.toUpperCase()}
              </span>
            </p>
            <div id="cc-field-wrapper" className="row">
              <div className="col-9 cardnumcontainer">
                <div id="sq-card-number"></div>
              </div>
              <div id="sq-postal-code" className="col-3"></div>
              <input type="hidden" id="card-nonce" name="nonce" />
              <div className="col-6 expdatecontainer">
                <div id="sq-expiration-date"></div>
              </div>
              <div id="sq-cvv" className="col-6"></div>
            </div>
            {/* <input
              id="name"
              style={styles.name}
              type="text"
              placeholder="Name"
            /> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <button
              className="button-credit-back btn"
              onClick={this.props.backFunction}
            >
              Back
            </button>
            <button
              className={`button-credit-card btn ${clsx(
                {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                }
              )}`}
              onClick={this.requestCardNonce}
              // disabled={this.state.loading}
              // className={`btn btn-primary button-credit-card btn-elevate kt-login__btn-primary ${clsx(
              //   {
              //     "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
              //   }
              // )}`}
              style={this.state.loadingButtonStyle}
            >
              Confirm Order
            </button>
          </div>
        </div>
        <p style={styles.center} id="error"></p>
      </div>
    )
  }
}