import React from 'react';
import Icon from '@material-ui/core/Icon';
// import { toAbsoluteUrl } from '../../../../_metronic';

export default function TeamSalesItem(props) {
    const userInfo = props.userInfo;
    const leader = props.leader;

    return (
        <div
            className="team_sales_item"
            style={{
                width: 251,
                height: 58,
                border: "1px solid #707070",
                borderRadius: 5,
                display: "flex",
                alignItem: "center",
                marginBottom: 10,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: 6
                }}
            >
                <img
                    alt="user_photo"
                    // width="50px"
                    height="50px"
                    className="rounded-circle"
                    src={userInfo ? userInfo.photo : ""}
                />
                <div>
                    <span
                        style={{ fontSize: 16, fontWeight: "500", color: "#111111" }}
                    >{userInfo ? userInfo.name : ""}</span><br />
                    {
                        leader ? null :
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span
                                    style={{ fontSize: 16, fontWeight: "500", color: "rgba(17, 17, 17)", marginRight: 10 }}
                                >
                                    ${userInfo ? userInfo.amountMoney.toFixed(2) : 0}
                                </span>
                                <Icon style={{ width: 19, opacity: 0.46 }} className="fa fa-sort-amount-down" />
                            </div>
                    }
                </div>
                <div
                    style={{
                        width: 58,
                        height: 57,
                        background: "#81B600",
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                        textAlign: "center",
                        paddingTop: 5,
                    }}
                >
                    {
                        leader ? null :
                            <span
                                style={{ fontSize: 18, fontWeight: "bold", color: "white" }}
                            >
                                {userInfo ? userInfo.bookCount : 0}
                            </span>
                    }
                    <br />
                    {
                        leader ? null :
                            <span style={{ fontSize: 16, fontWeight: "500", color: "#111111" }}>
                                Books
                            </span>
                    }
                </div>
            </div>
        </div>
    );
}