import React from "react";
// import { Link } from "react-router-dom";
import Registration from "./RegistrationHome";
import MyNavbar from "../../components/MyNavbar";
import { Helmet } from "react-helmet";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";
import "../css/homepage.css";
import HomeProgramItem from "../../components/HomeProgramItem";
import ContactUs from "../../components/ContactUs";
import NewsLetter from "../../components/NewsLetter";
import GoTop from "../../components/GoTop";
import { Link } from "react-scroll";

export default function AuthPage() {
  return (
    <>
      {/* https://github.com/nfl/react-helmet */}
      <Helmet>
        {/* <link
          type="text/css"
          rel="stylesheet"
          href={toAbsoluteUrl(
            "/assets/css/demo1/style.bundle.css"
          )}
        />
        <link
          type="text/css"
          rel="stylesheet"
          href={toAbsoluteUrl(
            "/assets/css/demo1/pages/login/login-1.css"
          )}
        /> */}
      </Helmet>

      <div className="home_container">
        <MyNavbar />
        <div
          className="container-fluid"
        >
          <div className="row content_section"
            id="kt_login"
            style={{
              paddingBottom: "250px",
            }}>
            <div className="col-md-6">

              <div
                className=""
                style={{
                  textAlign: "center"
                }}
              >
                <img
                  alt="bg-auth"
                  className="img-fluid"
                  src={toAbsoluteUrl("/media/bg/bg-auth.png")}
                />
              </div>
              <div className="kt-login__head" style={{ textAlign: "center", marginTop: "-30px" }}>
                <span className="kt-login__signup-label" style={{ fontSize: "18px" }}>
                  Already User?
                </span>
                &nbsp;&nbsp;
                <Link to="/auth/login" className="kt-link kt-login__signup-link" style={{ fontSize: "18px", fontWeight: "500", fontFamily: "Open Sans" }}>
                  Sign In Now!
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <Registration />
            </div>
          </div>
        </div>
        <div id="about" className="container-fluid" style={{ background: `url(${toAbsoluteUrl("/media/bg/Group14.png")})`, backgroundSize: "cover" }}>
          <div className="row about_container content_section">
            <div className="col-md-2">
            </div>
            <div className="col-9 col-md-7 div_about_middle">
              <p>About</p>
              <h1>Complete CRM for your programs</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut accumsan quam in diam porta, quis hendrerit urna eleifend. Cras eget velit non leo malesuada ullamcorper.
              </p>
            </div>
            <div className="col-3 col-md-3" style={{ paddingTop: "53px", paddingRight: "4%" }}>
              <img
                alt="bg-auth"
                className="img-fluid"
                src={toAbsoluteUrl("/media/logos/YR Logo Icon.png")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
        <div id="services" className="container-fluid">
          <div className="row program_container content_section">
            <div className="col-md-2"></div>
            <div className="col-md-2 program_left">
              <div className="program_bar"></div>
            </div>
            <div className="col-sm-12 col-md-6 program_right">
              <p>WHY USE THE YOUTH RUSH APP</p>
              <h1>It will simply your programs process.</h1>
              <div className="row" style={{ marginTop: "80px" }}>
                <div className="col-md-6">
                  <HomeProgramItem
                    icon="/media/icons/recycle3.png"
                    title="Live POS System"
                    content="Pellentesque vestibulum orci, id eleifend ante cursus vitae. "
                  />
                </div>
                <div className="col-md-6">
                  <HomeProgramItem
                    icon="/media/icons/rocket.png"
                    title="Fast analytics for your team"
                    content="Vestibulum orci, id eleifend ante cursus vitae. In eget enim "
                  />
                </div>
                <div className="col-md-6">
                  <HomeProgramItem
                    icon="/media/icons/vector.png"
                    title="Inventory Management"
                    content="Vestibulum orci, id eleifend ante cursus vitae. In eget enim "
                  />
                </div>
                <div className="col-md-6">
                  <HomeProgramItem
                    icon="/media/icons/target.png"
                    title="Evangelistic Follow up"
                    content="Vestibulum orci, id eleifend ante cursus vitae. In eget enim "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="contact" className="container-fluid" style={{ background: "#F8F8F8" }}>
          <div className="row contact_container content_section" style={{ paddingTop: "200px", paddingBottom: "270px" }}>
            <div className="col-md-3"></div>
            <div className="col-md-6 div_form">
              <ContactUs
                title="Contact us with any questions"
                subtitle="Questions about this software, comments also welcome."
              />
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="footer_container content_section" style={{ paddingTop: "28px", paddingBottom: "22px" }}>

            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-sm-12 col-md-8" style={{ borderBottom: "1px solid #EBEBEB", paddingBottom: "56px" }}>
                <div className="row">
                  <div className="meet_app col-sm-6">
                    <div style={{ paddingBottom: "82px" }}>
                      <img
                        alt="bottom-logo"
                        className="img-fluid"
                        src={toAbsoluteUrl("/media/logos/logo-4.png")}
                      />
                    </div>
                    <h4 style={{ marginBottom: "21px", fontSize: "18px", fontWeight: "normal", color: "black" }}>
                      Meet the Youth Rush App
                    </h4>
                    <Link
                      style={{ fontSize: "14px", fontWeight: "normal", color: "#7d7d7d" }}
                      to="about"
                      className=""
                      spy={true} smooth={true} offset={-70} duration={500}
                    >
                      About us
                    </Link><br />
                    <Link
                      style={{ fontSize: "14px", fontWeight: "normal", color: "#7d7d7d", marginBottom: "15px" }}
                      to="#"
                      className=""
                    >
                      How it Works
                    </Link><br />
                    <Link
                      style={{ fontSize: "14px", fontWeight: "normal", color: "#7d7d7d" }}
                      to="#"
                      className=""
                    >
                      Support
                    </Link><br />
                    <Link
                      style={{ fontSize: "14px", fontWeight: "normal", color: "#7d7d7d" }}
                      to="#"
                      className=""
                    >
                      Pricing
                    </Link><br />
                    <Link
                      style={{ fontSize: "14px", fontWeight: "normal", color: "#7d7d7d" }}
                      to="contact"
                      className=""
                      spy={true} smooth={true} offset={-70} duration={500}
                    >
                      Contact us
                    </Link>
                  </div>
                  <div className="newsletter col-sm-6" style={{ paddingTop: "125px" }}>
                    <NewsLetter
                      title="Email Newsletters"
                      description="Keep me up to date with content, updates, and offers from Youth Rush App"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer row">
              <div className="col-md-2"></div>
              <div className="col-sm-12 col-md-8" style={{ paddingTop: "35px" }}>
                <div className="footer_menu">
                  <Link
                    style={{ fontSize: "14px", fontWeight: "normal", color: "black", marginRight: "30px" }}
                    to="#"
                    className=""
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    style={{ fontSize: "14px", fontWeight: "normal", color: "black", marginRight: "30px" }}
                    to="#"
                    className=""
                  >
                    Terms and Conditions
                  </Link>
                  <Link
                    style={{ fontSize: "14px", fontWeight: "normal", color: "black", marginRight: "30px" }}
                    to="#"
                    className=""
                  >
                    Help
                  </Link>
                  <Link
                    style={{ fontSize: "14px", fontWeight: "normal", color: "black", marginRight: "30px" }}
                    to="#"
                    className=""
                  >
                    Youth Rush App Licenses
                  </Link>
                </div>
                <div className="footer_copyright" style={{ fontSize: "14px", fontWeight: "normal", color: "#9FA4AF", marginTop: "33px" }}>
                  {"© 2020 "}
                  <Link
                    style={{ fontSize: "14px", fontWeight: "normal", color: "#4CA9F9" }}
                    to="#"
                    className=""
                  >
                    Youth Rush Central
                </Link>
                  {" - All Rights Reserved."}
                </div>
              </div>
            </div>
          </div>
        </div>
        <GoTop scrollStepInPx="50" delayInMs="15" />
      </div>
    </>
  );
}
