import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import cn from 'classnames';
import _ from 'lodash';

export function Card(props) {
  return _.flowRight(props.connectDragSource, props.connectDropTarget)(
    <div
      className={cn('Card', {
        'Card--dragging': props.isDragging,
        'Card--spacer': props.isSpacer,
      })}
      style={{
        display: "flex", 
        alignItems: "center", 
        // justifyContent: "center"
      }}
    >
      <img
        alt="card_photo"
        src={props.photo}
        height="50px"
        className="rounded-circle"
       />
      <span style={{fontSize: 16, fontWeight: "500", color: "#111111", marginLeft: 20, overflow: "hidden"}}>{props.name}</span>
    </div>
  );
}

export const DraggableCard = _.flowRight([
  DropTarget(
    'Card',
    {
      hover(props, monitor) {
        const { columnId, columnIndex } = props;
        const draggingItem = monitor.getItem();
        if (columnId.indexOf("L") >= 0) {
          return;
        }
        if (draggingItem.id !== props.id && draggingItem.role === props.role) {
          props.moveCard(draggingItem.id, columnId, columnIndex);
        }
      },
    },
    connect => ({
      connectDropTarget: connect.dropTarget(),
    })
  ),
  DragSource(
    'Card',
    {
      beginDrag(props) {
        return { id: props.id, role: props.role };
      },

      isDragging(props, monitor) {
        return props.id === monitor.getItem().id;
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  ),
])(Card);
