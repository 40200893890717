import React from 'react';
import {useParams} from 'react-router';
import Header from './update_components/Header';
import Footer from './update_components/Footer';
import Content from './update_components/Content';

export default function UpdatePassword() {
  const {userId} = useParams();
  return (
    <>
      <Header />
      <Content userId={userId} />
      <Footer />
    </>
  );
}
