import React, { useState, useEffect } from 'react';
// import { DragDropContext } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import _ from 'lodash';
import { KanbanColumn } from './Column';
import { DraggableCard } from './Card';
// import { TextForm } from './TextForm';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
// import Button from "@material-ui/core/Button";
import app from "../../../components/base";
import CircularIntegration from "../common_component/CircularIntegration";
// import { toAbsoluteUrl } from "../../../../_metronic";
import { GET_USER } from "../../../components/constants";

// const availableLeaders = [
//   {
//     id: 0,
//     photo: toAbsoluteUrl('/media/users/user1.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 1,
//     photo: toAbsoluteUrl('/media/users/user2.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 2,
//     photo: toAbsoluteUrl('/media/users/user3.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 3,
//     photo: toAbsoluteUrl('/media/users/user4.jpg'),
//     name: "Perla Cuevas"
//   },
// ];

// const availableStudents = [
//   {
//     id: 4,
//     photo: toAbsoluteUrl('/media/users/user1.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 5,
//     photo: toAbsoluteUrl('/media/users/user2.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 6,
//     photo: toAbsoluteUrl('/media/users/user3.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 7,
//     photo: toAbsoluteUrl('/media/users/user4.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 8,
//     photo: toAbsoluteUrl('/media/users/user1.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 9,
//     photo: toAbsoluteUrl('/media/users/user2.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 10,
//     photo: toAbsoluteUrl('/media/users/user3.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 11,
//     photo: toAbsoluteUrl('/media/users/user4.jpg'),
//     name: "Perla Cuevas"
//   },
//   {
//     id: 12,
//     photo: toAbsoluteUrl('/media/users/user5.jpg'),
//     name: "Perla Cuevas"
//   },
// ];

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
    background: "#36425C",
    color: "white"
  },
}));


// const initialCards = [...availableLeaders, ...availableStudents];

// const initialLeaderColumns = Array.from({ length: 3 }).map(() => ({
//   id: "L" + _leaderColumnId++,
//   role: "Leader",
//   cardIds: [],
// }));

// const initialStudentColumns = Array.from({ length: 3 }).map((j, i) => ({
//   id: "S" + _studentColumnId++,
//   role: "Student",
//   cardIds: [],
// }));

// const initialAvailableLeaderColumns = Array.from({ length: 5 }).map((j, i) => ({
//   id: "LL" + _availableleaderColumnId++,
//   cardIds: availableLeaders[i] ? [availableLeaders[i].id] : [],
// }));

// const initialAvailableStudentColumns = Array.from({ length: 10 }).map((j, i) => ({
//   id: "LS" + _availablestudentColumnId++,
//   cardIds: availableStudents[i] ? [availableStudents[i].id] : [],
// }));

// const initialColumns = [...leaderColumns, ...studentColumns];

function isExistCard(userId, cards) {
  let res = false;
  cards.forEach(card => {
    if (card.userId === userId) {
      res = true;
    }
  })
  return res;
}

let _leaderColumnId = 0;
let _studentColumnId = 0;

let mycardId = 0;

let _availableleaderColumnId = 0;
let _availablestudentColumnId = 0;

export default function Kanban(props) {


  const classes = useStyles();
  const programId = props.programId;
  const teamType = props.type;

  const [fetchedUserState, setFetchedUserState] = useState(false);
  // const [columnState, setColumnState] = useState(false);
  const [cardLength, setCardLength] = useState(0)

  const [cards, setCards] = useState([]);
  const [leaderColumns, setLeaderColumns] = useState([]);
  const [studentColumns, setStudentColumns] = useState([]);
  const [teamIds, setTeamIds] = useState([]);
  const [availableLeaderColumns, setAvailableLeaderColumns] = useState([]);
  const [availableStudentColumns, setAvailableStudentColumns] = useState([]);

  const columns = { leaderColumns: leaderColumns, studentColumns: studentColumns, teamIds: teamIds };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setFetchedUserState(true);
    // setColumnState(true);
  }, []);

  if (!fetchedUserState) {
    setFetchedUserState(true);
    app.database().ref().child(`programs/${programId}/teams`).once("value")
      .then(snapshot => {
        const teams = snapshot.val();
        if (teams) {
          if (teams[teamType]) {
            const teamsWithType = teams[teamType];
            let initialLeaderColumns = [];
            let initialStudentColumns = [];
            let initialTeamIds = [];

            teamsWithType.forEach(teamWithKey => {
              const [teamId] = Object.keys(teamWithKey);
              initialTeamIds.push(teamId);
              let team = teamWithKey[teamId];

              // let bufferLeaderIds = [];
              const bufferLeaderIds = team.leaders ? team.leaders.map(card => {
                // if (!isExistCard(card.userId, cards)) {
                return card.userId;
                // setCards([...cards, currentCard]);
                // }
              }) : [];

              const bufferStudentIds = team.students ? team.students.map(card => {
                // // if (!isExistCard(card.userId, cards)) {
                // let currentCard = {
                //   id: card.userId,
                //   userId: card.userId,
                //   teamId: teamId,
                //   role: card.role,
                //   photo: card.photo,
                //   name: card.name,
                // };
                // // setCards([...cards, currentCard]);
                // bufferStudentIds.push(currentCard.id);
                // // }
                return card.userId;
              }) : [];

              // if (!columnState) {
              //   setColumnState(true);
              initialStudentColumns.push({
                id: "S" + _studentColumnId++,
                role: "Student",
                cardIds: bufferStudentIds,
              });


              initialLeaderColumns.push({
                id: "L" + _leaderColumnId++,
                role: "Leader",
                cardIds: bufferLeaderIds,
              });
              // }
            })

            setStudentColumns(initialStudentColumns);
            setLeaderColumns(initialLeaderColumns);
            setTeamIds(initialTeamIds);
          }
        }
      })

    fetch(GET_USER + "/" + programId, { method: "GET" })
      .then(
        response => {
          return response.text()
        }
      )
      .then(
        data => {
          const users = JSON.parse(data);
          if (users) {
            let bufferCards = [];
            Object.keys(users).forEach(userId => {
              if (!isExistCard(userId, cards)) {
                bufferCards.push({
                  id: userId,
                  userId: userId,
                  teamId: users[userId].teamId ? users[userId].teamId : null,
                  role: users[userId].role,
                  photo: users[userId].image,
                  name: users[userId].firstName + " " + users[userId].lastName
                });
              }
            })

            const initialCards = [...cards, ...bufferCards];
            setCards(initialCards);

          }
        }
      )

  }

  const addColumn = () => {
    setLeaderColumns([...leaderColumns, {
      id: "L" + _leaderColumnId++,
      role: "Leader",
      cardIds: [],
    }]);

    setStudentColumns([...studentColumns, {
      id: "S" + _studentColumnId++,
      role: "Student",
      cardIds: [],
    }]);
  };

  const addCard = (columnId, _title) => {
    const title = _title.trim();
    if (!title) return;

    const newCard = { id: ++mycardId, title };
    setCards([...cards, newCard]);
    setLeaderColumns(leaderColumns.map(
      column =>
        column.id === columnId
          ? { ...column, cardIds: [...column.cardIds, newCard.id] }
          : column
    ));
  };

  const moveCard = (cardId, destColumnId, index) => {
    setLeaderColumns(
      leaderColumns.map(column => ({
        ...column,
        cardIds: _.flowRight(
          // 2) If this is the destination column, insert the cardId.
          ids =>
            column.id === destColumnId
              ? [...ids.slice(0, index), cardId, ...ids.slice(index)]
              : ids,
          // 1) Remove the cardId for all columns
          ids => ids.filter(id => id !== cardId)
        )(column.cardIds),
      }))
    );
    setStudentColumns(
      studentColumns.map(column => ({
        ...column,
        cardIds: _.flowRight(
          // 2) If this is the destination column, insert the cardId.
          ids =>
            column.id === destColumnId
              ? [...ids.slice(0, index), cardId, ...ids.slice(index)]
              : ids,
          // 1) Remove the cardId for all columns
          ids => ids.filter(id => id !== cardId)
        )(column.cardIds),
      }))
    );
    setAvailableLeaderColumns(
      availableLeaderColumns.map(column => ({
        ...column,
        cardIds: _.flowRight(
          // 2) If this is the destination column, insert the cardId.
          ids =>
            column.id === destColumnId
              ? [...ids.slice(0, index), cardId, ...ids.slice(index)]
              : ids,
          // 1) Remove the cardId for all columns
          ids => ids.filter(id => id !== cardId)
        )(column.cardIds),
      }))
    );
    setAvailableStudentColumns(
      availableStudentColumns.map(column => ({
        ...column,
        cardIds: _.flowRight(
          // 2) If this is the destination column, insert the cardId.
          ids =>
            column.id === destColumnId
              ? [...ids.slice(0, index), cardId, ...ids.slice(index)]
              : ids,
          // 1) Remove the cardId for all columns
          ids => ids.filter(id => id !== cardId)
        )(column.cardIds),
      }))
    );
  };

  const handleTeamSave = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
    }
    let currentTeams = [];
    Object.keys(columns.leaderColumns).forEach((j, i) => {
      // if (columns.leaderColumns[i].cardIds.length > 0 || columns.studentColumns[i].cardIds.length > 0) {
      const leaders = columns.leaderColumns[i].cardIds.map(cardId => {
        let resCard;
        cards.forEach(card => {
          if (card.id === cardId) {
            resCard = card
          }
        })
        return resCard;
      });
      const students = columns.studentColumns[i].cardIds.map(cardId => {
        let resCard;
        cards.forEach(card => {
          if (card.id === cardId) {
            resCard = card;
          }
        })
        return resCard;
      });

      let team = { leaders: leaders, students: students, created_at: (new Date()).toISOString() };
      var teamRef = app.database().ref().child("teams");
      if (columns.teamIds[i]) {
        teamRef.child(columns.teamIds[i]).update(team);
        if (columns.leaderColumns[i].cardIds.length > 0 || columns.studentColumns[i].cardIds.length > 0) {
          let currentTeam = {};
          leaders.forEach(card => {
            app.database().ref().child(`users/${card.userId}`).update({ teamId: columns.teamIds[i] });
            card.teamId = columns.teamIds[i];
          })
          students.forEach(card => {
            app.database().ref().child(`users/${card.userId}`).update({ teamId: columns.teamIds[i] });
            card.teamId = columns.teamIds[i];
          })
          currentTeam[columns.teamIds[i]] = team;
          currentTeams.push(currentTeam);
          let teamWithType = {};
          teamWithType[teamType] = currentTeams;
          app.database().ref().child(`programs/${programId}/teams`).update(teamWithType);
        }
      } else {
        if (columns.leaderColumns[i].cardIds.length > 0 || columns.studentColumns[i].cardIds.length > 0) {
          teamRef.push(team).then(response => {
            let currentTeam = {};
            leaders.forEach(card => {
              app.database().ref().child(`users/${card.userId}`).update({ teamId: response.key });
              card.teamId = response.key;
            })
            students.forEach(card => {
              app.database().ref().child(`users/${card.userId}`).update({ teamId: response.key });
              card.teamId = response.key;
            })
            currentTeam[response.key] = team;
            currentTeams.push(currentTeam);
            let teamWithType = {};
            teamWithType[teamType] = currentTeams;
            app.database().ref().child(`programs/${programId}/teams`).update(teamWithType);
          })
        }
      }
      // }
    })

    availableLeaderColumns.forEach(column => {
      column.cardIds.forEach(id => {
        app.database().ref().child(`users/${id}`).update({ teamId: null });
      })
    })
    availableStudentColumns.forEach(column => {
      column.cardIds.forEach(id => {
        app.database().ref().child(`users/${id}`).update({ teamId: null });
      })
    })

    setSuccess(true);
    setLoading(false);
  }

  if (cardLength !== cards.length) {
    setCardLength(cards.length);

    let availableLeaders = [];
    let availableStudents = [];
    cards.forEach(card => {
      if (card.role === "Leader") {
        if (card.teamId == null) {
          availableLeaders.push(card);
        }
      } else {
        if (card.teamId == null) {
          availableStudents.push(card);
        }
      }
    })

    const initialAvailableLeaderColumns = Array.from({ length: availableLeaders.length }).map((j, i) => ({
      id: "LL" + _availableleaderColumnId++,
      role: "Leader",
      cardIds: availableLeaders[i] ? [availableLeaders[i].id] : [],
    }));
    initialAvailableLeaderColumns.push({
      id: "LL" + _availableleaderColumnId++,
      role: "Leader",
      cardIds: [],
    })
    setAvailableLeaderColumns(initialAvailableLeaderColumns);

    const initialAvailableStudentColumns = Array.from({ length: availableStudents.length }).map((j, i) => ({
      id: "LS" + _availablestudentColumnId++,
      role: "Student",
      cardIds: availableStudents[i] ? [availableStudents[i].id] : [],
    }));
    initialAvailableStudentColumns.push({
      id: "LS" + _availablestudentColumnId++,
      role: "Student",
      cardIds: [],
    })
    setAvailableStudentColumns(initialAvailableStudentColumns);
  }


  return (
    <DndProvider backend={HTML5Backend}>
      <div className="Board row">
        {cards.length > 0 ?
          columns.leaderColumns.map((leaderColumn, i) => (
            <div className="col-sm-6 col-md-4 col-xl-3" key={i}>
              <div className="Column__container">
                <div className="Column__leader">
                  <div className="Column__title" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    Leader
                  <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <KanbanColumn
                    key={leaderColumn.id}
                    id={leaderColumn.id}
                    role={leaderColumn.role}
                    title={leaderColumn.title}
                    cardCount={leaderColumn.cardIds.length}
                    moveCard={moveCard}
                    addCard={addCard.bind(null, leaderColumn.id)}
                  >
                    {leaderColumn.cardIds
                      .map(cardId => cards.find(card => card.id === cardId))
                      .map((card, index) => (
                        <DraggableCard
                          key={card.id}
                          id={card.id}
                          role={card.role}
                          photo={card.photo}
                          columnId={leaderColumn.id}
                          columnIndex={index}
                          name={card.name}
                          moveCard={moveCard}
                        />
                      ))}
                  </KanbanColumn>
                </div>
                <div className="ColumnLeaderBound"></div>
                <KanbanColumn
                  key={columns.studentColumns[i].id}
                  id={columns.studentColumns[i].id}
                  role={columns.studentColumns[i].role}
                  title={columns.studentColumns[i].title}
                  cardCount={columns.studentColumns[i].cardIds.length}
                  moveCard={moveCard}
                  addCard={addCard.bind(null, columns.studentColumns[i].id)}
                >
                  {columns.studentColumns[i].cardIds
                    .map(cardId => cards.find(card => card.id === cardId))
                    .map((card, index) => (
                      <DraggableCard
                        key={card.id}
                        id={card.id}
                        role={card.role}
                        photo={card.photo}
                        columnId={columns.studentColumns[i].id}
                        columnIndex={index}
                        name={card.name}
                        moveCard={moveCard}
                      />
                    ))}
                </KanbanColumn>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                >
                  <span style={{ fontSize: 18, color: "rgba(17, 17, 17, 0.7)" }}>
                    Add more students
                </span>
                  <Fab className={classes.fab} style={{ width: 36, height: 36 }}>
                    <AddIcon />
                  </Fab>
                </div>
              </div>
            </div>
          )) : null}
        <div className="col-sm-6 col-md-4 col-xl-3">
          <div className="Column__container add_column">
            <div style={{ textAlign: "center" }}>
              <Fab className={classes.fab} onClick={addColumn}>
                <AddIcon />
              </Fab>
              <p style={{ fontSize: 18, color: "rgba(17, 17, 17, 0.7)" }}>
                New Team
                </p>
            </div>
          </div>
        </div>
      </div>

      <CircularIntegration
        caption="Save Team"
        loading={loading}
        success={success}
        handleButtonClick={handleTeamSave}
      />

      {/* <Button
        variant="contained"
        style={{
          background: "#36435C",
          color: "white",
          fontSize: "16px",
          fontWeight: "bold",
          width: "216px",
          height: "44px",
          borderRadius: "3px",
          textTransform: "none",
        }}
      >
        Save Team
      </Button> */}

      <div className="available_leaders" style={{ padding: 30, marginTop: 28, background: "white" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 26 }}>
          <h3 style={{ fontSize: 30, color: "#111111" }}>Available leaders</h3>
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </div>
        <div className="row">
          {cards.length > 0 ?
            Array.from({ length: availableLeaderColumns.length }).map((j, i) => (
              <div className="col-sm-6 col-md-4 col-xl-3" key={i}>
                <KanbanColumn
                  key={availableLeaderColumns[i].id}
                  id={availableLeaderColumns[i].id}
                  role={availableLeaderColumns[i].role}
                  title={availableLeaderColumns[i].title}
                  cardCount={availableLeaderColumns[i].cardIds.length}
                  moveCard={moveCard}
                  addCard={addCard.bind(null, availableLeaderColumns[i].id)}
                >
                  {availableLeaderColumns[i].cardIds
                    .map(cardId => cards.find(card => card.id === cardId))
                    .map((card, index) => (
                      <DraggableCard
                        key={card.id}
                        id={card.id}
                        role={card.role}
                        photo={card.photo}
                        columnId={availableLeaderColumns[i].id}
                        columnIndex={index}
                        name={card.name}
                        moveCard={moveCard}
                      />
                    ))}
                </KanbanColumn>
              </div>
            )) : null}
        </div>
      </div>

      <div className="available_students" style={{ padding: 30, marginTop: 28, background: "white" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 26 }}>
          <h3 style={{ fontSize: 30, color: "#111111" }}>Available students</h3>
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </div>
        <div className="row">
          {cards.length > 0 ?
            Array.from({ length: availableStudentColumns.length }).map((j, i) => (
              <div className="col-sm-6 col-md-4 col-xl-3" key={i}>
                <KanbanColumn
                  key={availableStudentColumns[i].id}
                  id={availableStudentColumns[i].id}
                  role={availableStudentColumns[i].role}
                  title={availableStudentColumns[i].title}
                  cardCount={availableStudentColumns[i].cardIds.length}
                  moveCard={moveCard}
                  addCard={addCard.bind(null, availableStudentColumns[i].id)}
                >
                  {availableStudentColumns[i].cardIds
                    .map(cardId => cards.find(card => card.id === cardId))
                    .map((card, index) => (
                      <DraggableCard
                        key={card.id}
                        id={card.id}
                        role={card.role}
                        photo={card.photo}
                        columnId={availableStudentColumns[i].id}
                        columnIndex={index}
                        name={card.name}
                        moveCard={moveCard}
                      />
                    ))}
                </KanbanColumn>
              </div>
            )) : null}
        </div>
      </div>
    </DndProvider>

  );
}

// export default DragDropContext(HTML5Backend)(App);
