import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { GOOGLE_MAP_API_KEY } from '../../../components/constants';

function SimpleMap(props) {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const data = props.data;
  const initData = {
    center: {
      lat: 36.7992841,
      lng: -119.7286572
    },
    zoom: 11
  };

  useEffect(() => {
    setMarkerData(data);
  }, [data])

  return (
    // Important! Always set the container height explicitly
    <div
      style={{
        position: "relative",
        height: 485,
        width: props.containerWidth - 80,
      }}
    >
      <Map
        google={props.google}
        initialCenter={initData.center}
        zoom={initData.zoom}
      // style={{ height: 484, width: "100%" }}
      >
        {
          markerData.length > 0 ?
            markerData.map(oneData => {
              const name = oneData.name;
              const locations = oneData.location;
              return locations.map((location, i) => {
                return <Marker
                  key={i}
                  position={{
                    lat: location.latitude,
                    lng: location.longitude
                  }}
                  onClick={(props, marker) => {
                    setActiveMarker(marker);
                    setSelectedPlace(location);
                    setSelectedName(name);
                  }}
                />
              })
            })
            : null
        }

        <InfoWindow
          marker={activeMarker}
          visible={Boolean(selectedPlace && selectedName)}
          onClose={() => {
            setSelectedPlace(null);
            setSelectedName(null);
            setActiveMarker(null);
          }}
        >
          {
            selectedPlace ?
              <div>
                <h3>{selectedName}</h3>
                <h5>{`lat: ${selectedPlace.latitude}`}</h5>
                <h5>{`lng: ${selectedPlace.longitude}`}</h5>
              </div>
              : <div></div>
          }
        </InfoWindow>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({ apiKey: GOOGLE_MAP_API_KEY })(SimpleMap);