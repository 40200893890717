import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import RoundTextField from "../../components/RoundTextField";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import app from "../../components/base";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    height: "50px",
    fontWeight: "500",
    fonSize: "1rem",
    paddingLeft: "2.75rem",
    borderRadius: "20px"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({
      paddingRight: "3.5rem",
      height: "50px",
      fontWeight: "500",
      fonSize: "1rem",
      paddingLeft: "2.75rem",
      borderRadius: "20px"
    });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({
      paddingRight: "2.5rem",
      height: "50px",
      fontWeight: "500",
      fonSize: "1rem",
      paddingLeft: "2.75rem",
      borderRadius: "20px"
    });
  };

  return (
    <>
      {/* <div className="kt-login__head">
        <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Sign Up!
        </Link>
      </div> */}

      <div className="kt-login__body">
        <div className="kt-login__form" style={{ padding: "90px 10%" }}>
          <div className="kt-login__title" style={{ marginBottom: "40px" }}>
            <h1 style={{ color: "#4F4F6F", fontWeight: "400", fontFamily: "Open Sans", fontSize: "48px" }}>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h1>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                login(values.email, values.password)
                  .then(() => {

                    app.auth().onAuthStateChanged(function (user) {
                      if (user != null) {
                        const currentUser = user;
                        const accessToken = currentUser.uid;
                        const userId = currentUser.uid;
                        app.database().ref('/admins/' + userId).once('value').then(function (snapshot) {
                          const subflag = snapshot.val().subflag;
                          disableLoading();
                          props.login(accessToken, subflag);
                        });
                      } else {
                        // this.name = "Unknown";
                        // alert("Login failed");
                      }
                    });

                  })
                  .catch((error) => {
                    console.log(error);
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      // intl.formatMessage({
                      //   id: error.message
                      // })
                      error.message
                    );
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="kt-form"
                  onSubmit={handleSubmit}
                >
                  {status ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  ) : (
                      <div>
                        {/* <div className="alert-text">
                      Use account <strong>admin@demo.com</strong> and password{" "}
                      <strong>demo</strong> to continue.
                    </div> */}
                      </div>
                    )}

                  {/* <div className="form-group">
                    <TextField
                      type="email"
                      label="Email"
                      margin="normal"
                      className="kt-width-full"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div> */}
                  <div className="form-group" style={{ marginBottom: 0 }}>
                    <RoundTextField
                      type="text"
                      headerLabel="Email Address"
                      placeholder="Your email address"
                      name="email"
                      className="kt-width-full"
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div>
                  <div className="form-group">
                    <RoundTextField
                      type="password"
                      headerLabel="Password"
                      placeholder="Your password"
                      className="kt-width-full"
                      name="password"
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                  </div>

                  <div className="kt-login__actions"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "3rem 0"
                    }}>
                    <Link
                      to="/auth/forgot-password"
                      className="kt-link kt-login__link-forgot"
                      style={{ fontSize: "18px", fontWeight: "500" }}
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>

                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Sign In
                  </button>
                  </div>
                </form>
              )}
          </Formik>

          {/* <div className="kt-login__divider">
            <div className="kt-divider">
              <span />
              <span>OR</span>
              <span />
            </div>
          </div>

          <div className="kt-login__options">
            <Link to="http://facebook.com" className="btn btn-primary kt-btn">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="http://twitter.com" className="btn btn-info kt-btn">
              <i className="fab fa-twitter" />
              Twitter
            </Link>
            <Link to="google.com" className="btn btn-danger kt-btn">
              <i className="fab fa-google" />
              Google
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
