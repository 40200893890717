import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { StepButton, StepLabel, StepConnector } from '@material-ui/core';
import "./css/myhstepper.css"
import { connect, shallowEqual, useSelector } from "react-redux";
import * as auth from "../store/ducks/auth.duck";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '876px',
        margin: "0 auto", 
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconContainer: {
        border: "4px solid #D6D6D6",
        color: "#014386",
        background: "#D6D6D6",
        borderRadius: "50%"
    },
    alternativeLabel: {
        color: "#014386",
        fontSize: "14px",
        fontWeight: "normal",
        fontFamily: "Open Sans"
    },
    activeAlternativeLabel: {
        color: "#014386 !important",
        fontSize: "14px",
        fontWeight: "normal !important",
        fontFamily: "Open Sans"
    },
    text: {
        display: "none"
    },
    iconroot: {
        width: "63px",
        height: "63px",
        color: "#D6D6D6",
        border: "5px solid #D6D6D6",
        borderRadius: "50%"
    },
    iconactive: {
        color: "#3692EE !important",
        border: "5px solid white"
    },
    connectorLineHorizontal: {
        borderWidth: "4px",
        borderColor: "#D6D6D6"
    }
}));


function getSteps() {
    return ['Account', 'Conference', 'Programs', 'Billing', 'Review & Pay'];
}

function MyHStepper(props) {
    const { currentStep } = useSelector(({auth}) => ({currentStep: auth.activeStep}), shallowEqual);
    const classes = useStyles();
    const steps = getSteps();

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={currentStep}
                connector={
                    <StepConnector
                        style={{ top: "33px", zIndex: "1", borderWidth: "4px" }}
                        classes={{
                            lineHorizontal: classes.connectorLineHorizontal
                        }}
                    />
                }
            >
                {steps.map((label, index) => {
                    const stepProps = index > 0 && index < 4 ? {} : {disabled: true};
                    const buttonProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepButton
                                onClick={() => {
                                    // props.setActiveStep(index);
                                }}
                                {...buttonProps}
                                style={{ zIndex: "2" }}
                            >
                                <StepLabel
                                    classes={{
                                        iconContainer: classes.iconContainer,
                                        alternativeLabel: classes.alternativeLabel,
                                        active: classes.activeAlternativeLabel
                                    }}
                                    StepIconProps={{
                                        classes: { text: classes.text, root: classes.iconroot, active: classes.iconactive }
                                    }}
                                >
                                    {label}
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
}

export default connect(null, auth.actions)(MyHStepper);