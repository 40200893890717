import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { GOOGLE_MAP_API_KEY } from '../../../components/constants';

function SimpleMap(props) {
  const [selectedNotes, setSelectedNotes] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const interests = props.interests;
  const initData = {
    center: {
      lat: 36.7992841,
      lng: -119.7286572
    },
    zoom: 11
  };

  useEffect(() => {
    setMarkerData(interests);
  }, [interests])

  return (
    // Important! Always set the container height explicitly
    <div
      style={{
        position: "relative",
        height: 485,
        // width: props.containerWidth - 80,
      }}
    >
      <Map
        google={props.google}
        initialCenter={initData.center}
        zoom={initData.zoom}
      // style={{ height: 484, width: "100%" }}
      >
        {
          markerData.length > 0 ?
            markerData.map((oneData, i) => {
              const name = oneData.customer.firstName + " " + oneData.customer.lastName;
              const note = oneData.customer.note;
              return <Marker
                key={i}
                position={{
                  lat: oneData.loc.latitude,
                  lng: oneData.loc.longitude
                }}
                onClick={(props, marker) => {
                  setActiveMarker(marker);
                  setSelectedNotes(note);
                  setSelectedName(name);
                }}
              />
            })
            : null
        }

        <InfoWindow
          marker={activeMarker}
          visible={Boolean(selectedName)}
          onClose={() => {
            setSelectedNotes(null);
            setSelectedName(null);
            setActiveMarker(null);
          }}
        >
          {
            selectedName ?
              <div>
                <h3>{selectedName}</h3>
                <h5>{`note: ${selectedNotes}`}</h5>
              </div>
              : <div></div>
          }
        </InfoWindow>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({ apiKey: GOOGLE_MAP_API_KEY })(SimpleMap);