import React from "react";
import { toAbsoluteUrl } from "../../_metronic";

export default function HomeProgramItem(props) {
    return (
        <div className="row" style={{marginBottom: "50px"}}>
            <div className="col-sm-3" style={{textAlign: "center"}}>
                <img
                    alt="icon_image"
                    style={{ height: "50px" }}
                    src={toAbsoluteUrl(`${props.icon}`)} />
            </div>
            <div className="col-sm-9">
                <h3 style={{color: "black", fontSize: "22px", fontWeight: "lighter"}}>{props.title}</h3>
                <p style={{color: "#7D7D7D", fontSize: "15px"}}>{props.content}</p>
            </div>
        </div>
    );
}