import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import SalesLeadersChart from './SalesLeadersChart';
import MyCustomRadio from './MyCustomRadio';

// const tableHead = ["Leader", "Week1", "Week2", "Week3", "Week4", "Week5", "Week6", "Week7", "Week8", "Week9", "Week10", "Total"]
// const rows = [
//     {
//         Leader: {
//             image: toAbsoluteUrl('/media/users/user1.jpg'),
//             name: "Perla Cuevas",
//         },
//         Week1: "2450.90",
//         Week2: "2450.90",
//         Week3: "2450.90",
//         Week4: "2450.90",
//         Week5: "2450.90",
//         Week6: "2450.90",
//         Week7: "2450.90",
//         Week8: "2450.90",
//         Week9: "2450.90",
//         Week10: "2450.90",
//         Total: "2450.90",
//     }, {},
//     {
//         Leader: {
//             image: toAbsoluteUrl('/media/users/user1.jpg'),
//             name: "Perla Cuevas",
//         },
//         Week1: "2450.90",
//         Week2: "2450.90",
//         Week3: "2450.90",
//         Week4: "2450.90",
//         Week5: "2450.90",
//         Week6: "2450.90",
//         Week7: "2450.90",
//         Week8: "2450.90",
//         Week9: "2450.90",
//         Week10: "2450.90",
//         Total: "2450.90",
//     }, {},
//     {
//         Leader: {
//             image: toAbsoluteUrl('/media/users/user1.jpg'),
//             name: "Perla Cuevas",
//         },
//         Week1: "2450.90",
//         Week2: "2450.90",
//         Week3: "2450.90",
//         Week4: "2450.90",
//         Week5: "2450.90",
//         Week6: "2450.90",
//         Week7: "2450.90",
//         Week8: "2450.90",
//         Week9: "2450.90",
//         Week10: "2450.90",
//         Total: "2450.90",
//     }, {},
// ]

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export default function SalesLeaders(props) {
    const teams = props.teams;
    const transactions = props.transactions;
    const users = props.users;
    const programInfo = props.programInfo;
    const startDate = programInfo.start_date;
    const endDate = programInfo.end_date;

    const [showState, setShowState] = React.useState(false);
    const [programSalesData, setProgramSalesData] = React.useState([]);
    const [programSalesWeekData, setProgramSalesWeekData] = React.useState([]);
    const [programSalesMonthData, setProgramSalesMonthData] = React.useState([]);
    const [programSalesColors, setProgramSalesColors] = React.useState([]);
    const [programSalesLabelChecks, setProgramSalesLabelChecks] = React.useState([]);

    const [tableHead, setTableHead] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const initProgramSalesData = () => {
        let programSalesData = [];
        let programSalesWeekData = [];
        let programSalesMonthData = [];
        programSalesData = teams.map(team => {
            let teamData = {};
            let teamGraphValue = {};
            const teamId = Object.keys(team)[0];
            const teamValue = Object.values(team)[0];
            teamData.name = teamValue.leaders[0].name;

            for (let d = new Date(), i = 0; i < 7; d.setDate(d.getDate() - 1), i++) {
                let dateValue = 0;
                const teamTransactions = transactions[teamId];
                if (teamTransactions) {
                    Object.keys(teamTransactions).forEach(key => {
                        const transaction = teamTransactions[key];
                        if (transaction.teamId === teamId) {
                            const tDate = new Date(transaction.transaction.createdAt);
                            if (tDate.getFullYear() === d.getUTCFullYear() &&
                                tDate.getMonth() === d.getUTCMonth() &&
                                tDate.getDate() === d.getUTCDate()) {
                                dateValue += transaction.transaction.amount_money.amount / 100;
                            }
                        }
                    })
                }
                teamGraphValue[(new Date(d.toUTCString())).toLocaleDateString()] = dateValue;
            }

            teamData.data = teamGraphValue;
            return teamData;
        })

        let tableHead = ["Leader"];
        let rows = [];
        programSalesWeekData = teams.map((team, j) => {
            let rowTotal = {};
            let rowAverage = {};
            let total = 0
            let teamData = {};
            let teamGraphValue = {};
            const teamId = Object.keys(team)[0];
            const teamValue = Object.values(team)[0];
            teamData.name = teamValue.leaders[0].name;
            let startDatesOfWeek = [startDate];

            rowTotal.Leader = {
                image: teamValue.leaders[0].photo,
                name: teamValue.leaders[0].name,
            }
            rowAverage.Leader = "Average";

            var firstMonday = new Date(startDate);
            firstMonday.setDate(firstMonday.getDate() + (1 + 7 - firstMonday.getDay()) % 7);
            if (firstMonday < new Date(endDate)) {
                for (let d = firstMonday; d < new Date(endDate); d.setDate(d.getDate() + 7)) {
                    startDatesOfWeek.push(d.toLocaleDateString());
                }
            }
            const last = new Date(endDate);
            last.setDate(last.getDate() + 1);
            startDatesOfWeek.push(last.toLocaleDateString);

            startDatesOfWeek.forEach((startDate, i) => {
                if (i !== startDatesOfWeek.length - 1) {
                    let dateValue = 0;
                    const teamTransactions = transactions[teamId];
                    if (teamTransactions) {
                        Object.keys(teamTransactions).forEach(key => {
                            const transaction = teamTransactions[key];
                            if (transaction.teamId === teamId) {
                                const tDate = new Date(transaction.transaction.createdAt);
                                const tempDate = new Date(tDate.getFullYear(), tDate.getMonth(), tDate.getDate());
                                const start = new Date(startDate);
                                const end = new Date(startDatesOfWeek[i + 1]);
                                if (tempDate >= start && tempDate < end) {
                                    dateValue += transaction.transaction.amount_money.amount / 100;
                                }
                            }
                        })
                    }
                    teamGraphValue[`Week${i + 1}`] = dateValue;
                    rowTotal[`Week${i + 1}`] = dateValue;
                    rowAverage[`Week${i + 1}`] = teamValue.students ? teamValue.students.length === 0 ? dateValue / teamValue.students.length : 0 : 0;
                    total += dateValue;

                    if (j === 0) {
                        tableHead.push(`Week${i + 1}`);
                    }
                }
            })

            rowTotal["Total"] = total;
            rowAverage["Total"] = teamValue.students ? teamValue.students.length === 0 ? total / teamValue.students.length : 0 : 0;

            rows.push(rowTotal);
            rows.push(rowAverage);

            teamData.data = teamGraphValue;
            return teamData;
        })
        tableHead.push("Total");

        setTableHead(tableHead);
        setRows(rows);

        programSalesMonthData = teams.map(team => {
            let teamData = {};
            let teamGraphValue = {};
            const teamId = Object.keys(team)[0];
            const teamValue = Object.values(team)[0];
            teamData.name = teamValue.leaders[0].name;
            let startDatesOfMonth = [startDate];

            var firstDay = new Date(startDate);
            for (let d = firstDay; d < new Date(endDate);
                d.setMonth(d.getMonth() + 1), d.setDate(1)) {
                startDatesOfMonth.push(d.toLocaleDateString());
            }
            const last = new Date(endDate);
            startDatesOfMonth.push(last.toLocaleDateString);

            startDatesOfMonth.forEach((startDate, i) => {
                if (i !== startDatesOfMonth.length - 1) {
                    let dateValue = 0;
                    const teamTransactions = transactions[teamId];
                    if (teamTransactions) {
                        Object.keys(teamTransactions).forEach(key => {
                            const transaction = teamTransactions[key];
                            if (transaction.teamId === teamId) {
                                const tDate = new Date(transaction.transaction.createdAt);
                                const tempDate = new Date(tDate.getFullYear(), tDate.getMonth(), tDate.getDate());
                                const start = new Date(startDate);
                                const end = new Date(startDatesOfMonth[i + 1]);
                                if (tempDate >= start && tempDate < end) {
                                    dateValue += transaction.transaction.amount_money.amount / 100;
                                }
                            }
                        })
                    }
                    teamGraphValue[monthNames[new Date(startDate).getMonth()]] = dateValue;
                }
            })

            teamData.data = teamGraphValue;
            return teamData;
        })

        const colors = Array.from({ length: programSalesData.length }).map(i => { return getRandomColor() })
        const checkStates = Array.from({ length: programSalesData.length }).map(i => { return true })

        setProgramSalesData(programSalesData);
        setProgramSalesWeekData(programSalesWeekData);
        setProgramSalesMonthData(programSalesMonthData);
        setProgramSalesColors(colors);
        setProgramSalesLabelChecks(checkStates);
    }


    useEffect(() => {
        if ((teams.length > 0) && Object.keys(transactions).length > 0 && Object.keys(users).length > 0) {
            if (!showState) {
                setShowState(true);
                initProgramSalesData();
            }
        }
    }, [teams, transactions, users, showState, initProgramSalesData])

    return (
        <div style={{ marginTop: 20 }}>
            <div className="row">
                <div className="col-lg-7">
                    <SalesLeadersChart
                        programSalesData={programSalesData}
                        programSalesWeekData={programSalesWeekData}
                        programSalesMonthData={programSalesMonthData}
                        programSalesColors={programSalesColors}
                        labelChecks={programSalesLabelChecks}
                    />
                </div>
                <div className="col-lg-5">
                    <div className="row">

                        {
                            teams.length > 0 && programSalesColors.length > 0 ?
                                teams.map((team, i) => {
                                    const teamValue = Object.values(team)[0];

                                    return <div key={i} className="col-6" style={{ marginBottom: 28 }}>
                                        <div
                                            style={{
                                                border: "1px solid #707070",
                                                borderRadius: 5,
                                                width: "100%",
                                                height: 58,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                paddingLeft: 5,
                                                paddingRight: 20,
                                            }}
                                        >
                                            <img
                                                alt="user_photo"
                                                // width="50px"
                                                height="50px"
                                                className="rounded-circle"
                                                src={teamValue.leaders[0].photo}
                                            />
                                            <span
                                                style={{ fontSize: 16, fontWeight: "500", color: "#111111" }}
                                            >{teamValue.leaders[0].name}</span>
                                            <MyCustomRadio checked={true} color={programSalesColors[i]} />
                                        </div>
                                    </div>
                                }) : null
                        }
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginTop: 33 }}>
                <div className="col-12">
                    <div
                        style={{
                            background: "white",
                            padding: "26px 30px",
                            width: "100%",
                        }}
                    >
                        <h3
                            style={{ fontSize: 14, fontWeight: "bold", color: "#333333", marginBottom: 90 }}
                        >Leader Totals</h3>
                        <div style={{ overflowX: "auto" }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        {
                                            tableHead.map((elem, i) => {
                                                return (<th key={i}>{elem}</th>);
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rows.map((row, index) => {
                                            if (index % 2 === 0) {
                                                return (<tr key={index}>
                                                    {Object.keys(row).map((key, j) => {
                                                        if (j === 0) {
                                                            return (
                                                                <td key={j} style={{ minWidth: 190 }}>
                                                                    <img
                                                                        alt="user_photo"
                                                                        src={row[key].image}
                                                                        className="rounded-circle"
                                                                        height="42px"
                                                                        style={{ border: "3px solid #707070", marginRight: 10 }}
                                                                    />
                                                                    <span>{row[key].name}</span>
                                                                </td>
                                                            );
                                                        }

                                                        return (<td key={j}>${row[key].toFixed(2)}</td>);
                                                    })}
                                                </tr>);
                                            } else {
                                                return (
                                                    <tr key={index}>
                                                        {
                                                            Object.keys(row).map((key, j) => {
                                                                if (j === 0) {
                                                                    return <td key={j}>
                                                                        <span style={{ marginLeft: 52 }}>Average</span>
                                                                    </td>
                                                                }

                                                                return (<td key={j}>${row[key].toFixed(2)}</td>);
                                                            })
                                                        }
                                                    </tr>
                                                );
                                            }
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}