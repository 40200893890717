import React, { useState, useEffect } from 'react';
import WhiteButton from "./common_component/WhiteButton";
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button"
import "./css/add_new_product.scss";
import MyDropDownField from "./inventory_component/MyDropDownField";
import MyColorPicker from "./common_component/MyColorPicker";
// import Fab from '@material-ui/core/Fab';
// import PublishIcon from '@material-ui/icons/Publish';
// import { makeStyles } from '@material-ui/core/styles';
// import { toAbsoluteUrl } from "../../../_metronic";
import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import app from "../../components/base";
import { useHistory } from "react-router-dom";
import CircularIntegration from "./common_component/CircularIntegration";
import { useParams } from 'react-router'

// const useStyles = makeStyles(theme => ({
//     fab: {
//         margin: theme.spacing(1),
//         background: "#36425C",
//         color: "white"
//     },
// }));

const CustomSwitch = withStyles({
    root: {
        width: "69px",
        height: "44px",
    },
    switchBase: {
        color: 'white',
        // backgroundColor: "#CDDC39",
        '&$checked': {
            color: '#CDDC39 !important',
        },
        '&$checked + $track': {
            backgroundColor: '#CDDC39 !important',
            opacity: "1",
        },
    },
    checked: {
        // backgroundColor: "black",
    },
    track: {
        backgroundColor: "#f1f2f8",
        height: "20px",
        marginTop: "-3px",
        opacity: "1",
        border: "1px solid #CDDC39"
    },
    thumb: {
        border: "4px solid white",
        width: "28px",
        height: "28px",
        marginTop: "-4px",
    }
})(Switch);

const parentOptions = [""];
// const colorOptions = ["#F76C2E", '#51A1E3', '#8DE08C', '#9F9D4A', '#fb4747', '#fe66f5',
//     '#3447fe', '#34fcfe', '#067a00'];

export default function EditCategory() {
    const history = useHistory();
    let { edit_key } = useParams();
    // const classes = useStyles();

    // const main_category = ls.get('edit_category');

    const [color, setColor] = useState({});
    const [parent, setParent] = useState("");
    const [name, setName] = useState("");
    const [products, setProducts] = useState(0);
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(false);
    // const [tags, setTags] = useState([]);
    const [showState, setShowState] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const tags = [];


    useEffect(() => {
        if (!showState) {
            setShowState(true);
            app.auth().onAuthStateChanged(user => {
                app.database().ref().child("admins/" + user.uid).once("value")
                    .then(adminSnap => {
                        const activeCategoryIds = adminSnap.val().activeCategoryIds ?
                            adminSnap.val().activeCategoryIds : [];
                        var categoryRef = app.database().ref().child("categories/" + edit_key);
                        categoryRef.once("value")
                            .then(snapshot => {
                                const category = snapshot.val();
                                setColor(category.color);
                                setParent(category.parent);
                                setName(category.category);
                                setProducts(category.products);
                                setDescription(category.description);
                                setActive(activeCategoryIds.indexOf(edit_key) > -1);
                                setShowDelete(category.adminId !== "master");
                            })
                    })
            })
        }
    }, [showState, edit_key])


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    function handleColorChangeComplete(value) {
        setColor(value.rgb);
    }

    function handleParentClick(value) {
        setParent(value)
    }

    function createData(category, products, description, color, parent, tags) {
        return { category, products, description, color, parent, tags };
    }

    const handleSubmit = () => {
        if (name === "") {
            alert("Please fill the Name textfield.")
        } else {

            if (!loading) {
                setSuccess(false);
                setLoading(true);
            }

            app.auth().onAuthStateChanged(function (user) {
                var categoryRef = app.database().ref().child("categories/" + edit_key);
                categoryRef.update(createData(name, products, description, color, parent, tags));

                app.database().ref().child("admins/" + user.uid).once('value')
                    .then(snapshot => {
                        const beforeCatIds = snapshot.val().activeCategoryIds ? snapshot.val().activeCategoryIds : [];
                        const value = beforeCatIds.indexOf(edit_key);

                        if (active) {
                            if (value === -1) {
                                app.database().ref().child("admins/" + user.uid).update({ activeCategoryIds: [...beforeCatIds, edit_key] })
                            }
                        } else {
                            if (value > -1) {
                                beforeCatIds.splice(value, 1);
                                app.database().ref().child("admins/" + user.uid).update({ activeCategoryIds: [...beforeCatIds] })
                            }
                        }

                        setSuccess(true);
                        setLoading(false);
                    })
            })

        }

    }

    const handleDelete = () => {
        var categoryRef = app.database().ref().child("categories/" + edit_key);
        categoryRef.remove()
            .then(function () {
                history.push("/all-categories");
            })
            .catch(error => {
                console.log("Remove failed:" + error.message);
            })
    }

    const handleSwitch = () => {
        setActive(!active);
    }

    return (
        <div className="row">
            <div className="col-xl-9">
                <div className="row inventory_submenu">
                    <h4
                        className="col-md-4"
                        style={{
                            fontSize: "30px",
                            fontWeight: "normal",
                            color: "#111111",
                            marginRight: "36px"
                        }}
                    >
                        Edit Category
                    </h4>
                    <div className="col-md-8" style={{ maxWidth: "375px" }}>
                        <WhiteButton handleClick={() => { history.push("/all-categories") }} caption="View all Categories" addIcon />
                    </div>
                </div>
                <div
                    className="content"
                    style={{
                        border: "1px solid #D4D6D9",
                        marginTop: "112px",
                        padding: "35px",
                        borderRadius: "5px"
                    }}
                >
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} sm="7" controlId="formGridName">
                                <Form.Label>Category Name</Form.Label>
                                <Form.Control type="text" onChange={e => { setName(e.target.value) }} value={name} placeholder="" />
                            </Form.Group>
                            <Form.Group as={Col} sm="5" controlId="formGridPrice">
                                <Form.Label>Products</Form.Label>
                                <Form.Control type="number" onChange={e => { setProducts(e.target.value) }} value={products} placeholder="" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="7" controlId="formGridDescription">
                                <Form.Label>Category Description</Form.Label>
                                <Form.Control type="text" onChange={e => { setDescription(e.target.value) }} value={description} placeholder="" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="5" controlId="formGridParent">
                                <Form.Label>Parent</Form.Label>
                                <MyDropDownField value={parent} options={parentOptions} handleClick={handleParentClick} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm="5" controlId="formGridColor">
                                <Form.Label>Select Color</Form.Label>
                                <MyColorPicker value={color} handleColorChangeComplete={handleColorChangeComplete} />
                            </Form.Group>

                            <Form.Group as={Col} sm="7" style={{ paddingTop: "45px" }}>
                                <div style={{ float: "right" }}>
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            color: "#36425C"
                                        }}
                                    >
                                        Active Category
                                    </span>
                                    <CustomSwitch checked={active} onClick={handleSwitch} />
                                </div>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm="6">
                                <CircularIntegration
                                    caption="Save"
                                    loading={loading}
                                    success={success}
                                    handleButtonClick={handleSubmit}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="6">
                                {
                                    showDelete ?
                                        <Button
                                            // type="button"
                                            onClick={handleDelete}
                                            style={{
                                                background: "#C52129",
                                                color: "white",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                width: "216px",
                                                height: "44px",
                                                borderRadius: "3px",
                                                float: "right"
                                            }}
                                        >
                                            Delete Category
                                    </Button> : null
                                }
                            </Form.Group>
                        </Form.Row>
                    </Form>

                </div>
            </div>
        </div >
    )
}
