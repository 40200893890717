import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import RoundTextField from "../../components/RoundTextField";
import * as auth from "../../store/ducks/auth.duck";
import { register } from "../../crud/auth.crud";
import ls from "local-storage";

function Registration(props) {
  let history = useHistory();
  const { intl } = props;

  return (
    <div className="kt-login__body">
      <div className="kt-login__form" style={{ padding: "60px 10%" }}>
        <div className="kt-login__title" style={{ marginBottom: "auto" }}>
          <h1 style={{ color: "#4F4F6F", fontWeight: "400", fontFamily: "Open Sans", fontSize: "48px" }}>
            Sign up today.
          </h1>
          <p style={{ color: "#4ea8f9", fontSize: "16px", fontFamily: "Open Sans", fontWeight: "normal" }}>
            Join today for only $99 a year, to manage your youth rush programs.
          </p>
        </div>

        <Formik
          initialValues={{
            email: "",
            firstname: "",
            lastname: "",
            password: "",
            acceptTerms: true,
            confirmPassword: ""
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }

            if (!values.firstname) {
              errors.firstname = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.lastname) {
              errors.lastname = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
            if (values.password.length < 6) {
              errors.password = "Password use minimum of 6 letters."
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm Password didn't match.";
            }

            if (!values.acceptTerms) {
              errors.acceptTerms = "Accept Terms";
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {

            ls.set("youthrush_signup_info", {
              firstname: values.firstname,
              lastname: values.lastname,
            });
            // history.push("/auth/bill");
            register(
              values.firstname,
              values.lastname,
              values.email,
              values.password
            )
              .then((info) => {
                history.push("/auth/step");
                // console.log("register");            
                // var accessToken = info.data.user.uid;
                // props.register(accessToken);
              })
              .catch((error) => {
                setSubmitting(false);
                setStatus(
                  // intl.formatMessage({
                  //   id: error.message
                  // })
                  error.message
                );
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                <div className="row">
                  <div className="form-group mb-0 col-sm-6">
                    <RoundTextField
                      type="text"
                      margin="normal"
                      headerLabel="Name"
                      name="firstname"
                      placeholder="First Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstname}
                      helperText={touched.firstname && errors.firstname}
                      error={Boolean(touched.firstname && errors.firstname)}
                    />
                  </div>

                  <div className="form-group mb-0 col-sm-6" style={{ marginTop: "27px" }}>
                    <RoundTextField
                      type="text"
                      margin="normal"
                      name="lastname"
                      placeholder="Last Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastname}
                      helperText={touched.lastname && errors.lastname}
                      error={Boolean(touched.lastname && errors.lastname)}
                    />
                  </div>
                </div>

                <div className="form-group mb-0">
                  <RoundTextField
                    type="text"
                    margin="normal"
                    name="email"
                    headerLabel="Email Address"
                    placeholder="Your email address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group mb-0">
                  <RoundTextField
                    type="password"
                    margin="normal"
                    headerLabel="Password"
                    name="password"
                    placeholder="Enter your password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="form-group mb-0">
                  <RoundTextField
                    type="password"
                    margin="normal"
                    lastStyle={{marginTop: "4px", marginBottom: "0"}}
                    headerLabel="Confirm Password"
                    name="confirmPassword"
                    placeholder="Retype your password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  />
                </div>

                <p style={{ paddingLeft: "15px", fontSize: "14px" }}>Your passwords are encrypted and secured</p>

                <div className="form-group mb-0">
                  <FormControlLabel
                    label={
                      <>
                        I have read & Accept Youth Rush App
                        {" "}
                        <Link
                          to="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </Link>
                        {" "}
                        and
                        {" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Use
                        </Link>
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="acceptTerms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values.acceptTerms}
                      />
                    }
                  />
                </div>


                <div className="kt-login__actions" style={{ marginTop: "25px", marginBottom: "25px" }}>
                  <button
                    disabled={isSubmitting || !values.acceptTerms}
                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                    style={{ width: "100%", borderRadius: "20px", height: "50px" }}
                  >
                    Next
                  </button>
                </div>
                <div className="form-group mb-0">
                  <FormControlLabel
                    label={
                      <>
                        By registering you confirm that you accept the
                        {" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms and Conditions
                        </Link>
                        {" "}
                        and
                        {" "}
                        <Link
                          to="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </Link>
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="acceptTerms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values.acceptTerms}
                        style={{ display: "none" }}
                      />
                    }
                  />
                </div>
              </form>
            )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    { ...auth.actions }
  )(Registration)
);
