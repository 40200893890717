import React, { useEffect } from 'react';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import SalesTotalStatistics from './SalesTotalStatistics';
import SalesTotalYesterday from './SalesTotalYesterday';
import OneTeamSale from './OneTeamSale';
import TotalProgramSalesChart from './TotalProgramSalesChart';
import MyDateRangePicker from './MyDateRangePicker';

export default function SalesTotal(props) {
    const [teamSalesInfos, setTeamSalesInfos] = React.useState([]);
    const [showState, setShowState] = React.useState(false);
    const [programSalesData, setProgramSalesData] = React.useState([]);
    const [programSalesColors, setProgramSalesColors] = React.useState([]);
    const [programSalesLabelChecks, setProgramSalesLabelChecks] = React.useState([]);

    const teams = props.teams;
    const transactions = props.transactions;
    const users = props.users;

    const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
    const day = beforeOneWeek.getDay();
    const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
    const lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
    const lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6));
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    let totalProgramAmount = 0;
    let programAmountOfLastWeek = 0;
    let yesterdayAmount = 0
    let totalStudents = 0;
    // let totalTransactions = 0;
    let todayTransactions = 0;

    if (Object.keys(transactions).length > 0) {
        Object.keys(transactions).forEach(key => {
            const teamTransactions = transactions[key];
            if (teamTransactions) {
                Object.keys(teamTransactions).forEach(key => {
                    const transaction = teamTransactions[key];
                    const transactionDate = new Date(transaction.transaction.createdAt);
                    const today = new Date();
                    totalProgramAmount += teamTransactions[key].transaction.amount_money.amount / 100;
                    // totalTransactions++;

                    if (today.toLocaleDateString() === transactionDate.toLocaleDateString()) {
                        todayTransactions++;
                    }

                    if (new Date(transactionDate.toLocaleDateString()) >= new Date(lastMonday.toLocaleDateString()) &&
                        new Date(transactionDate.toLocaleDateString()) <= new Date(lastSunday.toLocaleDateString())) {

                        programAmountOfLastWeek += transaction.transaction.amount_money.amount / 100;
                    }

                    if (yesterday.toLocaleDateString() === transactionDate.toLocaleDateString()) {
                        yesterdayAmount += transaction.transaction.amount_money.amount / 100;
                    }
                })
            }
        })
        teams.forEach(team => {
            const teamValue = Object.values(team)[0];
            totalStudents += teamValue.students ? teamValue.students.length : 0;
        })
    }

    const getTeamSalesInfo = (teamId, index, start = "1/1/1900", end = "12/31/3000") => {

        if (Object.keys(transactions).length > 0) {
            const teamTransaction = transactions[teamId];
            const userIds = teamTransaction ? Object.keys(teamTransaction).map(key => {
                return teamTransaction[key].userId;
            }) : [];
            const teamStudents = Object.values(teams[index])[0].students;
            const teamLeader = Object.values(teams[index])[0].leaders[0];
            if (teamStudents) {
                teamStudents.forEach(elem => {
                    userIds.push(elem.id);
                })
            }
            userIds.push(teamLeader.id);
            const uniqueUserIds = [...new Set(userIds)];

            let userSales = {};
            uniqueUserIds.forEach(userId => {
                userSales[userId] = [];
            })

            if (teamTransaction) {
                Object.keys(teamTransaction).forEach(key => {
                    const transaction = teamTransaction[key];
                    const transactionDate = new Date(new Date(transaction.transaction.createdAt).toLocaleDateString());
                    const startDate = new Date(start);
                    const endDate = new Date(end);
                    if (startDate <= transactionDate &&
                        transactionDate <= endDate) {
                        userSales[transaction.userId].push(transaction);
                    }
                })
            }

            let resUserSales = {};
            Object.keys(userSales).forEach(key => {
                const userSale = userSales[key];
                let user = {};
                let bookCount = 0;
                let amountMoney = 0;
                userSale.forEach(transaction => {
                    transaction.orders.forEach(order => {
                        bookCount += order.count;
                    })
                    amountMoney += transaction.transaction.amount_money.amount / 100;
                })
                user.bookCount = bookCount;
                user.amountMoney = amountMoney;
                user.name = users[key].firstName + " " + users[key].lastName;
                user.photo = users[key].image;
                resUserSales[key] = user;
            })

            let result = {};
            result.userSalesInfo = resUserSales;
            result.leaderId = teamLeader.id;
            return result;
        }
    }


    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const initProgramSalesData = () => {
        let programSalesData = [];
        programSalesData = teams.map(team => {
            let teamData = {};
            let teamGraphValue = {};
            const teamId = Object.keys(team)[0];
            const teamValue = Object.values(team)[0];
            teamData.name = teamValue.leaders[0].name;

            for (let d = new Date(), i = 0; i < 7; d.setDate(d.getDate() - 1), i++) {
                let dateValue = 0;
                const teamTransactions = transactions[teamId];
                if (teamTransactions) {
                    Object.keys(teamTransactions).forEach(key => {
                        const transaction = teamTransactions[key];
                        if (transaction.teamId === teamId) {
                            const tDate = new Date(transaction.transaction.createdAt);
                            if (tDate.getFullYear() === d.getUTCFullYear() &&
                                tDate.getMonth() === d.getUTCMonth() &&
                                tDate.getDate() === d.getUTCDate()) {
                                dateValue += transaction.transaction.amount_money.amount / 100;
                            }
                        }
                    })
                }
                teamGraphValue[(new Date(d.toUTCString())).toLocaleDateString()] = dateValue;
            }

            teamData.data = teamGraphValue;
            return teamData;
        })
        const colors = Array.from({ length: programSalesData.length }).map(i => { return getRandomColor() })
        const checkStates = Array.from({ length: programSalesData.length }).map(i => { return true })

        setProgramSalesData(programSalesData);
        setProgramSalesColors(colors);
        setProgramSalesLabelChecks(checkStates);
    }

    const handleDateRange = (start, end) => {
        const teamSalesInfos = teams.map((team, i) => {
            const teamId = Object.keys(team)[0];
            return getTeamSalesInfo(teamId, i, start, end);
        })
        setTeamSalesInfos(teamSalesInfos);
    }

    useEffect(() => {
        if ((teams.length > 0) && Object.keys(transactions).length > 0 && Object.keys(users).length > 0) {
            if (!showState) {
                setShowState(true);
                const teamSalesInfos = teams.map((team, i) => {
                    const teamId = Object.keys(team)[0];
                    return getTeamSalesInfo(teamId, i);
                })
                setTeamSalesInfos(teamSalesInfos);

                initProgramSalesData();
            }
        }
    }, [teams, transactions, users, showState, getTeamSalesInfo, initProgramSalesData])

    return (
        <div>
            <div className="row">
                <div className="col-lg-7">
                    <SalesTotalStatistics
                        totalProgramAmount={totalProgramAmount}
                        programAmountOfLastWeek={programAmountOfLastWeek}
                        totalStudents={totalStudents}
                        todayTransactions={todayTransactions}
                    />
                </div>
                <div className="col-lg-5">
                    <SalesTotalYesterday
                        yesterdayAmount={yesterdayAmount}
                        totalStudents={totalStudents}
                    />
                </div>
            </div>

            <div>
                <div style={{
                    height: 55,
                    background: "rgba(54, 68, 92, 0.83)",
                    paddingLeft: 33,
                    paddingRight: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <span
                        style={{
                            fontSize: "22px",
                            fontWeight: "500",
                            color: "white"
                        }}
                    >
                        Team Sales</span>
                    <div style={{ float: "right" }}>
                        <MyDateRangePicker handleRange={(start, end) => handleDateRange(start, end)} />
                    </div>
                </div>
                <div
                    style={{ padding: "45px 50px", background: "white" }}
                >
                    <div
                        style={{ overflowX: "auto", display: "flex" }}
                    >
                        {
                            teamSalesInfos.map((info, i) => {
                                return <OneTeamSale teamSalesInfo={info} key={i} />
                            })
                        }
                    </div>
                </div>
            </div >
            <div style={{ marginTop: 23 }}>
                <TotalProgramSalesChart
                    programSalesData={programSalesData}
                    programSalesColors={programSalesColors}
                    labelChecks={programSalesLabelChecks}
                />
            </div >
        </div>
    );
}