import React from 'react';
import Header from './update_components/Header';
import Footer from './update_components/Footer';
import Success from './update_components/Success';

export default function UpdatePassword() {
  return (
    <>
      <Header />
      <Success />
      <Footer />
    </>
  );
}
