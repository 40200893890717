import React, {useState} from "react";
import SettingsBill0 from "./SettingsBill0";
import SettingsBill1 from "./SettingsBill1";
import SettingsBill2 from "./SettingsBill2";
import SettingsBill3 from "./SettingsBill3";

export default function SettingsBill() {
    const [page, setPage] = useState(0);

    const goToBill1 = () => {
        setPage(1);
    }
    const goToBill2 = () => {
        setPage(2);
    }
    const goToBill0 = () => {
        setPage(0);
    }
    const goToBill3 = () => {
        setPage(3);
    }
    return (
        <>
        {[
            <SettingsBill0 handleCancelSubscription={goToBill2} handleChangePayment={goToBill1} />, 
            <SettingsBill1 handleBack={goToBill0} handleAddNew={goToBill3} />, 
            <SettingsBill2 handleCancelSubscription={goToBill0} />, 
            <SettingsBill3 handleBack={goToBill1} />
        ][page]}
        </>
    );
}