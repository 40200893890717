import React, { useState, useEffect } from 'react';
import DashboardStatistics from "./dashboard_component/DashboardStatistics";
import ProgramSalesChart from "./dashboard_component/ProgramSalesChart";
import CategoryChart from "./dashboard_component/CategoryChart";
import SalesMap from "./dashboard_component/SalesMap";
import LeaderSales from "./dashboard_component/LeaderSales";
import TopDistributedMaterial from "./dashboard_component/TopDistributedMaterial";
import TopSurveyInterests from "./dashboard_component/TopSurveyInterests";
import app from "../../components/base";
import { shallowEqual, useSelector } from "react-redux";
import MyDropDownMenu from "./common_component/MyDropDownMenu";

export default function Dashboard() {
  const { currentUser } = useSelector(({ auth }) => ({ currentUser: auth.user }), shallowEqual);
  const [admin, setAdmin] = useState({});
  const [showState, setShowState] = useState(false);
  const [programOptions, setProgramOptions] = useState([]);
  const [alivePrograms, setAlivePrograms] = useState([]);
  const [aliveIndex, setAliveIndex] = useState(0);

  const [programTotal, setProgramTotal] = useState(0);
  const [programGoal, setProgramGoal] = useState(0);
  // const [programTransactions, setProgramTransactions] = useState({});
  const [studentAverage, setStudentAverage] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [programSalesData, setProgramSalesData] = useState([]);
  const [programSalesColors, setProgramSalesColors] = useState([]);
  const [programSalesLabelCheckStates, setProgramSalesLabelCheckStates] = useState([]);
  const [catChartData, setCatChartData] = useState({});
  const [catChartColors, setCatChartColors] = useState({});
  const [salesMapData, setSalesMapData] = useState([]);
  const [salesMapColors, setSalesMapColors] = useState([]);
  const [salesMapChecks, setSalesMapChecks] = useState([]);
  const [leaderSalesData, setLeaderSalesData] = useState([]);
  const [topDistributedMaterialData, setTopDistributedMaterialData] = useState([]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const initializeData = (programId, admin) => {
    app.database().ref(`transactions`).orderByChild('programId').equalTo(programId).once('value')
      .then(snapshot => {
        let topDistributedMaterialData = [];
        let keys = [];
        const programTransactions = snapshot.val();
        // setProgramTransactions(programTransactions);
        let programTotal = 0;
        if (programTransactions) {
          Object.keys(programTransactions).forEach(key => {
            const transaction = programTransactions[key];
            programTotal += transaction.transaction.amount_money.amount / 100;

            const orders = transaction.orders;
            orders.forEach(order => {
              if (keys.indexOf(order.productItem.key) > -1) {
                const index = keys.indexOf(order.productItem.key);
                topDistributedMaterialData[index].count += Number(order.count);
              } else {
                const product = order.productItem;
                product.count = order.count;
                topDistributedMaterialData.push(product);
                keys.push(order.productItem.key);
              }
            })
          })
        }
        topDistributedMaterialData.sort((product1, product2) => { return product2.count - product1.count });
        setTopDistributedMaterialData(topDistributedMaterialData.slice(0, 10));
        setProgramTotal(programTotal);

        app.database().ref(`users`).orderByChild('programId').equalTo(programId).once('value')
          .then(userSnap => {
            const users = userSnap.val();
            let count = 0;
            if (users) {
              Object.keys(users).forEach(key => {
                count = users[key].role === "Student" ? count + 1 : count;
              })
            }
            setStudentCount(count);
            setProgramGoal(count * 3000);
            setStudentAverage(!isNaN(programTotal / count) ? programTotal / count : 0);
          })

        app.database().ref(`programs/${programId}/teams/today`).once('value')
          .then(teamSnap => {
            const teams = teamSnap.val();
            let programSalesData = [];
            let leaderSalesData = [];
            let salesMapData = [];
            let salesMapColors = [];
            let salesMapChecks = [];
            if (teams) {
              programSalesData = teams.map(team => {
                let teamData = {};
                let teamGraphValue = {};
                const teamId = Object.keys(team)[0];
                const teamValue = Object.values(team)[0];
                teamData.name = teamValue.leaders[0].name;

                for (let d = new Date(), i = 0; i < 7; d.setDate(d.getDate() - 1), i++) {
                  let dateValue = 0;
                  if (programTransactions) {
                    Object.keys(programTransactions).forEach(key => {
                      const transaction = programTransactions[key];
                      if (transaction.teamId === teamId) {
                        const tDate = new Date(transaction.transaction.createdAt);
                        if (tDate.getFullYear() === d.getUTCFullYear() &&
                          tDate.getMonth() === d.getUTCMonth() &&
                          tDate.getDate() === d.getUTCDate()) {
                          dateValue += transaction.transaction.amount_money.amount / 100;
                        }
                      }
                    })
                  }
                  teamGraphValue[(new Date(d.toUTCString())).toLocaleDateString()] = dateValue;
                }

                teamData.data = teamGraphValue;
                return teamData;
              })

              leaderSalesData = teams.map(team => {
                let row = {};
                let total = 0;
                const teamId = Object.keys(team)[0];
                const teamValue = Object.values(team)[0];
                row.photo = teamValue.leaders[0].photo;
                row.name = teamValue.leaders[0].name;
                if (programTransactions) {
                  Object.keys(programTransactions).forEach(key => {
                    const transaction = programTransactions[key];
                    if (transaction.teamId === teamId) {
                      total += transaction.transaction.amount_money.amount / 100;
                    }
                  })
                }
                row.total = total;
                row.average = teamValue.students ? teamValue.students.length === 0 ? total / teamValue.students.length : 0 : 0;
                return row;
              })

              //[Start] Temporarily Data of SalesMap 
              salesMapData = teams.map(team => {
                const teamId = Object.keys(team)[0];
                const teamValue = Object.values(team)[0];
                let oneData = {};
                oneData.name = teamValue.leaders[0].name;
                oneData.location = [];
                if (programTransactions) {
                  Object.keys(programTransactions).forEach(key => {
                    const transaction = programTransactions[key];
                    if (transaction.teamId === teamId) {
                      oneData.location.push(transaction.loc);
                    }
                  })
                }
                return oneData;
              })

              salesMapColors = teams.map(team => { return getRandomColor() })
              salesMapChecks = teams.map(team => { return true })
              //[End] Temporarily Data of SalesMap 
            }
            setSalesMapData(salesMapData);
            setSalesMapColors(salesMapColors);
            setSalesMapChecks(salesMapChecks);

            setLeaderSalesData(leaderSalesData);

            const colors = Array.from({ length: programSalesData.length }).map(i => { return getRandomColor() })
            const checkStates = Array.from({ length: programSalesData.length }).map(i => { return true })

            setProgramSalesData(programSalesData);
            setProgramSalesColors(colors);
            setProgramSalesLabelCheckStates(checkStates);
          })
      })

    const activeCategoryIds = admin.activeCategoryIds;
    let catChartDataWithId = {};
    if (activeCategoryIds) {
      activeCategoryIds.forEach(catId => {
        catChartDataWithId[catId] = 0;
      })
      app.database().ref('transactions').once('value')
        .then(transctSnap => {
          const transactions = transctSnap.val();
          if (transactions) {
            Object.values(transactions).forEach(transaction => {
              const orders = transaction.orders;
              orders.forEach(order => {
                if (activeCategoryIds.indexOf(order.productItem.categoryId) > -1 &&
                  transaction.programId === programId) {
                  catChartDataWithId[order.productItem.categoryId] += Number(order.count);
                }
              })
            })
          }
          app.database().ref('categories').once('value')
            .then(catSnap => {
              const categories = catSnap.val();
              let catChartData = {};
              let catChartColors = {};
              if (catChartDataWithId) {
                Object.keys(catChartDataWithId).forEach(catId => {
                  catChartData[categories[catId].category] = catChartDataWithId[catId];
                  catChartColors[categories[catId].category] = categories[catId].color;
                })
              }
              setCatChartData(catChartData);
              setCatChartColors(catChartColors);
            })
        })
    }
  }

  useEffect(() => {
    if (!showState) {
      setShowState(true);
      app.database().ref(`admins/${currentUser.user.uid}`).once('value')
        .then(snapshot => {
          const admin = snapshot.val();
          setAdmin(admin);

          app.database().ref(`programs`).orderByChild('adminId').equalTo(currentUser.user.uid).once('value')
            .then(snapshot => {
              const programs = snapshot.val();
              const programOptions = [];
              const alivePrograms = [];
              if (programs) {
                Object.keys(programs).forEach(key => {
                  const program = programs[key];
                  if (new Date(program.end_date) > new Date()) {
                    programOptions.push(program.name);
                    program.programId = key;
                    alivePrograms.push(program);
                  }
                })
                setProgramOptions(programOptions);
                setAlivePrograms(alivePrograms);
                initializeData(alivePrograms[0].programId, admin);
              }
            })
        })
    }
  }, [showState, currentUser.user.uid, initializeData])

  const handleProgramClick = index => {
    setAliveIndex(index);
    initializeData(alivePrograms[index].programId, admin);
  }


  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div className="row dashsboard_submenu">
            <div className="col-sm-6">
              <div className="row">
                <h4 style={{ fontSize: "22px", fontWeight: "normal", color: "#111111" }}>Dashboard</h4>
                <p style={{ fontSize: "16px", fontWeight: "normal", color: "#707070", marginTop: "7px", marginLeft: "18px" }}>Welcome, {admin ? admin.firstname : null}.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div style={{ paddingTop: "7px", textAlign: "right" }}>
                <MyDropDownMenu options={programOptions} handleProgramClick={handleProgramClick} />
              </div>
            </div>
          </div>
          <DashboardStatistics
            programTotal={programTotal}
            programGoal={programGoal}
            studentCount={studentCount}
            studentAverage={studentAverage}
          />
          <div className="row dashboard_chart">
            <div className="col-xl-7 program_sales_chart" style={{ marginTop: "20px" }}>
              <ProgramSalesChart
                programName={programOptions[aliveIndex]}
                programSalesData={programSalesData}
                programSalesColors={programSalesColors}
                labelCheckStates={programSalesLabelCheckStates}
              />
            </div>
            <div className="col-xl-5 category_chart" style={{ marginTop: "20px" }}>
              <CategoryChart
                chartData={catChartData}
                chartColors={catChartColors}
              />
            </div>
          </div>
          <div>
            <SalesMap
              data={salesMapData}
              colors={salesMapColors}
              checks={salesMapChecks}
            />
          </div>
        </div>
        <div className="col-md-3" style={{ paddingTop: "63px" }}>
          <div>
            <LeaderSales data={leaderSalesData} />
          </div>
          <div style={{ marginTop: "20px" }}>
            <TopDistributedMaterial data={topDistributedMaterialData} />
          </div>
          {/* <div style={{ marginTop: "20px" }}>
            <TopSurveyInterests />
          </div> */}
        </div>
      </div>
    </>
  )
}
