import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import WhiteButton from "./common_component/WhiteButton";
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button"
import MyDropDownField from "./inventory_component/MyDropDownField";
// import Fab from '@material-ui/core/Fab';
// import PublishIcon from '@material-ui/icons/Publish';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CircularIntegration from "./common_component/CircularIntegration";
// import { toAbsoluteUrl } from "../../../_metronic";
import app from "../../components/base";
import "./css/add_new_product.scss";

// const useStyles = makeStyles(theme => ({
//     fab: {
//         margin: theme.spacing(1),
//         background: "#36425C",
//         color: "white"
//     },
// }));

const CustomSwitch = withStyles({
    root: {
        width: "69px",
        height: "44px",
    },
    switchBase: {
        color: 'white',
        // backgroundColor: "#CDDC39",
        '&$checked': {
            color: '#CDDC39 !important',
        },
        '&$checked + $track': {
            backgroundColor: '#CDDC39 !important',
            opacity: "1",
        },
    },
    checked: {
        // backgroundColor: "black",
    },
    track: {
        backgroundColor: "#f1f2f8",
        height: "20px",
        marginTop: "-3px",
        opacity: "1",
        border: "1px solid #CDDC39"
    },
    thumb: {
        border: "4px solid white",
        width: "28px",
        height: "28px",
        marginTop: "-4px",
    }
})(Switch);

export default function EditInterest() {
    const { programId, interestId } = useParams();
    const history = useHistory();
    // const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [address, setAddress] = useState('');

    const [showState, setShowState] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (!showState) {
            setShowState(true);
            app.database().ref(`interests/${interestId}`).once('value')
                .then(snapshot => {
                    const interest = snapshot.val();
                    setFirstName(interest.customer.firstName);
                    setLastName(interest.customer.lastName);
                    setCity(interest.customer.city);
                    setState(interest.customer.state);
                    setZip(interest.customer.zip);
                    setPhone(interest.customer.phone);
                    setEmail(interest.customer.email);
                    setAddress(interest.customer.address);
                })
        }
    }, [showState, interestId])

    const handleFirstName = event => {
        setFirstName(event.target.value);
    }

    const handleLastName = event => {
        setLastName(event.target.value);
    }

    const handleCity = event => {
        setCity(event.target.value);
    }

    const handleState = event => {
        setState(event.target.value);
    }

    const handleZip = event => {
        setZip(event.target.value);
    }

    const handlePhone = event => {
        setPhone(event.target.value);
    }

    const handleEmail = event => {
        setEmail(event.target.value);
    }

    const handleNotes = event => {
        setNotes(event.target.value);
    }

    const handleDelete = () => {
        var interestRef = app.database().ref().child("interests/" + interestId);
        interestRef.remove()
            .then(function () {
                history.push(`/interest/${programId}`);
            })
            .catch(error => {
                console.log("Remove failed:" + error.message);
            })
    }


    function createData(firstName, lastName, city, state, zip, email, phone, notes) {
        return { firstName, lastName, city, state, zip, email, phone, notes };
    }

    const handleSubmit = () => {

        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }

        // if (image != null) {
        //     const uploadTask = app.storage().ref(`book_covers/${image.name}`).put(image);
        //     uploadTask.on('state_changed', (snapshot) => {

        //     }, (error) => {

        //     }, () => {
        //         app.storage().ref('book_covers').child(image.name).getDownloadURL().then(url => {
        //             var productRef = app.database().ref().child("products/" + edit_key);
        //             productRef.update(createData(url, title, stock, price, tags, active, categoryIds[categoryOption.indexOf(category)]));

        //             app.database().ref().child("admins/" + user.uid).once('value')
        //                 .then(snapshot => {
        //                     const beforeProductIds = snapshot.val().activeProductIds ? snapshot.val().activeProductIds : [];
        //                     const value = beforeProductIds.indexOf(edit_key);

        //                     if (active) {
        //                         if (value == -1) {
        //                             app.database().ref().child("admins/" + user.uid).update({ activeProductIds: [...beforeProductIds, edit_key] })
        //                         }
        //                     } else {
        //                         if (value > -1) {
        //                             beforeProductIds.splice(value, 1);
        //                             app.database().ref().child("admins/" + user.uid).update({ activeProductIds: [beforeProductIds] })
        //                         }
        //                     }

        //                     setSuccess(true);
        //                     setLoading(false);
        //                 })
        //         });
        //     })
        // } else {
        var updateRef = app.database().ref().child(`interests/${interestId}/customer`);
        updateRef.update(createData(firstName, lastName, city, state, zip, email, phone, notes));

        setSuccess(true);
        setLoading(false);
        // }
    }


    return (
        <>
            <div className="row">
                <div className="col-xl-9">
                    <div className="row inventory_submenu">
                        <h4
                            className="col-md-4"
                            style={{
                                fontSize: "30px",
                                fontWeight: "normal",
                                color: "#111111",
                                marginRight: "36px"
                            }}
                        >
                            Edit Interest
                    </h4>
                        <div className="col-md-8" style={{ maxWidth: "375px" }}>
                            <WhiteButton handleClick={() => { history.push(`/interest/${programId}`) }} caption="View all Interests" addIcon />
                        </div>
                    </div>
                    <div
                        className="content"
                        style={{
                            border: "1px solid #D4D6D9",
                            marginTop: "112px",
                            padding: "35px",
                            borderRadius: "5px"
                        }}
                    >
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} sm="4" controlId="formGridFirstname">
                                    <Form.Label>Interest Name</Form.Label>
                                    <Form.Control type="text" placeholder="" value={firstName} onChange={handleFirstName} />
                                </Form.Group>

                                <Form.Group as={Col} sm="4" controlId="formGridLastname" style={{ display: "flex", alignItems: "flex-end" }}>
                                    <Form.Label></Form.Label>
                                    <Form.Control type="text" placeholder="" value={lastName} onChange={handleLastName} />
                                </Form.Group>
                                <Form.Group as={Col} sm="4" controlId="formGridInterests">
                                    <Form.Label>Interests</Form.Label>
                                    <MyDropDownField />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="4" controlId="formGridAddress">
                                    <Form.Label>Address</Form.Label>
                                    <MyDropDownField />
                                </Form.Group>
                                <Form.Group as={Col} sm="6" controlId="formGridGender" style={{ display: "flex", alignItems: "flex-end", paddingLeft: 30 }}>
                                    <RadioGroup defaultValue="female" aria-label="gender" name="customized-radisos" style={{ flexDirection: "row" }}>
                                        <FormControlLabel value="female" control={<Radio style={{ color: "#36435C" }} />} label="Female" style={{ marginRight: 50 }} />
                                        <FormControlLabel value="male" control={<Radio style={{ color: "#36435C" }} />} label="Male" />
                                    </RadioGroup>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm="4" controlId="formGridCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" placeholder="" value={city} onChange={handleCity} />
                                </Form.Group>
                                <Form.Group as={Col} sm="4" controlId="formGridState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control type="text" placeholder="" value={state} onChange={handleState} />
                                </Form.Group>
                                <Form.Group as={Col} sm="4" controlId="formGridZip">
                                    <Form.Label>Zip</Form.Label>
                                    <Form.Control type="text" placeholder="" value={zip} onChange={handleZip} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm="5" controlId="formGridPhoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder="" value={phone} onChange={handlePhone} />
                                </Form.Group>
                                <Form.Group as={Col} sm="5" controlId="formGridEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" placeholder="" value={email} onChange={handleEmail} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm="10" controlId="formGridNotes">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control as="textarea" rows="5" placeholder="" style={{ height: "auto" }} value={notes} onChange={handleNotes} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm="5" controlId="formGridStudent">
                                    <Form.Label>Student that made contact</Form.Label>
                                    <Form.Control type="text" placeholder="" />
                                </Form.Group>
                                <Form.Group as={Col} sm="5" controlId="formGridChurch">
                                    <Form.Label>Church</Form.Label>
                                    <Form.Control type="text" placeholder="" />
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            color: "#36425C",
                                            marginRight: "55px"
                                        }}
                                    >
                                        Handed over to church?
                                    </span>
                                    <CustomSwitch />
                                </Form.Group>
                            </Form.Row>
                            {/* <Form.Row>
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>Add Profile Image</Form.Label>
                                    <div style={{ width: "100%", border: "1px solid #B8B9BC", borderRadius: "3px", textAlign: "center" }}>
                                        <Fab aria-label="Add" className={classes.fab}>
                                            <PublishIcon />
                                        </Fab>
                                    </div>
                                </Form.Group>
                            </Form.Row> */}

                            <CircularIntegration
                                caption="Save"
                                loading={loading}
                                success={success}
                                handleButtonClick={handleSubmit}
                            />
                        </Form>

                    </div>
                </div>
                <div className="col-xl-3">
                    <div
                        className="right_content"
                        style={{
                            border: "1px solid #D4D6D9",
                            padding: "20px 40px",
                            borderRadius: "5px",
                            marginTop: "20px",
                            paddingBottom: "60px"
                        }}
                    >
                        <div className="row">
                            {/* <div className="col-sm-6 col-md-6 col-xl-12">
                                <img
                                    className="img-fluid rounded-circle"
                                    style={{ width: "237px" }}
                                    alt="user"
                                    src={toAbsoluteUrl("/media/users/user1.jpg")}
                                />
                            </div> */}
                            <div className="col-sm-6 col-md-6 col-xl-12">
                                <h1
                                    style={{
                                        fontSize: "30px",
                                        fontWeight: "bold",
                                        color: "#707070",
                                        marginTop: "20px",
                                        marginBottom: "20px"
                                    }}
                                >{firstName + " " + lastName}</h1>
                                <p
                                    style={{
                                        fontSize: "28px",
                                        fontWeight: "normal",
                                        color: "#707070",
                                        marginTop: "20px"
                                    }}
                                >
                                    {address}
                                </p>
                                <span
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "normal",
                                        color: "#36435C",
                                        marginTop: "20px"
                                    }}
                                >{`${city}, ${state} ${zip}`}</span><br />

                                {/* <span
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "500",
                                        color: "white",
                                        background: "#50A2E3",
                                        padding: "5px 30px",
                                        borderRadius: "3px",
                                    }}
                                >Health</span> */}
                                <p
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "normal",
                                        color: "#707070",
                                        marginTop: "20px"
                                    }}
                                >
                                    {email}<br />
                                    {phone}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div style={{ float: "right", marginTop: "213px" }}>
                <br />
                <Button
                    // type="button"
                    onClick={handleDelete}
                    style={{
                        background: "#C52129",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "bold",
                        width: "216px",
                        height: "44px",
                        borderRadius: "3px",
                        float: "right"
                    }}
                >
                    Delete Interest
                </Button>
            </div>
        </>
    )
}
