import React from "react";
import Login from "./Login";
import MyStepNavbar from "../../components/MyStepNavbar";
import { Helmet } from "react-helmet";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";


export default function AuthPage() {
  return (
    <>
      {/* https://github.com/nfl/react-helmet */}
      <Helmet>
        {/* <link
          type="text/css"
          rel="stylesheet"
          href={toAbsoluteUrl(
            "/assets/css/demo1/style.bundle.css"
          )}
        />
        <link
          type="text/css"
          rel="stylesheet"
          href={toAbsoluteUrl(
            "/assets/css/demo1/pages/login/login-1.css"
          )}
        /> */}
      </Helmet>

      <div style={{ backgroundColor: "white" }}>
        <MyStepNavbar />
        <div
          id="kt_login"
          className="container-fluid"
          style={{
            maxWidth: "1400px",
            margin: "0 auto",
          }}
        >
          <div className="row">
            <div className="col-md-6">

              <div
                className=""
                style={{
                  flex: "none"
                }}
              >
                <img
                  alt="bg-auth"
                  className="img-fluid"
                  src={toAbsoluteUrl("/media/bg/bg-auth.png")}
                />
              </div>
              {/* <div className="kt-login__head" style={{ textAlign: "center", marginTop: "-30px" }}>
                <span className="kt-login__signup-label" style={{fontSize: "18px"}}>
                  Already User?
                </span>
                &nbsp;&nbsp;
                <Link to="/auth/login" className="kt-link kt-login__signup-link" style={{fontSize: "18px", fontWeight: "500", fontFamily: "Open Sans"}}>
                  Sign In Now!
                </Link>
              </div> */}
            </div>

            <div className="col-md-6">
              <Login />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
