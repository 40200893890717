import React from "react";
import PropTypes from "prop-types";
// import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from "@material-ui/core/IconButton";
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import MenuIcon from "@material-ui/icons/Menu";
// import { toAbsoluteUrl } from '../../../../_metronic';
import { useHistory } from "react-router-dom";
// import { GET_CATEGORY } from "../../../components/constants";
// import { GET_PRODUCT } from "../../../components/constants";
// import ls from 'local-storage';

// function createData(name, address, city, state, zip, email, phone, interest, date) {
//     return { name, address, city, state, zip, email, phone, interest, date };
// }

// const rows = [
//     createData(toAbsoluteUrl('/media/users/user1.jpg'), 'Perla Cuevas', '2293 Westgate Ave', 'Madera', 'California', '93637', 'willbarrow@gmail.com', '169-9878-7843', 'Health, Bible Studies', '01/23/20'),
//     createData(toAbsoluteUrl('/media/users/user2.jpg'), 'Elizabeth Rivas', '2293 Westgate Ave', 'Madera', 'California', '93637', 'pelicansteve@gmail.com', '982-4786-2877', 'Health, Bible Studies', '01/23/20'),
//     createData(toAbsoluteUrl('/media/users/user3.jpg'), 'Jimmy Nguyen', '2293 Westgate Ave', 'Madera', 'California', '93637', 'willbarrow@gmail.com', '169-9878-7843', 'Health, Bible Studies', '01/23/20'),
//     createData(toAbsoluteUrl('/media/users/user4.jpg'), 'Philip Brutche', '2293 Westgate Ave', 'Madera', 'California', '93637', 'pelicansteve@gmail.com', '982-4786-2877', 'Health, Bible Studies', '01/23/20'),
//     createData(toAbsoluteUrl('/media/users/user5.jpg'), 'Kenneth Rivera', '2293 Westgate Ave', 'Madera', 'California', '93637', 'willbarrow@gmail.com', '169-9878-7843', 'Health, Bible Studies', '01/23/20'),
// ];

const headCells = [
  // { id: 'photo', label: '', minWidth: 50, numeric: false },
  {
    id: "name",
    label: "Customer Name",
    minWidth: 80,
    align: "center",
    numeric: false
  },
  {
    id: "address",
    label: "Address",
    minWidth: 125,
    align: "center",
    numeric: false
  },
  { id: "city", label: "City", minWidth: 50, align: "center", numeric: false },
  {
    id: "state",
    label: "State",
    minWidth: 65,
    align: "center",
    numeric: false
  },
  { id: "zip", label: "Zip", minWidth: 40, align: "center", numeric: true },
  {
    id: "email",
    label: "Email & Phone",
    minWidth: 155,
    align: "center",
    numeric: false
  },
  {
    id: "interest",
    label: "Interest",
    minWidth: 135,
    align: "center",
    numeric: false
  },
  { id: "date", label: "Date", minWidth: 60, align: "center", numeric: false },
  { id: "edit", label: "Edit", minWidth: 30, align: "center", numeric: false }
];

function desc(a, b, orderBy) {
  let isNumeric = false;
  headCells.forEach(elem => {
    if (elem.id === orderBy) {
      isNumeric = elem.numeric;
    }
  });
  const compA = isNumeric ? Number(a[orderBy]) : a[orderBy];
  const compB = isNumeric ? Number(b[orderBy]) : b[orderBy];
  if (compB < compA) {
    return -1;
  }
  if (compB > compA) {
    return 1;
  }
  return 0;
}

function stableSort(object, cmp) {
  let array = Object.keys(object);
  const stabilizedThis = array.map((key, index) => [object[key], index, key]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => [el[0], el[2]]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

// const headCells = [
//   { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
//   { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
//   { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
//   { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
//   { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
// ];

const columns = headCells;

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function InterestTable(props) {
  const history = useHistory();
  const myRows = props.rows;
  const programId = props.programId;
  const interestTypes = props.interestTypes;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getInterestTypes = keys => {
    let types = [];
    if (keys) {
      types = keys.map(key => {
        return interestTypes[key] ? interestTypes[key].interestType : null;
      });
    }
    return types.toString();
  };

  const rows = {};
  if (myRows) {
    Object.keys(myRows).forEach(key => {
      const row = {};
      row.name =
        myRows[key].customer.firstName + " " + myRows[key].customer.lastName;
      row.address = myRows[key].customer.address;
      row.city = myRows[key].customer.city;
      row.state = myRows[key].customer.state;
      row.zip = myRows[key].customer.zip;
      row.email = myRows[key].customer.email;
      row.phone = myRows[key].customer.phone;
      row.interest = getInterestTypes(myRows[key].interestTypeKey);
      row.date = new Date(myRows[key].date).toLocaleDateString();
      rows[key] = row;
    });
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = event => {
  //     if (event.target.checked) {
  //         const newSelecteds = rows.map(n => n.name);
  //         setSelected(newSelecteds);
  //         return;
  //     }
  //     setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //     const selectedIndex = selected.indexOf(name);
  //     let newSelected = [];

  //     if (selectedIndex === -1) {
  //         newSelected = newSelected.concat(selected, name);
  //     } else if (selectedIndex === 0) {
  //         newSelected = newSelected.concat(selected.slice(1));
  //     } else if (selectedIndex === selected.length - 1) {
  //         newSelected = newSelected.concat(selected.slice(0, -1));
  //     } else if (selectedIndex > 0) {
  //         newSelected = newSelected.concat(
  //             selected.slice(0, selectedIndex),
  //             selected.slice(selectedIndex + 1),
  //         );
  //     }

  //     setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = key => {
    history.push(`/edit-interest/${programId}/${key}`);
  };

  // const handleChangeDense = event => {
  //     setDense(event.target.checked);
  // };

  // const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rows
    ? rowsPerPage -
      Math.min(rowsPerPage, Object.keys(rows).length - page * rowsPerPage)
    : 0;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            // size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows ? Object.keys(rows).length : 0}
            />
            <TableBody>
              {rows
                ? stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((oneRow, index) => {
                      const row = oneRow[0];
                      const row_key = oneRow[1];
                      // const isItemSelected = isSelected(row.name);
                      // const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={event => handleClick(event, row.image)}
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row_key}
                          // selected={isItemSelected}
                        >
                          <TableCell
                            key={columns[0].id}
                            align={columns[0].align}
                          >
                            <span
                              style={{
                                color: "#555555",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {row[columns[0].id]}
                            </span>
                          </TableCell>
                          <TableCell
                            key={columns[1].id}
                            align={columns[1].align}
                          >
                            <span
                              style={{
                                color: "#555555",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {row[columns[1].id]}
                            </span>
                          </TableCell>
                          <TableCell
                            key={columns[2].id}
                            align={columns[2].align}
                          >
                            <span
                              style={{
                                color: "#438AFE",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {row[columns[2].id]}
                            </span>
                          </TableCell>
                          <TableCell
                            key={columns[3].id}
                            align={columns[3].align}
                          >
                            <span
                              style={{
                                color: "#438AFE",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {row[columns[3].id]}
                            </span>
                          </TableCell>
                          <TableCell
                            key={columns[4].id}
                            align={columns[4].align}
                          >
                            <span
                              style={{
                                color: "#438AFE",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {row[columns[4].id]}
                            </span>
                          </TableCell>
                          <TableCell
                            key={columns[5].id}
                            align={columns[5].align}
                          >
                            <span
                              style={{
                                color: "#555555",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {row.email}
                              <br />
                              {row.phone}
                            </span>
                          </TableCell>
                          <TableCell
                            key={columns[6].id}
                            align={columns[6].align}
                          >
                            <span
                              style={{
                                color: "#438AFE",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {row[columns[6].id]}
                            </span>
                          </TableCell>
                          {/* <TableCell key={columns[2].id} align={columns[2].align}>
                                                    <button
                                                        style={{
                                                            background: typeof row[columns[2].id] === "string" ?
                                                                row[columns[2].id] :
                                                                `rgba(${row[columns[2].id].r}, ${row[columns[2].id].g}, ${row[columns[2].id].b}, ${row[columns[2].id].a})`,
                                                            width: "91px",
                                                            height: "23px",
                                                            border: "none"
                                                        }}>
                                                    </button>
                                                </TableCell> */}
                          <TableCell
                            key={columns[7].id}
                            align={columns[7].align}
                          >
                            <span
                              style={{
                                color: "#555555",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {row[columns[7].id]}
                            </span>
                          </TableCell>
                          <TableCell key={"edit"} align={columns[8].align}>
                            <IconButton
                              onClick={() => handleEditClick(row_key)}
                            >
                              <MenuIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : null}
              {emptyRows > 0 && (
                // <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={rows ? Object.keys(rows).length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
