import React, { useRef, useState, useEffect } from 'react';
// import SalesButtonBar from "./SalesButtonBar";
import LineChartLabel from "./LineChartLabel";
import SimpleMap from "./SimpleMap";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function SalesMap(props) {
    const data = props.data;
    const colors = props.colors;
    const checks = props.checks;
    const mapContainer = useRef(null);
    const [mapContainerWidth, setMapContainerWidth] = useState(0);

    const [mapData, setMapData] = React.useState([]);
    const [labelColors, setLabelColors] = React.useState([]);
    const [labelChecks, setLabelChecks] = React.useState([]);
    const [showFlag, setShowFlag] = React.useState(false);

    useEffect(() => {
        if (data.length > 0 && colors.length > 0 && checks.length > 0) {
            if (!showFlag) {
                setShowFlag(true);
                setMapData(data);
                setLabelColors(colors);
                setLabelChecks(checks);
            }
        }

        const updateMapContainerWidth = () => {
            setMapContainerWidth(mapContainer.current.offsetWidth);
        }
        if (mapContainer) {
            setMapContainerWidth(mapContainer.current.offsetWidth);
        }
        window.addEventListener("resize", updateMapContainerWidth);
        return () => window.removeEventListener("resize", updateMapContainerWidth);
    }, [mapContainer, data, colors, checks, showFlag])


    const handleClick = i => {
        let tempChecks = labelChecks.map((check, index) => {
            return index === i ? !check : check;
        });
        setLabelChecks(tempChecks);

        const check = labelChecks[i];

        let temp = mapData.map(oneData => { return oneData });
        let tempColors = labelColors.map(color => { return color });
        if (check) {
            if (i < temp.length) {
                temp.splice(i, 1);
                tempColors.splice(i, 1);
            } else {
                temp.pop();
                tempColors.pop();
            }
        } else {
            if (i < temp.length) {
                temp.splice(i, 0, data[i]);
                tempColors.splice(i, 0, colors[i]);
            } else {
                temp.push(data[i]);
                tempColors.push(colors[i]);
            }
        }
        setMapData(temp);
        setLabelColors(tempColors);
    }

    return (
        <div style={{ background: "white", marginTop: "20px", padding: "47px 55px" }}>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
                <SalesButtonBar />
            </div> */}

            <div className="row" style={{ marginTop: "64px" }}>
                <div className="col-md-6 col-xl-8">
                    <h4 style={{ fontSize: "18px", fontWeight: "bold", color: "#333333" }}>Sales Map</h4>
                </div>
                <div className="col-md-6 col-xl-4">
                    <div className="row">
                        {
                            data.length > 0 ?
                                data.map((oneData, i) => {
                                    return <div key={i} className="col-6 col-lg-4">
                                        <LineChartLabel
                                            color={colors[i]}
                                            fontColor={"#666666"}
                                            title={oneData.name}
                                            checked={labelChecks[i]}
                                            fontSize="12px"
                                            fontWeight="normal"
                                            handleClick={() => handleClick(i)}
                                        />
                                    </div>
                                }) : null
                        }

                    </div>
                </div>
            </div>
            <div ref={mapContainer} style={{ marginTop: "32px", display: "flex", alignItems: "flex-end" }} >
                <List component="nav" aria-label="secondary mailbox folders">
                    <ListItem button>
                        <ListItemText primary="$50,000" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="$40,000" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="$30,000" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="$20,000" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="$10,000" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="0" />
                    </ListItem>
                </List>
                <SimpleMap data={mapData} containerWidth={mapContainerWidth} />
            </div>
        </div>
    )
}
