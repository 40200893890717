import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { SketchPicker } from 'react-color';
import { Form } from "react-bootstrap";


export default function MyDropDownField(props) {
    // const options = props.options;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <div xs={12}
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                {/* <Form.Control type="text" placeholder="" ref={anchorRef} value={entryCount} readOnly /> */}
                <Form.Control type="text" placeholder="" style={{background: `rgba(${props.value.r}, ${props.value.g}, ${props.value.b}, ${props.value.a})`}} ref={anchorRef} readOnly />
                <button
                    // size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    // aria-expanded={open ? 'true' : undefined}
                    // aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    style={{
                        background: "white",
                        border: "1px solid #E2E2E2",
                        padding: "4px 0",
                        borderLeft: "none",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        height: "52px",
                        width: "52px"
                    }}
                    type="button"
                >
                    <MenuIcon style={{ width: "31px" }} />
                </button>
                <Popper style={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} placement="top" transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <SketchPicker
                                        color={props.value}
                                        onChangeComplete={props.handleColorChangeComplete}
                                    />
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}