import React from 'react';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
// import Fab from '@material-ui/core/Fab';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//     fab: {
//         margin: theme.spacing(1),
//         background: "#36425C",
//         color: "white", 
//         width: 36, 
//         height: 36, 
//         marginLeft: 30
//     },
// }));

export default function WhiteButton(props) {
    // const classes = useStyles();

    return (
        <Button
            style={{
                width: "100%",
                height: "74px",
                background: "white",
                color: "#111111",
                fontSize: "20px",
                fontWeight: "bold",
                textTransform: "none", 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center"
            }}
            onClick={props.handleClick}
            variant="contained"
        >
            {props.caption}
            {props.addIcon ?
                // <Fab aria-label="Add" className={classes.fab} onClick={props.handleClick}>
                    <AddIcon
                        style={{
                            background: "#36425C", 
                            width: 36, 
                            height: 36, 
                            color: "white", 
                            borderRadius: "50%", 
                            marginLeft: 25
                        }}
                     />
                // </Fab>
                : null}
        </Button>
    )
}
