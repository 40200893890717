import React, { useState, useEffect } from "react";
import MyNavbar from "../../components/MyNavbar";
import { Container, Card, Button, Table, InputGroup, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";
import PaymentForm from '../../components/PaymentForm';
import "../css/payment.css";
import app from "../../components/base";
import * as auth from "../../store/ducks/auth.duck";
import { SIGNUP_PAYMENT, SANDBOX_APPID } from "../../components/constants";

function AuthPageBill(props) {

  // const signup_info = ls.get("signup-info");

  const [confname, setConfName] = useState("");
  const [confabbr, setConfAbbr] = useState("");

  app.auth().onAuthStateChanged(function (user) {
    if (user != null) {
      app.database().ref('/users/' + user.uid).once('value').then(function (snapshot) {
        setConfName(snapshot.val().confname);
        setConfAbbr(snapshot.val().confabbr);
      });
    } else {
      this.name = "Unknown";
    }
  });

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let sqPaymentScript = document.createElement('script');
    sqPaymentScript.src = "https://js.squareupsandbox.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript"
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      setLoaded(true);
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }, []);

  const handleCupon = () => {
    var usersRef = app.database().ref().child("users");
    usersRef.child(app.auth().currentUser.uid).update({ "subflag": true });
    props.login(app.auth().currentUser.uid, true);
  }

  const handleSquare = () => {
    var usersRef = app.database().ref().child("users");
    usersRef.child(app.auth().currentUser.uid).update({ "subflag": true });
    props.login(app.auth().currentUser.uid, true);
  }

  const handleForceSignOut = () => {
    props.logout();
  }

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          style={{ background: "white" }}
        >
          <MyNavbar handleForceSignOut={handleForceSignOut} />
          <Container>
            <div
              className="row"
            >
              <div className="col-md-12" style={{ textAlign: "center" }}>
                <h1
                  style={{ fontFamily: "Open Sans", fontWeight: "400", marginTop: "40px", marginBottom: "30px", color: "#3493ee" }}
                >
                  Set up billing
              </h1>
              </div>
              <div className="col-md-6">
                <Card style={{ borderRadius: "20px" }}>
                  <Card.Body>
                    <Card.Title>{confname}</Card.Title>
                    <Card.Text>
                      {confabbr}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card bg="light" style={{ marginTop: "30px", textAlign: "center", borderRadius: "20px" }}>
                  <Card.Body>
                    <Card.Title style={{ color: "#3493ee", fontFamily: "Open Sans" }}>Order Overview</Card.Title>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>Yearly Subscription</td>
                          <td>$49</td>
                        </tr>
                        {/* <tr>
                          <td>Programs(1) $49 each</td>
                          <td>$99</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Subtotal</td>
                          <td>$198</td>
                        </tr> */}
                        <tr>
                          <td>Sales Tax(7.975%)</td>
                          <td>$3.91</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>$52.91</td>
                        </tr>
                      </tbody>
                    </Table>
                    <InputGroup className="mb-3" style={{ borderRadius: "20px" }}>
                      <FormControl
                        placeholder="Coupon Code"
                        aria-label="Coupon Code"
                        aria-describedby="basic-addon2"
                        style={{
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                      />
                      <InputGroup.Append>
                        <Button variant="outline-primary"
                          style={{
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                          }}
                          onClick={handleCupon}
                        >
                          Apply
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Card.Body>
                </Card>


                {/* <Form style={{ marginTop: "40px" }}>
                  <Form.Group controlId="cardname">
                    <Form.Label>Name of Card</Form.Label>
                    <Form.Control type="text" placeholder="Name of Card" style={{ borderRadius: "15px" }} />
                  </Form.Group>

                  <Container>
                    <Form.Group controlId="cardnumber" className="row">
                      <Form.Label>Credit Card</Form.Label>
                      <Form.Control type="text" placeholder="0000 0000 0000 0000" style={{ borderRadius: "20px", marginBottom: "20px" }} />
                      <Col style={{ paddingLeft: 0 }}>
                        <Form.Control type="text" name="expirydate" placeholder="mm/yy" style={{ borderRadius: "20px" }} />
                      </Col>
                      <Col style={{ paddingRight: 0 }}>
                        <Form.Control type="text" name="securitycode" placeholder="CVC" style={{ borderRadius: "20px" }} />
                      </Col>
                    </Form.Group>
                  </Container>

                  <Button variant="primary" type="submit" style={{ width: "100%", borderRadius: "20px", marginTop: "52px", marginBottom: "30px" }}>
                    Confirm Order
                  </Button>
                </Form> */}
              </div>
              <div className="col-md-6">
                {/* <Card bg="light" style={{ borderRadius: "20px" }}>
                  <Card.Body>
                    <Card.Title style={{ textAlign: "center", color: "#3493ee", fontFamily: "Open Sans", marginBottom: 0 }}
                    >
                      Add Different Billing&nbsp;&nbsp;&nbsp;
                      <Button variant="outline-secondary" style={{ borderRadius: "50%", padding: "0px 6px", fontSize: "30px", border: "none", lineHeight: "1" }}
                      >
                        +
                      </Button>
                    </Card.Title>
                  </Card.Body>
                </Card> */}
                {loaded ?
                  <PaymentForm
                    paymentForm={window.SqPaymentForm}
                    handleSquare={handleSquare}
                    paymentUrl={SIGNUP_PAYMENT}
                    appId={SANDBOX_APPID}
                  /> : null
                }
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default connect(
  null,
  auth.actions
)(AuthPageBill);
