import React from "react";
import { Table } from "react-bootstrap";
import WithHeaderWindow from '../common_component/WithHeaderWindow';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Button from "@material-ui/core/Button";
import { toAbsoluteUrl } from "../../../../_metronic";
import "./css/borderlesstable.scss";

export default function SettingsBill0(props) {
    return (
        <>
            <WithHeaderWindow title="Subscriptions" style={{ marginTop: "25px" }}>
                <div
                    className="content"
                    style={{
                        // border: "1px solid #D4D6D9",
                        padding: "40px",
                        borderBottomLeftRadius: "5px",
                        borderBottomRightRadius: "5px",
                        // background: "white"
                    }}
                >
                    <Table responsive>
                        <tbody>
                            <tr>
                                <td>
                                    <h4
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "#707070"
                                        }}
                                    >
                                        Youth Rush Pro Yearly Subscription
                                    </h4>
                                    <span style={{ fontSize: 18, color: "#707070" }}>Central California Conference</span>
                                </td>
                                <td>
                                    <p
                                        style={{
                                            fontSize: 20,
                                            color: "#707070",
                                            marginBottom: 0
                                        }}
                                    >
                                        <b>$99.00/year</b> | Visa ending 4533
                                    </p>
                                    <span style={{ fontSize: 18, color: "#707070" }}>Next billing date: 12/10/2020</span>
                                </td>
                                <td
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#36435C",
                                    }}
                                >
                                    <a
                                        href="#"
                                        target="_black"
                                        style={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: "#3891EE"
                                        }}
                                    >
                                        View last invoice (PDF)
                                    </a>
                                    <ReceiptIcon style={{ fontSize: 50 }} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </WithHeaderWindow>
            <div className="row" style={{ marginTop: "54px" }}>
                <div className="col-sm-8">
                    <WithHeaderWindow title="Billing History">
                        <div style={{ padding: "50px 40px" }}>
                            <Table responsive>
                                <tbody>
                                    <tr style={{ display: "flex", alignItems: "center" }}>
                                        <td>
                                            <span
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    color: "white",
                                                    padding: "5px 30px",
                                                    background: "#82B700",
                                                    borderRadius: 3,
                                                    boxShadow: "1px 1.73px 8px 0px rgba(0, 0, 0, 0.4)"
                                                }}
                                            >Paid</span>
                                        </td>
                                        <td
                                            style={{
                                                fontSize: 18,
                                                fontWeight: "bold",
                                                color: "#898989"
                                            }}
                                        >
                                            Youth Rush Pro
                                        </td>
                                        <td
                                            style={{
                                                fontSize: 18,
                                                color: "#3692EE"
                                            }}
                                        >
                                            View last invoice (PDF)
                                        </td>
                                        <td style={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: "#898989"
                                        }}
                                        >
                                            12/10/2019
                                        </td>
                                        <td
                                            style={{
                                                fontSize: 18,
                                                color: "#898989"
                                            }}
                                        >
                                            Visa / 4533
                                        </td>
                                        <td
                                            style={{
                                                fontSize: 18,
                                                color: "#898989"
                                            }}
                                        >
                                            $99.00
                                        </td>
                                    </tr>
                                    <tr style={{ display: "flex", alignItems: "center" }}>
                                        <td>
                                            <span
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    color: "white",
                                                    padding: "5px 30px",
                                                    background: "#82B700",
                                                    borderRadius: 3,
                                                    boxShadow: "1px 1.73px 8px 0px rgba(0, 0, 0, 0.4)"
                                                }}
                                            >Paid</span>
                                        </td>
                                        <td
                                            style={{
                                                fontSize: 18,
                                                fontWeight: "bold",
                                                color: "#898989"
                                            }}
                                        >
                                            2x YR Program
                                        </td>
                                        <td
                                            style={{
                                                fontSize: 18,
                                                color: "#3692EE"
                                            }}
                                        >
                                            View last invoice (PDF)
                                        </td>
                                        <td style={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: "#898989"
                                        }}
                                        >
                                            12/8/2019
                                        </td>
                                        <td
                                            style={{
                                                fontSize: 18,
                                                color: "#898989"
                                            }}
                                        >
                                            Visa / 4533
                                        </td>
                                        <td
                                            style={{
                                                fontSize: 18,
                                                color: "#898989"
                                            }}
                                        >
                                            $98.00
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </WithHeaderWindow>
                </div>
                <div className="col-sm-4">
                    <WithHeaderWindow title="Current Subscription">
                        <div
                            style={{
                                padding: 20,
                                paddingBottom: 50,
                                textAlign: "center"
                            }}
                        >
                            <h3 style={{ fontSize: 25, fontWeight: "bold", color: "#707070", marginBottom: 0 }}
                            >Youth Rush Pro</h3>
                            <span style={{ fontSize: 20, fontWeight: "normal", color: "#707070" }}
                            >Yearly Subscription</span>
                            <p
                                style={{
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    color: "#82B700",
                                    paddingTop: 25,
                                    paddingBottom: 25
                                }}
                            >
                                $99/ yr
                            </p>
                            <Button
                                style={{
                                    background: "#36435C",
                                    color: "white",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "238px",
                                    height: "44px",
                                    borderRadius: "3px", 
                                    padding: "9px 18px"
                                }}
                                onClick={props.handleCancelSubscription}
                            >
                                Cancel Subscription
                            </Button>
                        </div>

                    </WithHeaderWindow>
                    <WithHeaderWindow title="Payment Method" style={{ marginTop: 35 }}>
                        <div
                            style={{
                                padding: 20,
                                paddingBottom: 50, 
                                textAlign: "center"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center", 
                                    justifyContent: "center"
                                }}
                            >
                                <img
                                    alt="notification"
                                    width="80px"
                                    // style={{border: "3px solid white"}}
                                    className="img-fluid rounded-circle"
                                    src={toAbsoluteUrl("/media/logos/visa@2x.png")}
                                />
                                <div style={{ marginLeft: "10px" }}>
                                    <span
                                        style={{ fontSize: 25, fontWeight: "normal", color: "#707070" }}
                                    >4533| Exp 03/24</span>
                                </div>
                            </div>
                            <Button
                                style={{
                                    background: "#36435C",
                                    marginTop: 53, 
                                    color: "white",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "238px",
                                    height: "44px",
                                    padding: "9px 18px", 
                                    borderRadius: "3px"
                                }}
                                onClick={props.handleChangePayment}
                            >
                                Change Payment Method
                            </Button>
                        </div>
                    </WithHeaderWindow>
                </div>
            </div>
        </>
    );
}
