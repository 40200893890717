import React, { useState } from 'react';
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import WithHeaderWindow from "../common_component/WithHeaderWindow";
import { toAbsoluteUrl } from "../../../../_metronic";
import "../css/add_new_product.scss";

const CustomSwitch = withStyles({
    root: {
        width: "69px",
        height: "44px",
    },
    switchBase: {
        color: 'white',
        // backgroundColor: "#CDDC39",
        '&$checked': {
            color: '#438AFE !important',
        },
        '&$checked + $track': {
            backgroundColor: '#438AFE !important',
            opacity: "1",
        },
    },
    checked: {
        // backgroundColor: "black",
    },
    track: {
        backgroundColor: "rgba(67, 138, 254, 0.45)",
        height: "20px",
        marginTop: "-3px",
        opacity: "1",
        border: "1px solid #438AFE"
    },
    thumb: {
        border: "4px solid white",
        width: "28px",
        height: "28px",
        marginTop: "-4px",
    }
})(Switch);

export default function SettingsProgram() {
    const [active, setActive] = useState(false);

    const handleSwitch = () => {
        setActive(!active);
    }

    return (
        <div className="row">
            <div className="col-xl-9">
                <WithHeaderWindow title="Connect to Square" style={{ marginTop: "25px" }} penIcon={true}>
                    <div
                        className="content row"
                        style={{
                            // border: "1px solid #D4D6D9",
                            padding: "40px",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px",
                            // background: "white"
                        }}
                    >
                        <div
                            className="col-md-5"
                        >
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} sm="12" controlId="formGridLogin">
                                        <Form.Label>Login</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} sm="12" controlId="formGridPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="" />
                                    </Form.Group>
                                </Form.Row>

                                <Button
                                    style={{
                                        background: "#36435C",
                                        color: "white",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        width: "216px",
                                        height: "44px",
                                        borderRadius: "3px",
                                        marginTop: 63,
                                        float: "right",
                                    }}
                                >
                                    Connect
                                </Button>
                            </Form>
                        </div>
                        <div style={{ textAlign: "center" }} className="col-md-7">
                            <img
                                src={toAbsoluteUrl("/media/logos/square-logo.png")}
                                alt="square"
                                className="img-fluid"
                            />
                            <div style={{marginTop: 115}}>
                                <CustomSwitch checked={active} onClick={handleSwitch} />
                                <span
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "normal",
                                        color: "#555555", 
                                        marginLeft: 36, 
                                    }}
                                >
                                    Disconnect
                                    </span>
                            </div>
                        </div>
                    </div>
                </WithHeaderWindow>

            </div>
        </div >
    );
}