import React, { useState } from "react";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
// import ProgramFormItem from "./ProgramFormItem";
import MyDateRangePicker from "../MyDateRangePicker";
import RoundTextField from "../RoundTextField";
import ls from 'local-storage';

function AuthProgram(props) {
    const { currentStep } = useSelector(({ auth }) => ({ currentStep: auth.activeStep }), shallowEqual);
    const { intl } = props;
    const program_counts = ls.get("youthrush_program_count");
    const program_values = ls.get("youthrush_program_values");
    const [programCount, setProgramCount] = useState(program_counts || 2);

    // const generateInitialValues = number => {
    //     const result = [];
    //     for (var i = 0; i < number; i++) {
    //         result[`programname${i + 1}`] = "";
    //         result[`programyear${i + 1}`] = "";
    //         result[`programdates${i + 1}`] = "";
    //     }
    //     console.log(result);
    //     return result;
    // }

    return (
        <>

            <div style={{ textAlign: "center", marginTop: "70px" }} >
                <h4
                    onClick={() => {
                        ls.set("youthrush_program_count", 0);
                        ls.set("youthrush_program_values", null);
                        props.skipFunction();
                    }}
                    style={{
                        textDecoration: "underline",
                        fontSize: "21px",
                        fontWeight: "normal",
                        color: "#014386",
                        fontFamily: "Open Sans",
                        cursor: "pointer"
                    }}
                >
                    Skip this step and purchase programs later
                </h4>
            </div>
            <div style={{ textAlign: "center", marginTop: "26px" }}>
                {/* <img
                    alt="bottom-logo"
                    className="img-fluid"
                    width="292px"
                    height="190px"
                    src={toAbsoluteUrl("/media/blog/program_card.png")}
                /> */}
                <div style={{
                    background: "rgba(242, 239, 239, 0.47)",
                    border: "2px solid #EBEBEB",
                    borderRadius: "27px",
                    padding: "44px 32px",
                    textAlign: "center",
                    margin: "0 auto",
                    width: "292px"
                }}>
                    <h2
                        onClick={() => { setProgramCount(programCount + 1) }}
                        style={{
                            fontSize: "27px",
                            fontWeight: "bold",
                            color: "#3692EE",
                            marginBottom: "32px",
                            cursor: "pointer"
                        }}
                    >
                        Add Program
                    </h2>
                    <span
                        style={{
                            fontSize: "27px",
                            fontWeight: "normal",
                            color: "#A8A8A8",
                            background: "white",
                            border: "2px solid #EBEBEB",
                            borderRadius: "27px",
                            padding: "16px 28px",
                            marginRight: "19px"
                        }}
                    >
                        {programCount}
                    </span>
                    <span style={{ fontSize: "30px", fontWeight: "normal", color: "#959595" }}>$49 Each</span>
                </div>
            </div>
            <div className="row" style={{ marginTop: "54px" }}>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <Formik
                        initialValues={program_values || null}
                        // {program_values ? initialValues=program_values : null}
                        validate={values => {
                            const errors = {};

                            for (var i = 0; i < programCount; i++) {
                                if (!values[`programname${i + 1}`]) {
                                    errors[`programname${i + 1}`] = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (!values[`programyear${i + 1}`]) {
                                    errors[`programyear${i + 1}`] = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (isNaN(values[`programyear${i + 1}`])) {
                                    errors[`programyear${i + 1}`] = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_YEAR"
                                    });
                                }
                                if (!values[`programdates${i + 1}`]) {
                                    errors[`programdates${i + 1}`] = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            ls.set("youthrush_program_count", programCount);
                            ls.set("youthrush_program_values", values);
                            props.setProgramCount(programCount);
                            props.skipFunction();
                        }}
                    >
                        {({
                            values,
                            status,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    {status && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{status}</div>
                                        </div>
                                    )}

                                    {[...Array(programCount).keys()].map(i =>
                                        // <ProgramFormItem
                                        //     key={i}
                                        //     number={i + 1}
                                        //     handleBlur={handleBlur}
                                        //     handleChange={handleChange}
                                        //     values={values}
                                        //     touched={touched}
                                        //     errors={errors}
                                        // />
                                        <div key={i}>
                                            <div className="form-group mb-0">
                                                <RoundTextField
                                                    type="text"
                                                    margin="normal"
                                                    headerLabel={`Program Name #${i + 1}`}
                                                    name={`programname${i + 1}`}
                                                    placeholder=""
                                                    lastStyle={{}}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values[`programname${i + 1}`]}
                                                    helperText={touched[`programname${i + 1}`] && errors[`programname${i + 1}`]}
                                                    error={Boolean(touched[`programname${i + 1}`] && errors[`programname${i + 1}`])}
                                                />
                                            </div>

                                            <div className="form-group mb-0">
                                                <RoundTextField
                                                    type="text"
                                                    margin="normal"
                                                    headerLabel={`Program Year`}
                                                    name={`programyear${i + 1}`}
                                                    placeholder={`Program Year`}
                                                    lastStyle={{}}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values[`programyear${i + 1}`]}
                                                    helperText={touched[`programyear${i + 1}`] && errors[`programyear${i + 1}`]}
                                                    error={Boolean(touched[`programyear${i + 1}`] && errors[`programyear${i + 1}`])}
                                                />
                                            </div>
                                            <div className="form-group mb-5">
                                                <MyDateRangePicker
                                                    number={i + 1}
                                                    margin="normal"
                                                    headerLabel={`Program Dates`}
                                                    name={`programdates${i + 1}`}
                                                    placeholder="mm/dd/yy-mm/dd/yy"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    textValue={values[`programdates${i + 1}`]}
                                                    values={values}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="kt-login__actions" style={{ marginTop: "25px", marginBottom: "25px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <button
                                            onClick={() => { props.setActiveStep(currentStep - 1) }}
                                            type="button"
                                            className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                                            style={{
                                                height: "50px",
                                                paddingLeft: "40px",
                                                paddingRight: "40px",
                                                borderRadius: "25px"
                                            }}
                                        >
                                            Back
                                        </button>
                                        <button
                                            disabled={isSubmitting}
                                            type="submit"
                                            className="btn btn-primary btn-elevate kt-login__btn-primary"
                                            style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "25px", height: "50px" }}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </form>
                            )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(AuthProgram)
);