import {
    PROCESS_PAYMENT_URL
} from "../../app/crud/payment.crud";
import crypto from "crypto"
import squareConnect from "square-connect";

// Set the Access Token
const accessToken = 'EAAAEABKMbouAX2aw6bfqk6W_YmI1qFvqpoZxlwCsRKlBisD2j1TV7sdRvqcuCBl';

// Set Square Connect credentials and environment
const defaultClient = squareConnect.ApiClient.instance;

// Configure OAuth2 access token for authorization: oauth2
const oauth2 = defaultClient.authentications['oauth2'];
oauth2.accessToken = accessToken;

// Set 'basePath' to switch between sandbox env and production env
// sandbox: https://connect.squareupsandbox.com
// production: https://connect.squareup.com
defaultClient.basePath = 'https://connect.squareupsandbox.com';

export default function mockPayment(mock) {
    mock.onPost(PROCESS_PAYMENT_URL).reply((req) => {
        const request_params = JSON.parse(JSON.parse(req.data).body);

        // length of idempotency_key should be less than 45
        const idempotency_key = crypto.randomBytes(22).toString('hex');

        // Charge the customer's card
        const payments_api = new squareConnect.PaymentsApi();
        const request_body = {
            source_id: request_params.nonce,
            amount_money: {
                amount: 5291, // $52.91 charge
                currency: 'USD'
            },
            idempotency_key: idempotency_key
        };
        console.log(request_body);

        payments_api.createPayment(request_body)
            .then((response) => {
                console.log(response);
                return [200, {
                    'title': 'Payment Successful',
                    'result': response
                }];
            })
            //   res.status(200).json({
            //     'title': 'Payment Successful',
            //     'result': response
            //   });
            .catch((error) => {
                return [500, {
                    'title': 'Payment Failure',
                    'result': error.response.text
                }];
                //   res.status(500).json({
                //     'title': 'Payment Failure',
                //     'result': error.response.text
                //   });
            });
    });
}  