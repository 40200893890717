import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        textAlign: "center",
        position: 'relative',
        fontSize: "14px",
        fontWeight: "normal",
        width: '60px',
        height: "13px",
        padding: '8px 14px',
        border: "1px solid #E2E2E2",
        fontFamily: "Open Sans",
        '&:focus': {
            boxShadow: "0 0 35px 0 rgba(0, 0, 0, 0.15)",
        },
    },
    Input: {
        marginTop: "0",
    },

    notchedOutline: {
        border: "1px solid #E2E2E2", 
        borderRigth: "none", 
        borderTopRightRadius: 0, 
        borderBottomRightRadius: 0, 
    },

}));


const options = ['10', '20', '30'];

export default function SelectTableEntryCountBar() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [entryCount, setEntryCount] = React.useState(options[0]);

    // const handleClick = () => {
    //     console.info(`You clicked ${options[selectedIndex]}`);
    // };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setEntryCount(options[index]);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <div xs={12}
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <span style={{ fontSize: "14px", fontWeight: "normal", color: "#7C7C7C", marginRight: "5px" }}>Show</span>{" "}
                <TextField style={{
                    background: "white",
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#7C7C7C",
                    width: "60px",
                    height: "31px",
                }}
                    inputProps={{
                        className: classes.input
                    }}
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline
                        },
                    }}
                    ref={anchorRef} value={entryCount} variant="outlined" />
                <button
                    // size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    // aria-expanded={open ? 'true' : undefined}
                    // aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    style={{ 
                        background: "white", 
                        border: "1px solid #E2E2E2", 
                        padding: "4px 0", 
                        borderLeft: "none", 
                        borderTopRightRadius: "4px", 
                        borderBottomRightRadius: "4px"
                    }}
                >
                    <MenuIcon style={{width: "31px"}} />
                </button>{" "}
                <span style={{ fontSize: "14px", fontWeight: "normal", color: "#7C7C7C", marginLeft: "5px" }}>Entries</span>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="top" transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                // disabled={index === 2}
                                                selected={index === selectedIndex}
                                                onClick={event => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}