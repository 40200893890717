import React from "react";
import MyStepNavbar from "../../components/MyStepNavbar";
import MyHStepper from "../../components/MyHStepper";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";
import AuthOrganization from "../../components/auth_components/AuthOrganization";
import AuthProgram from "../../components/auth_components/AuthProgram";
import AuthBilling from "../../components/auth_components/AuthBilling";
import ReviewPay from "../../components/auth_components/ReviewPay";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";

const pages = ["organization", "program", "billing", "review"];
const titles = ["Setup Your Organization", "Set up your programs", "Set up billing", "Welcome!"];

function AuthPageStep(props) {
    const { currentStep } = useSelector(({auth}) => ({currentStep: auth.activeStep}), shallowEqual);

    const plusActiveStep = () => {
        props.setActiveStep(currentStep + 1);
    }

    const minusActiveStep = () => {
        props.setActiveStep(currentStep - 1);
    }

    const handleForceSignOut = () => {
        props.logout();
    }

    return (
        <>
            <div style={{ backgroundColor: "white" }}>
                <MyStepNavbar handleForceSignOut={handleForceSignOut} />
                <div
                    id="kt_login"
                    className="container-fluid"
                    style={{
                        maxWidth: "1400px",
                        margin: "0 auto",
                    }}
                >
                    <div style={{ textAlign: "center", paddingTop: "50px" }}>
                        <h1 style={{ fontSize: "48px", fontFamily: "Open Sans", fontWeight: "normal", color: "#014386" }}>
                            {titles[currentStep - 1]}
                        </h1>
                    </div>
                    <MyHStepper />
                    <div className="bill_content">
                        {
                            {
                                organization: <AuthOrganization skipFunction={plusActiveStep} />,
                                program: <AuthProgram skipFunction={plusActiveStep} backFunction={minusActiveStep} />,
                                billing: <AuthBilling backFunction={minusActiveStep} />, 
                                review: <ReviewPay />
                            }[pages[currentStep - 1]]
                        }
                    </div>
                </div>
            </div>
        </>
    );
}


export default connect(null, auth.actions)(AuthPageStep);