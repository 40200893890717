import axios from "axios";
import app from "../components/base"

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(email, password) {
  // return axios.post(LOGIN_URL, { email, password });
  return app.auth().signInWithEmailAndPassword(email, password);
}

export function register(firstname, lastname, email, password) {
  // return axios.post(REGISTER_URL, { confname, email, confabbr, password });
  return app.auth().createUserWithEmailAndPassword(email, password)
    .then((response) => {
      const currentUser = app.auth().currentUser;
      var usersRef = app.database().ref().child("admins");
      usersRef.child(currentUser.uid).update({
        "firstname": firstname,
        "lastname": lastname,
        "email": email, 
        "password": password, 
        "subflag": false
      });
    });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL); 
  // return app.auth().currentUser;
}
