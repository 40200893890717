import {
  // LOGIN_URL,
  ME_URL,
  // REGISTER_URL,
  REQUEST_PASSWORD_URL
} from "../../app/crud/auth.crud";
import userTableMock from "./userTableMock";
import app from "../../app/components/base";

export default function mockAuth(mock) {
  // mock.onPost(LOGIN_URL).reply(({ data }) => {
    // const { email, password } = JSON.parse(data);

    // if (email && password) {
    //   const user = userTableMock.find(
    //       x =>
    //           x.email.toLowerCase() === email.toLowerCase() &&
    //           x.password === password
    //   );

    //   if (user) {
    //     return [200, { ...user, password: undefined }];
    //   }
    // }

    // return [400];
    // if (email && password) {
    //   app.auth().signInWithEmailAndPassword(email, password);
    //   const user = app.auth().currentUser;
    //   console.log(user);
    //   if (user) {
    //     return [200, { user }];
    //   }
    // }
    // return [400]
  // });

  // mock.onPost(REGISTER_URL).reply(({ data }) => {
    // const { confname, email, confabbr, password } = JSON.parse(data);
    // if (confname && email && confabbr && password) {
    // const user = {
    //   email,
    //   fullname,
    //   username,
    //   password,
    //   roles: [2], // Manager
    //   accessToken: "access-token-" + Math.random(),
    //   refreshToken: "access-token-" + Math.random(),
    //   pic: process.env.PUBLIC_URL + "/media/users/default.jpg"
    // };

    // userTableMock.push(user);

    // return [200, { ...user, password: undefined }];
    // app.auth().createUserWithEmailAndPassword(email, password);
    // if(app.auth().currentUser)
    // {
    //   return [200];
    // }

    // return [400];

    // .then(function () {
    //   app.auth().signInWithEmailAndPassword(email, password);
    //   const user = app.auth().currentUser;
    //   return [200, { user }];
    // });

    // }

    // return [400];
  // });

  mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
    const { email } = JSON.parse(data);

    if (email) {
      const user = userTableMock.find(
        x => x.email.toLowerCase() === email.toLowerCase()
      );

      if (user) {
        user.password = undefined;

        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);
    console.log(accessToken);

    if (accessToken) {
      // const user = userTableMock.find(x => x.accessToken === accessToken);
      const user = app.auth().currentUser;
      if (user) {
        return [200, { user }];
      }
    }

    return [401];
  });
}
