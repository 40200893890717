import React, { useState, useEffect } from 'react';
import LargeSearchBar from './common_component/LargeSearchBar';
import WhiteButton from "./common_component/WhiteButton";
import SelectTableEntryCountBar from "./inventory_component/SelectTableEntryCountBar";
import CategoryTable from "./inventory_component/CategoryTable";
import { GET_CATEGORY } from "../../components/constants";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import app from "../../components/base";

// const rows = {};


// fetch(GET_CATEGORY, {
//     method: "GET"
// })
//     .then(response => {
//         return response.text();
//     })
//     .then(data => {
//         const categories = JSON.parse(data);
//         Object.keys(categories).forEach(key => {
//             rows[key] = categories[key];
//         })
//     })


export default function AllCategories() {
    const { currentUser } = useSelector(({ auth }) => ({ currentUser: auth.user }), shallowEqual);
    const history = useHistory();

    const [rows, setRows] = useState({});
    // const [activeCategories, setActiveCategories] = useState([]);

    // function createData(category, products, color, parent, tags) {
    //     return { category, products, color, parent, tags };
    // }

    // const rows = [
    //     createData('Message', 5, "#F76C2E", '', ''),
    //     createData('Health', 5, '#51A1E3', '', ''),
    //     createData('Childrens', 5, '#8DE08C', '', ''),
    //     createData('Dropdown', 5, '#9F9D4A', '', ''),
    // ];

    useEffect(() => {
        const abortController = new AbortController();
        app.auth().onAuthStateChanged(function (user) {
            if (user != null) {
                const adminRef = app.database().ref().child('admins/' + user.uid);
                adminRef.once('value', snapshot => {
                    const admin = snapshot.val();
                    const activeCategoryIds = admin.activeCategoryIds ? admin.activeCategoryIds : [];

                    fetch(GET_CATEGORY, {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            adminId: currentUser.user.uid,
                        }),
                        signal: abortController.signal,
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            const rows = JSON.parse(data);
                            if (rows) {
                                Object.keys(rows).forEach((key, i) => {
                                    // if (rows[key].adminId !== "master") {
                                    rows[key].active = activeCategoryIds.indexOf(key) > -1;
                                    // }
                                })
                            }
                            setRows(rows);
                        })

                })
            }
        })

        return () => {
            abortController.abort();
        }

    }, [currentUser.user.uid])


    return (
        <div className="row">
            <div className="col-xl-9">
                <div className="row inventory_submenu">
                    <div className="col-md-3">
                        <h4
                            style={{
                                fontSize: "30px",
                                fontWeight: "normal",
                                color: "#111111",
                                marginRight: "36px"
                            }}
                        >
                            All Categories
                    </h4>
                    </div>
                    <div className="col-md-9">
                        <LargeSearchBar placeholder="Search category, tag" />
                    </div>
                </div>

                <div className="row button_bar" style={{ marginTop: "43px" }}>
                    <div className="col-sm-4">
                        <WhiteButton caption="View All Categories" />
                    </div>
                    <div className="col-sm-4">
                        <WhiteButton handleClick={() => { history.push("/add-new-category") }} caption="Add New Category" addIcon />
                    </div>
                </div>

                <div className="product_list" style={{ marginTop: "62px", background: "white", padding: "26px 29px" }}>
                    <h5 style={{ fontSize: "14px", fontWeight: "bold", color: "#333333", marginBottom: "30px" }}>Category Listing</h5>
                    <SelectTableEntryCountBar />
                    <div style={{ height: "25px" }}></div>
                    <CategoryTable rows={rows} />
                </div>
            </div>
        </div>
    )
}
