import React from 'react';
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RefreshIcon from "@material-ui/icons/Refresh";

export default function LeaderSales(props) {
    const data = props.data;
    return (
        <div style={{ background: "white", padding: "25px 25px", borderRadius: "5px" }}>
            <div className="row">
                <div className="col-12" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <h4 style={{ fontSize: "14px", fontWeight: "bold", color: "#333333", float: "left" }}>Leaders Sales</h4>

                    <div style={{ marginRight: "-22px" }}>
                        <IconButton aria-label="Settings">
                            <RefreshIcon />
                        </IconButton>

                        <IconButton aria-label="Settings">
                            <MoreVertIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className="row" style={{ fontSize: "12px", fontWeight: "normal", color: "#333333" }}>
                <div className="col-2"></div>
                <div className="col-6">Total Sales</div>
                <div className="col-4">Student Average</div>
            </div>
            <div
                style={{
                    width: "100%",
                    height: "4px",
                    borderRadius: "2px",
                    backgroundImage: "linear-gradient(to right, #4CF6A2, #05C2F7)",
                    marginBottom: "12px"
                }}
            >
            </div>
            <div className="leader_table">
                {
                    data.length > 0 ?
                        data.map((row, i) => {
                            return <div key={i} className="row" style={{ marginBottom: "30px", display: "flex", alignItems: "center" }}>
                                <div className="col-3">
                                    <img
                                        alt="avata"
                                        className="rounded-circle"
                                        width="100%"
                                        src={row.photo}
                                    />
                                </div>
                                <div className="col-5" style={{ fontSize: "14px", fontWeight: "500", color: "#111111" }}>
                                    {row.name}<br />
                                    <span style={{ fontSize: "13px", fontWeight: "normal", color: "#7C7C7C" }}>${row.total.toFixed(2)}</span>
                                </div>
                                <div className="col-4" style={{ fontSize: "13px", fontWeight: "normal", color: "#7C7C7C" }}>${row.average.toFixed(2)}</div>
                            </div>

                        })
                    : null
                }
            </div>
        </div>
    )
}
