import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  input: {
    borderRadius: "25px",
    position: 'relative',
    fontSize: "14px",
    fontWeight: "normal",
    width: '100%',
    height: "30px",
    padding: '10px 20px',
    border: "1px solid #bfbfbf", 
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: "Open Sans",
    '&:focus': {
      boxShadow: "0 0 35px 0 rgba(0, 0, 0, 0.15)",
      borderColor: "#4EA8F9",
      borderWidth: "2px", 
    },
    // '&:error $notchedOutline':{
    //   borderColor: "#004486", 
    // }
  },
  Input: {
    borderRadius: "30px",
    marginTop: "0",
  },

  underline: {
    border: `2px solid #004486 !important`,
  },

  notchedOutline: {
    border: "none"
  }, 

  helperroot:{
  }, 
  helper: {
    color: "#004486 !important",
  }
}));

export default function RoundTextField(props) {
  const classes = useStyles();
  return (
    <>
      <label style={{ fontSize: "14px", fontWeight: "500", color: "black" }}>{props.headerLabel}</label>
      <TextField
        style={!props.lastStyle ? { marginTop: "4px", marginBottom: "24px" } : props.lastStyle}
        type={props.type}
        margin={props.margin}
        label={props.label}
        className={props.className}
        name={props.name}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onClick={props.onClick}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        variant="outlined"
        multiline={props.multiline}
        rows={props.rows}
        InputProps={{
          classes: {
            root: classes.Input,
            error: classes.underline, 
            notchedOutline: classes.notchedOutline
          }, 
        }}
        notched={{

        }}
        inputProps={{
          className: classes.input,
        }}
        FormHelperTextProps={{
          // className: classes.helperText, 
          classes: {
            root: classes.helperroot, 
            error: classes.helper
          }
        }}
      />
    </>
  );
}