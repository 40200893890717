import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularIntegration from "../common_component/CircularIntegration";

const styles = {
  name: {
    verticalAlign: "top",
    display: "none",
    margin: 0,
    border: "none",
    fontSize: "16px",
    fontFamily: "Helvetica Neue",
    padding: "16px",
    color: "#373F4A",
    backgroundColor: "transparent",
    lineHeight: "1.15em",
    placeholderColor: "#959595",
    _webkitFontSmoothing: "antialiased",
    _mozOsxFontSmoothing: "grayscale"
  },
  "&:focus": {
    backgroundColor: "#3996ee"
  },
  leftCenter: {
    float: "left",
    textAlign: "center"
  },
  blockRight: {
    display: "block",
    float: "right"
  },
  center: {
    textAlign: "center"
  }
};

export default class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardBrand: "",
      nonce: undefined,
      googlePay: false,
      applePay: false,
      masterpass: true,
      loading: false,
      success: false,
      loadingButtonStyle: {
        paddingRight: "2.5rem"
      },
      isSubmitting: false
    };
    this.requestCardNonce = this.requestCardNonce.bind(this);
    this.handleSquare = props.handleSquare;
  }

  enableLoading = () => {
    this.setState({
      loading: true,
      loadingButtonStyle: { paddingRight: "3.5rem" }
    });
  };

  disableLoading = () => {
    this.setState({
      loading: false,
      loadingButtonStyle: { paddingRight: "2.5rem" }
    });
  };

  requestCardNonce() {
    this.paymentForm.requestCardNonce();
    // this.enableLoading();
    if (!this.state.loading) {
      this.setState({
        success: false,
        loading: true
      });
    }
  }

  componentDidMount() {
    const config = {
      applicationId: this.props.appId,
      // locationId: "GMT96A77XABR1",
      inputClass: "sq-input",
      autoBuild: false,
      inputStyles: [
        {
          fontSize: "16px",
          fontFamily: "Arial",
          padding: "16px",
          color: "#373F4A",
          backgroundColor: "transparent",
          lineHeight: "1.15em",
          placeholderColor: "#959595",
          _webkitFontSmoothing: "antialiased",
          _mozOsxFontSmoothing: "grayscale"
        }
      ],
      applePay: {
        elementId: "sq-apple-pay"
      },
      masterpass: {
        elementId: "sq-masterpass"
      },
      googlePay: {
        elementId: "sq-google-pay"
      },
      cardNumber: {
        elementId: "sq-card-number",
        placeholder: "• • • •  • • • •  • • • •  • • • •"
      },
      cvv: {
        elementId: "sq-cvv",
        placeholder: "CVV"
      },
      expirationDate: {
        elementId: "sq-expiration-date",
        placeholder: "MM/YY"
      },
      postalCode: {
        elementId: "sq-postal-code",
        placeholder: "Zip"
      },
      callbacks: {
        methodsSupported: methods => {
          if (methods.googlePay) {
            this.setState({
              googlePay: methods.googlePay
            });
          }
          if (methods.applePay) {
            this.setState({
              applePay: methods.applePay
            });
          }
          if (methods.masterpass) {
            this.setState({
              masterpass: methods.masterpass
            });
          }
          return;
        },
        createPaymentRequest: () => {
          return {
            requestShippingAddress: false,
            requestBillingInfo: true,
            currencyCode: "USD",
            countryCode: "US",
            total: {
              label: "MERCHANT NAME",
              amount: "100",
              pending: false
            },
            lineItems: [
              {
                label: "Subtotal",
                amount: "100",
                pending: false
              }
            ]
          };
        },
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            console.log("Encountered errors:");
            errors.forEach(function(error) {
              console.log("  " + error.message);
            });

            return;
          }
          // this.setState({
          //   nonce: nonce
          // })

          //send process payment request
          // fetch(baseUrl + '/say/hello', {
          //   method: 'GET',
          // })
          // .then(response => console.log(response));
          fetch(this.props.paymentUrl, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify({
              nonce: nonce,
              price: this.props.price
            })
          })
            .catch(err => {
              alert("Network error: " + err);
              //   this.disableLoading();
              this.setState({
                success: true,
                loading: false
              });
            })
            .then(response => {
              if (!response.ok) {
                return response
                  .text()
                  .then(errorInfo => Promise.reject(errorInfo));
              }
              this.setState({
                success: true,
                loading: false
              });
              return response.text();
            })
            .then(data => {
              console.log(JSON.stringify(data));
              console.log(
                "Payment complete successfully!\nCheck browser developer console for more details"
              );
              this.handleSquare();
              this.disableLoading();
              this.setState({
                success: true,
                loading: false
              });
            })
            .catch(err => {
              console.error(err);
              console.log(
                "Payment failed to complete!\nCheck browser developer console for more details"
              );
              this.disableLoading();
            });
        },
        unsupportedBrowserDetected: () => {},
        inputEventReceived: inputEvent => {
          switch (inputEvent.eventType) {
            case "focusClassAdded":
              break;
            case "focusClassRemoved":
              break;
            case "errorClassAdded":
              document.getElementById("error").innerHTML =
                "Please fix card information errors before continuing.";
              break;
            case "errorClassRemoved":
              document.getElementById("error").style.display = "none";
              break;
            case "cardBrandChanged":
              if (inputEvent.cardBrand !== "unknown") {
                this.setState({
                  cardBrand: inputEvent.cardBrand
                });
              } else {
                this.setState({
                  cardBrand: ""
                });
              }
              break;
            case "postalCodeChanged":
              break;
            default:
              break;
          }
        },
        paymentFormLoaded: function() {
          // document.getElementById('name').style.display = "inline-flex";
        }
      }
    };
    this.paymentForm = new this.props.paymentForm(config);
    this.paymentForm.build();
  }

  render() {
    return (
      <div className="card_container">
        <div id="form-container">
          <div id="sq-walletbox">
            <button
              style={{ display: this.state.applePay ? "inherit" : "none" }}
              className="wallet-button"
              id="sq-apple-pay"
            ></button>
            <button
              style={{ display: this.state.masterpass ? "block" : "none" }}
              className="wallet-button"
              id="sq-masterpass"
            ></button>
            {/* <button style={{display: (this.state.googlePay) ? 'inherit': 'none'}}
                    className="wallet-button"
                    id="sq-google-pay"></button> */}
            <hr />
          </div>

          <div id="sq-ccbox" style={{ marginBottom: "19px" }}>
            <p style={{ display: "none" }}>
              <span style={styles.leftCenter}>Enter Card Info Below </span>
              <span style={styles.blockRight}>
                {this.state.cardBrand.toUpperCase()}
              </span>
            </p>
            <div id="cc-field-wrapper" className="row">
              <div className="col-9 cardnumcontainer">
                <div id="sq-card-number"></div>
              </div>
              <div id="sq-postal-code" className="col-3"></div>
              <input type="hidden" id="card-nonce" name="nonce" />
              <div className="col-6 expdatecontainer">
                <div id="sq-expiration-date"></div>
              </div>
              <div id="sq-cvv" className="col-6"></div>
            </div>
            {/* <input
                        id="name"
                        style={styles.name}
                        type="text"
                        placeholder="Name"
                        /> */}
          </div>
          <FormControlLabel
            control={
              <Checkbox
                // checked={state.checkedB}
                // onChange={handleChange('checkedB')}
                value="checkedB"
                // color="primary"
              />
            }
            label="Save card for future purchases?"
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              color: "#959595"
            }}
          />
          {/* <button
            className="button-credit-card btn"
            onClick={this.requestCardNonce}
            // onClick={this.props.handleSquare}
            // disabled={this.state.loading}
            // className={`btn btn-primary button-credit-card btn-elevate kt-login__btn-primary ${clsx(
            //   {
            //     "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
            //   }
            // )}`}
            // style={this.state.loadingButtonStyle}
          >
            Confirm Order
          </button> */}
          <div
            style={{
              //   width: "100%",
              //   background: "#36435C",
              marginTop: "300px"
            }}
          >
            <CircularIntegration
              caption="Confirm Order"
              loading={this.state.loading}
              success={this.state.success}
              handleButtonClick={this.requestCardNonce}
            />
          </div>
        </div>
        <p style={styles.center} id="error"></p>
      </div>
    );
  }
}
