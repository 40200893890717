import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    label: props => ({
        color: `${props.fontColor} !important`,
        fontSize: `${props.fontSize} !important`, 
        fontWeight: `${props.fontWeight} !important`
    })
}))

export default function LineChartLabel(props) {
    const classes = useStyles(props);

    const handleClick = () => {
        props.handleClick();
    }

    const CustomRadio = withStyles({
        root: {
            color: props.color,
            '&$checked': {
                color: props.color,
            },
        },
        checked: {},
    })(attrs => <Radio onClick={handleClick} checked={props.checked} color="default" {...attrs} />);

    return (
        <div>
            <FormControlLabel
                classes={{label: classes.label}}
                value={props.title}
                name={props.name}
                control={<CustomRadio />}
                label={props.title}
            />
        </div>
    )
}
