import React, { useState, useEffect } from "react";
import { Container, Card, Table, InputGroup, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";
import PaymentForm from './program_component/ProgramPaymentForm';
import * as auth from "../../store/ducks/auth.duck";
import { PAYMENT, SANDBOX_APPID } from "../../components/constants";
import RoundTextField from "../../components/RoundTextField";
import { toAbsoluteUrl } from "../../../_metronic"
import LargeSearchBar from './common_component/LargeSearchBar';
// import NewProgramCard from './program_component/NewProgramCard';
import AddIcon from '@material-ui/icons/Add';
import app from '../../components/base';
import "../../pages/css/payment.css";
import "./program_component/css/cuponstyle.scss";

const styles = {
    cupon: {
        borderRadius: "30px",
        height: "72px",
        textAlign: "left",
        fontSize: "27px",
        fontWeight: "bold",
        paddingLeft: "30px",
    },
}

function PurchaseNewProgram() {
    // const { currentStep, programCount } = useSelector(({ auth }) =>
    //     ({
    //         currentStep: auth.activeStep,
    //         programCount: auth.programCount
    //     }),
    //     shallowEqual
    // );

    const [admin, setAdmin] = useState(null);
    const [programCount, setProgramCount] = useState(2);
    const [programCost, setProgramCost] = useState(98);
    const [subTotal, setSubTotal] = useState(98);
    const [tax, setTax] = useState(7.90);
    const [total, setTotal] = useState(105.90);
    // const programCount = 2;


    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        app.auth().onAuthStateChanged((user) => {
            if (user != null) {
                app.database().ref(`admins/${user.uid}`).once('value', snapshot => {
                    setAdmin(snapshot.val());
                })
            }
        })
        let sqPaymentScript = document.createElement('script');
        sqPaymentScript.src = "https://js.squareupsandbox.com/v2/paymentform";
        sqPaymentScript.type = "text/javascript"
        sqPaymentScript.async = false;
        sqPaymentScript.onload = () => {
            setLoaded(true);
        };
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    }, []);

    // const handleCupon = () => {
    // props.setActiveStep(currentStep + 1);
    // app.auth().onAuthStateChanged(function (user) {
    //     if (user != null) {
    //         var usersRef = app.database().ref().child("users");
    //         usersRef.child(user.uid).update({ "subflag": true });
    //         props.login(user.uid, true);
    //     } else {
    //         // this.name = "Unknown";
    //         // alert("Login failed");
    //     }
    // });

    // }

    const backFunction = () => {
        // props.setActiveStep(currentStep - 1);
    }

    const handleSquare = () => {
        app.auth().onAuthStateChanged(function (admin) {
            if (admin != null) {
                app.database().ref(`admins/${admin.uid}`).once('value', userSnap => {
                    // const currentAdmin = userSnap.val();
                    const d = new Date();
                    const str = "" + d.getYear() + d.getMonth() + d.getDate() + d.getHours() + d.getMinutes() + d.getMinutes() + d.getSeconds();

                    const programs = Array.from({ length: programCount }).map((elem, i) => {
                        let program = {};
                        program.adminId = admin.uid;
                        program.name = "Program" + str + i;
                        program.start_date = new Date().toLocaleDateString();
                        program.end_date = new Date().toLocaleDateString();
                        program.year = new Date().getFullYear();
                        return program;
                    })

                    let update_data = {};
                    var programsRef = app.database().ref().child("programs");
                    programs.forEach((program) => {
                        const key = programsRef.push(program).key;
                        if (!update_data[program.year]) {
                            update_data[program.year] = [];
                        }
                        update_data[program.year].push(key);
                    })
                    const adminsRef = app.database().ref("admins");
                    Object.keys(update_data).forEach((year) => {
                        var updateRef = adminsRef.child(admin.uid + "/programIds/" + year);
                        if (updateRef) {
                            updateRef.once('value', snapshot => {
                                if (snapshot.val()) {
                                    const arr = [...snapshot.val(), ...update_data[year]];
                                    updateRef.set(arr);
                                } else {
                                    updateRef.set(update_data[year]);
                                }
                            })
                        }
                    })
                })
            } else {
                // this.name = "Unknown";
                // alert("Login failed");
            }
        });
    }

    // const handleForceSignOut = () => {
    //     props.logout();
    // }

    const handlePlusClick = () => {
        const count = programCount + 1;
        const programCost = 49 * count;
        const subTotal = programCost;
        const tax = Math.round(subTotal * 7.975) / 100;
        const total = subTotal + tax;
        setProgramCount(count);
        setProgramCost(programCost);
        setSubTotal(subTotal);
        setTax(tax);
        setTotal(total);
    }

    const handleMinusClick = () => {
        const count = programCount - 1;
        if (count >= 0) {
            const programCost = 49 * count;
            const subTotal = programCost;
            const tax = Math.round(subTotal * 7.975) / 100;
            const total = subTotal + tax;
            setProgramCount(count);
            setProgramCost(programCost);
            setSubTotal(subTotal);
            setTax(tax);
            setTotal(total);
        }
    }

    return (
        <>
            <div className="row inventory_submenu">
                <div className="col-md-3">
                    <h4
                        style={{
                            fontSize: "30px",
                            fontWeight: "normal",
                            color: "#111111",
                            marginRight: "36px"
                        }}
                    >
                        All Programs
                    </h4>
                </div>
                <div className="col-md-9">
                    <LargeSearchBar placeholder="Search programs, year" />
                </div>
            </div>
            {/* <div style={{ marginTop: "63px" }}>
                <NewProgramCard />
            </div> */}
            <div className="" style={{ marginTop: "37px", paddingBottom: "79px" }}>
                <div
                    style={{ background: "white", maxWidth: "1244px", padding: "75px 80px" }}
                >
                    <Container>
                        <div
                            className="row"
                        >
                            <div className="col-md-6">
                                {
                                    admin ?
                                        <Card style={{ border: "none" }}>
                                            <Card.Body>
                                                <Card.Title
                                                    style={{
                                                        fontSize: "25px",
                                                        fontWeight: "bold",
                                                        fontFamily: "Open Sans",
                                                        color: "#959595"
                                                    }}
                                                >
                                                    {admin.firstname + " " + admin.lastname}
                                                </Card.Title>
                                                <Card.Text
                                                    style={{
                                                        fontSize: "25px",
                                                        fontWeight: "normal",
                                                        fontFamily: "Open Sans",
                                                        color: "#959595"
                                                    }}
                                                >
                                                    {admin.orgname}<br />
                                                    {admin.orgaddress1}<br />
                                                    {admin.orgaddress2}<br />
                                                </Card.Text>
                                            </Card.Body>
                                        </Card> : null
                                }

                                <div style={{ marginTop: "47px" }}>
                                    <div className="form-group mb-0">
                                        <RoundTextField
                                            type="text"
                                            margin="normal"
                                            headerLabel="Name on Card"
                                            name="cardname"
                                            placeholder="Name on Card"
                                        // onBlur={handleBlur}
                                        // onChange={handleChange}
                                        // value={values.cardname}
                                        // helperText={touched.cardname && errors.cardname}
                                        // error={Boolean(touched.cardname && errors.cardname)}
                                        />
                                        <label style={{
                                            fontSize: "14px", fontWeight: "500", fontFamily: "Open Sans", color: "black"
                                        }}>Credit Card</label>
                                    </div>
                                    {loaded ?
                                        <PaymentForm
                                            paymentForm={window.SqPaymentForm}
                                            handleSquare={handleSquare}
                                            price={total}
                                            paymentUrl={PAYMENT}
                                            appId={SANDBOX_APPID}
                                            backFunction={backFunction}
                                            backButton={false}
                                        /> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 style={{
                                    marginTop: "44px",
                                    fontSize: "27px",
                                    fontFamily: "Open Sans",
                                    fontWeight: "bold",
                                    color: "#36435C",
                                    padding: "17px 40px",
                                    background: "rgba(242, 239, 239, .47)",
                                    border: "2px solid #EBEBEB",
                                    borderRadius: "25px",
                                    textAlign: "center"
                                }}
                                >
                                    Add Different Billing
                                    <span style={{ fontSize: "36px", fontWeight: "normal", marginTop: "18px", fontFamily: "Open Sans", color: "#959595", padding: "0 23px" }}>+</span>
                                </h3>
                                <Card style={{ marginTop: "30px", textAlign: "left", borderRadius: "20px", backgroundColor: "rgba(242, 239, 239, .47)" }}>
                                    <Card.Body>
                                        <Card.Title style={{ color: "#36435C", fontFamily: "Open Sans", fontSize: "27px", fontWeight: "bold" }}>Order Overview</Card.Title>
                                        <Table responsive style={{ color: "#959595", fontSize: "25px", fontFamily: "Open Sans", fontWeight: "normal" }}>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ display: "flex", justifyContent: "space-between" }}
                                                    >Programs
                                                        <div>
                                                            <span id="plus_botton"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    color: "#36415C"
                                                                }}
                                                                onClick={handlePlusClick}
                                                            >+</span>{" "}
                                                            <span id="plus_botton">{programCount}</span>{" "}
                                                            <span id="minus_botton"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    color: "#36415C"
                                                                }}
                                                                onClick={handleMinusClick}
                                                            >-</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>${programCost}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td style={{ textAlign: "right" }}>${subTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sales Tax(7.975%)</td>
                                                    <td style={{ textAlign: "right" }}>${tax}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr style={{ fontSize: "28px", fontWeight: "bold" }}>
                                                    <td>Total</td>
                                                    <td style={{ textAlign: "right" }}>${total}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <InputGroup className="mb-3" style={{ borderRadius: "20px" }}>
                                            <FormControl
                                                placeholder="Coupon Code"
                                                aria-label="Coupon Code"
                                                aria-describedby="basic-addon2"
                                                style={styles.cupon}
                                                id="cupon_input"
                                            />
                                            <AddIcon
                                                style={{
                                                    color: "white",
                                                    fontSize: "30px",
                                                    background: "#36435C",
                                                    borderRadius: "50%",
                                                    padding: "2px",
                                                    position: "absolute",
                                                    top: "20px",
                                                    right: "20px",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </InputGroup>
                                    </Card.Body>
                                </Card>
                                <div style={{ marginTop: "192px" }}>
                                    <img
                                        alt="bottom-logo"
                                        className="img-fluid"
                                        style={{ maxWidth: "348px", float: "right" }}
                                        src={toAbsoluteUrl("/media/blog/stripe-cc-payments1.png")}
                                    />
                                </div>
                            </div>
                        </div>
                    </Container>

                </div>

            </div>
        </>
    );
}


export default connect(
    null,
    auth.actions
)(PurchaseNewProgram);
