import React from 'react';
import { Card, CardHeader, IconButton, CardContent } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "../css/dashboard_statistics.scss";
import StatisticsSlider from "./StatisticsSlider";

export default function DashboardStatistics(props) {
    const programGoal = props.programGoal;
    const programTotal = props.programTotal;
    const studentCount = props.studentCount;
    const studentAverage = props.studentAverage;
    const percent = Math.round(!isNaN(programTotal / programGoal * 100) ? programTotal / programGoal * 100 : 0);
    return (
        <div className="row dashboard_statistics">
            <div className="col-md-4" style={{marginTop: "20px"}}>
                <Card style={{ paddingTop: "19px", paddingBottom: "30px", fontSize: "20px", fontWeight: "bold" }}>
                    <CardHeader
                        style={{
                            paddingLeft: "30px",
                            paddingRight: "10px",
                            paddingBottom: "9px"
                        }}

                        action={
                            <IconButton aria-label="Settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Total Program Sales"
                        titleTypographyProps={{ variant: 'h4' }}
                    />

                    <CardContent
                        style={{
                            paddingTop: "9px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}
                    >
                        <StatisticsSlider title={`$${programTotal.toFixed(2)}`} value={percent} />
                        <div>
                            <div style={{ float: "left" }}>
                                <span>{percent}%</span> Of program Goal
                            </div>
                            <div style={{ float: "right" }}>
                                To Goal: <span>${programGoal}</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-4" style={{marginTop: "20px"}}>
                <Card style={{ paddingTop: "19px", paddingBottom: "30px", fontSize: "20px", fontWeight: "bold" }}>
                    <CardHeader
                        style={{
                            paddingLeft: "30px",
                            paddingRight: "10px",
                            paddingBottom: "9px"
                        }}
                        action={
                            <IconButton aria-label="Settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Student Average"
                        titleTypographyProps={{ variant: 'h4' }}
                    />

                    <CardContent
                        style={{
                            paddingTop: "9px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}
                    >
                        <StatisticsSlider title={`$${studentAverage.toFixed(2)}`} value={10.4} />
                        <div>
                            <div style={{ float: "left" }}>
                                <span style={{ fontSize: "14px", fontWeight: "normal", color: "#FD5722" }}>+</span>
                                <span>10.4%</span> Last week
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-4" style={{marginTop: "20px"}}>
                <Card style={{ paddingTop: "19px", paddingBottom: "30px", fontSize: "20px", fontWeight: "bold" }}>
                    <CardHeader
                        style={{
                            paddingLeft: "30px",
                            paddingRight: "10px",
                            paddingBottom: "9px"
                        }}
                        action={
                            <IconButton aria-label="Settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Total Students"
                        titleTypographyProps={{ variant: 'h4' }}
                    />

                    <CardContent
                        style={{
                            paddingTop: "9px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}
                    >
                        <StatisticsSlider title={studentCount} value={10} />
                        <div>
                            <div style={{ float: "left" }}>
                                <span style={{ fontSize: "14px", fontWeight: "normal", color: "#43C702" }}></span>
                                <span>10%</span> Today
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}
