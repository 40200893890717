import React from "react";
// import { toAbsoluteUrl } from "../../../_metronic";

export default function Profile() {
    return (
        <div className="kt-section__body" style={{background: "white", padding: "20px 20px"}}>
            <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-6">
                    <h3 className="kt-section__title kt-section__title-sm"> </h3>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label" style={{textAlign: "right"}}>Avatar</label>
                <div className="col-lg-9 col-xl-6">
                    <div className="kt-avatar kt-avatar--outline kt-avatar--circle-" id="kt_user_edit_avatar">
                        <div className="kt-avatar__holder" style={{backgroundImage: `url("./media/users/300_20.jpg")`}}></div>
                        <label className="kt-avatar__upload" data-toggle="kt-tooltip" title="" data-original-title="Change avatar">
                            <i className="fa fa-pen"></i>
                            <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" />
                        </label>
                        <span className="kt-avatar__cancel" data-toggle="kt-tooltip" title="" data-original-title="Cancel avatar">
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label" style={{textAlign: "right"}}>First Name</label>
                <div className="col-lg-9 col-xl-6">
                    <input className="form-control" type="text" defaultValue="Nick" />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label" style={{textAlign: "right"}}>Last Name</label>
                <div className="col-lg-9 col-xl-6">
                    <input className="form-control" type="text" defaultValue="Bold" />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label" style={{textAlign: "right"}}>Company Name</label>
                <div className="col-lg-9 col-xl-6">
                    <input className="form-control" type="text" defaultValue="Loop Inc." />
                    <span className="form-text text-muted">If you want your invoices addressed to a company. Leave blank to use your full name.</span>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label" style={{textAlign: "right"}}>Contact Phone</label>
                <div className="col-lg-9 col-xl-6">
                    <div className="input-group">
                        <div className="input-group-prepend"><span className="input-group-text"><i className="la la-phone"></i></span></div>
                        <input type="text" className="form-control" defaultValue="+35278953712" placeholder="Phone" aria-describedby="basic-addon1" />
                    </div>
                    <span className="form-text text-muted">We'll never share your email with anyone else.</span>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label" style={{textAlign: "right"}}>Email Address</label>
                <div className="col-lg-9 col-xl-6">
                    <div className="input-group">
                        <div className="input-group-prepend"><span className="input-group-text"><i className="la la-at"></i></span></div>
                        <input type="text" className="form-control" defaultValue="nick.bold@loop.com" placeholder="Email" aria-describedby="basic-addon1" />
                    </div>
                </div>
            </div>
            <div className="form-group form-group-last row">
                <label className="col-xl-3 col-lg-3 col-form-label" style={{textAlign: "right"}}>Company Site</label>
                <div className="col-lg-9 col-xl-6">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Username" defaultValue="loop" />
                        <div className="input-group-append"><span className="input-group-text">.com</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
}   