import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import LargeSearchBar from "./common_component/LargeSearchBar";
import WhiteButton from "./common_component/WhiteButton";
import InterestTable from "./interest_component/InterestTable";
import InterestMap from "./interest_component/InterestMap";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import LineChartLabel from "./dashboard_component/LineChartLabel";
// import { GET_PRODUCT } from "../../components/constants";
// import SelectTableEntryCountBar from "./inventory_component/SelectTableEntryCountBar";
import "./interest_component/css/interest_list.scss";
import app from "../../components/base";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  }
});

const columns = [
  { id: "name", label: "Customer Name", minWidth: 80, numeric: false },
  { id: "address", label: "Address", minWidth: 125, numeric: false },
  { id: "city", label: "City", minWidth: 50, align: "center", numeric: false },
  {
    id: "state",
    label: "State",
    minWidth: 65,
    align: "center",
    numeric: false
  },
  { id: "zip", label: "Zip", minWidth: 40, align: "center", numeric: true },
  {
    id: "emailphone",
    label: "Email & Phone",
    minWidth: 155,
    align: "center",
    numeric: false
  },
  {
    id: "interest",
    label: "Interest",
    minWidth: 135,
    align: "center",
    numeric: false
  },
  { id: "date", label: "Date", minWidth: 60, align: "center", numeric: false }
];

const radiusOptions = [25, 50, 75];

export default function ProgramInterest() {
  const { programId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState({});
  const [interestTypes, setInterestTypes] = useState({});
  const [showState, setShowState] = useState(false);
  const [interests, setInterests] = useState({});
  const [locationInterests, setLocationInterests] = useState([]);
  const [locationChecks, setLocationChecks] = useState([]);
  const [typeKeys, setTypeKeys] = useState([]);

  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [radiusSelectedIndex, setRadiusSelectedIndex] = useState(0);

  var rad = function(x) {
    return (x * Math.PI) / 180;
  };

  var getDistance = function(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  };

  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;

    if (!showState) {
      setShowState(true);
      app
        .database()
        .ref("interests")
        .orderByChild("programId")
        .equalTo(programId)
        .once("value")
        .then(snapshot => {
          const rows = snapshot.val();
          if (rows) {
            setRows(rows);
            const interests = Object.values(rows).map(row => {
              return row;
            });
            let locationInterests = [];
            interests.forEach(interest => {
              const p1 = {
                lat: 36.7992841,
                lng: -119.7286572
              };
              const p2 = {
                lat: interest.loc.latitude,
                lng: interest.loc.longitude
              };
              if (
                getDistance(p1, p2) <
                radiusOptions[radiusSelectedIndex] * 1609.34
              ) {
                locationInterests.push(interest);
              }
            });
            setLocationInterests(locationInterests);
            setInterests(rows);
          }
        });

      app
        .database()
        .ref("interestTypes")
        // .orderByChild("programId")
        // .equalTo(programId)
        .once("value")
        .then(snapshot => {
          const interestTypes = snapshot.val();
          setInterestTypes(interestTypes);
          if (interestTypes) {
            const checks = Object.keys(interestTypes).map(key => {
              return true;
            });
            const types = Object.keys(interestTypes).map(key => {
              return key;
            });
            setLocationChecks(checks);
            setTypeKeys(types);
          }
        });
    }
  }, [showState, programId, open, radiusSelectedIndex, getDistance]);

  const handleLocationCategory = (key, i) => {
    let tempChecks = locationChecks.map(check => {
      return check;
    });
    let tempInterests = [];
    let tempTypes = [];
    const check = tempChecks[i];
    tempChecks[i] = !check;
    setLocationChecks(tempChecks);

    tempChecks.forEach((check, j) => {
      if (check) {
        tempTypes.push(typeKeys[j]);
      }
    });

    Object.keys(interests).forEach((key, i) => {
      const interest = interests[key];
      const p1 = {
        lat: 36.7992841,
        lng: -119.7286572
      };
      const p2 = {
        lat: interest.loc.latitude,
        lng: interest.loc.longitude
      };
      const types = interest.interestTypeKey;
      const totalTypes = types.concat(tempTypes);

      const uniqTypes = [...new Set(totalTypes)];

      if (
        uniqTypes.length !== totalTypes.length &&
        getDistance(p1, p2) < radiusOptions[radiusSelectedIndex] * 1609.34
      ) {
        tempInterests.push(interest);
      }
    });

    setLocationInterests(tempInterests);
  };

  const getInterestTypes = keys => {
    let types = [];
    if (keys) {
      types = keys.map(key => {
        return interestTypes[key] ? interestTypes[key].interestType : null;
      });
    }
    return types.toString();
  };

  const handleLocationSearchChange = searchText => {
    let resInterests = [];
    const maxDistance = radiusOptions[radiusSelectedIndex] * 1609.34;
    Object.keys(interests).forEach(interestId => {
      const interest = interests[interestId];
      // const p1 = {
      //   lat: 34.046682,
      //   lng: -118.262225
      // };
      const p1 = {
        lat: 36.7992841,
        lng: -119.7286572
      };
      const p2 = {
        lat: interest.loc.latitude,
        lng: interest.loc.longitude
      };
      const types = getInterestTypes(interest.interestTypeKey);
      const allString = (
        types +
        " " +
        interest.customer.zip +
        " " +
        interest.customer.city
      ).toLowerCase();

      if (
        allString.includes(searchText.toLowerCase()) &&
        getDistance(p1, p2) < maxDistance
      ) {
        resInterests.push(interest);
      }
    });
    setLocationInterests(resInterests);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = index => {
    setRadiusSelectedIndex(index);

    setOpen(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-11">
          <div className="row inventory_submenu">
            <div className="col-md-3 col-xl-3">
              <h4
                style={{
                  fontSize: "30px",
                  fontWeight: "normal",
                  color: "#111111",
                  marginRight: "36px"
                }}
              >
                Interest list
              </h4>
            </div>
            <div className="col-md-9 col-xl-4">
              <LargeSearchBar placeholder="Search product, category…" />
            </div>
            <div className="col-xl-5">
              <div
                style={{
                  maxWidth: 375,
                  marginTop: 33,
                  float: "right",
                  width: "100%"
                }}
              >
                <WhiteButton
                  handleClick={() => {
                    history.push(`/add-new-interest-type/${programId}`);
                  }}
                  caption="Create Interest Type"
                  addIcon
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-11">
          <div
            className="product_list"
            style={{
              marginTop: "62px",
              background: "white",
              padding: "26px 29px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 25
              }}
            >
              <h5
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#333333",
                  marginBottom: 0
                }}
              >
                All Interests
              </h5>
              <IconButton>
                <MenuIcon />
              </IconButton>
            </div>
            <InterestTable
              rows={rows}
              programId={programId}
              interestTypes={interestTypes}
            />
          </div>
          <h3 style={{ fontSize: 30, color: "#111111", marginTop: 12 }}>
            Interest by location
          </h3>
          <div
            className="row"
            style={{ background: "white", padding: "60px 30px" }}
          >
            {/* <Paper className={classes.root}> */}
            <div className={classes.tableWrapper + " col-md-12"}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#666666",
                          background: "white",
                          borderWidth: 3
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locationInterests.map((interest, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell key={columns[0].id} align={columns[0].align}>
                          <span
                            style={{
                              color: "#555555",
                              fontSize: "14px",
                              fontWeight: "normal"
                            }}
                          >
                            {interest.customer.firstName +
                              " " +
                              interest.customer.lastName}
                          </span>
                        </TableCell>
                        <TableCell key={columns[1].id} align={columns[1].align}>
                          <span
                            style={{
                              color: "#555555",
                              fontSize: "14px",
                              fontWeight: "normal"
                            }}
                          >
                            {interest.customer.address}
                          </span>
                        </TableCell>
                        <TableCell key={columns[2].id} align={columns[2].align}>
                          <span
                            style={{
                              color: "#438AFE",
                              fontSize: "14px",
                              fontWeight: "normal"
                            }}
                          >
                            {interest.customer.city}
                          </span>
                        </TableCell>
                        <TableCell key={columns[3].id} align={columns[3].align}>
                          <span
                            style={{
                              color: "#438AFE",
                              fontSize: "14px",
                              fontWeight: "normal"
                            }}
                          >
                            {interest.customer.state}
                          </span>
                        </TableCell>
                        <TableCell key={columns[4].id} align={columns[4].align}>
                          <span
                            style={{
                              color: "#438AFE",
                              fontSize: "14px",
                              fontWeight: "normal"
                            }}
                          >
                            {interest.customer.zip}
                          </span>
                        </TableCell>
                        <TableCell key={columns[5].id} align={columns[5].align}>
                          <span
                            style={{
                              color: "#555555",
                              fontSize: "14px",
                              fontWeight: "normal"
                            }}
                          >
                            {interest.customer.email}
                            <br />
                            {interest.customer.phone}
                          </span>
                        </TableCell>
                        <TableCell key={columns[6].id} align={columns[6].align}>
                          <span
                            style={{
                              color: "#438AFE",
                              fontSize: "14px",
                              fontWeight: "normal"
                            }}
                          >
                            {getInterestTypes(interest.interestTypeKey)}
                          </span>
                        </TableCell>
                        <TableCell key={columns[7].id} align={columns[7].align}>
                          <span
                            style={{
                              color: "#555555",
                              fontSize: "14px",
                              fontWeight: "normal"
                            }}
                          >
                            {new Date(interest.date).toLocaleDateString()}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            {/* </Paper> */}
            <div className="col-md-6">
              <div
                className="mapOverBar"
                style={{ marginTop: 4, marginBottom: 4, display: "flow-root" }}
              >
                <LargeSearchBar
                  placeholder="Search by Zip Code, City, Interest"
                  style={{
                    height: 38,
                    maxWidth: 273,
                    fontSize: 9,
                    float: "left"
                  }}
                  handleChange={handleLocationSearchChange}
                />
                <div
                  style={{
                    height: 38,
                    borderRadius: 19,
                    maxWidth: 131,
                    float: "right",
                    display: "flex",
                    alignItems: "center",
                    background: "#FAFAFA",
                    paddingLeft: 16,
                    paddingRight: 16
                  }}
                >
                  <span
                    ref={anchorRef}
                    style={{
                      fontSize: 9,
                      fontWeight: "bold",
                      color: "rgba(54, 68, 92, 0.61)",
                      marginRight: 15
                    }}
                  >
                    Radius. {radiusOptions[radiusSelectedIndex]} Mile
                  </span>
                  <MenuIcon onClick={handleToggle} />
                  <Popper
                    style={{ zIndex: 1 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom"
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                              {radiusOptions.map((option, index) => (
                                <MenuItem
                                  key={index}
                                  selected={index === radiusSelectedIndex}
                                  onClick={() => handleMenuItemClick(index)}
                                  style={{ fontSize: "10px" }}
                                >
                                  Radius. {option} Mile
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>
              <div
                style={{
                  background: "#F5F5F5",
                  padding: "10px 20px"
                  // display: "flex",
                  // justifyContent: "center"
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <h5
                    style={{
                      padding: "10px 3px",
                      borderTop: "2px solid #DEDEDE",
                      color: "#666666",
                      fontSize: 14
                    }}
                  >
                    FIND US
                  </h5>
                </div>
                <InterestMap interests={locationInterests} />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="row"
                style={{ padding: 35, display: "flex", alignItems: "center" }}
              >
                <h3 style={{ fontSize: 26, color: "#111111", width: "100%" }}>
                  Interest Type
                </h3>
                {interestTypes
                  ? Object.keys(interestTypes).map((key, i) => {
                      const type = interestTypes[key];
                      return (
                        <div key={i} className="col-sm-6 col-md-4">
                          <LineChartLabel
                            // color={"#EBECEE"}
                            fontColor={"#666666"}
                            title={type.interestType}
                            handleClick={() => handleLocationCategory(key, i)}
                            checked={locationChecks[i]}
                          />
                        </div>
                      );
                    })
                  : null}
                <div
                  style={{
                    width: "100%",
                    marginTop: 40,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <h3 style={{ fontSize: 26, color: "#111111" }}>
                    Export Maps with Interests (PDF)
                  </h3>
                  <IconButton>
                    <MenuIcon style={{ fontSize: 26 }} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
