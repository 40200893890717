import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import RoundTextField from "./RoundTextField";
import RoundTextArea from "./RoundTextArea";
import { SEND_CONTACTUS_EMAIL } from "../components/constants";
import clsx from "clsx";

function ContactUs(props) {
  // let history = useHistory();
  const { intl } = props;

  const [loading, setLoading] = React.useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = React.useState({
    width: "100%",
    borderRadius: "20px",
    height: "50px"
  });

  const enableLoading = () => {
    setLoading(true);
    // setLoadingButtonStyle({
    //   paddingRight: "3.5rem",
    //   height: "50px",
    //   fontWeight: "500",
    //   fonSize: "1rem",
    //   paddingLeft: "2.75rem",
    //   borderRadius: "20px"
    // });
  };

  const disableLoading = () => {
    setLoading(false);
    // setLoadingButtonStyle({
    //   paddingRight: "2.5rem",
    //   height: "50px",
    //   fontWeight: "500",
    //   fonSize: "1rem",
    //   paddingLeft: "2.75rem",
    //   borderRadius: "20px"
    // });
  };

  const sendEmail = values => {
    enableLoading();
    fetch(SEND_CONTACTUS_EMAIL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      },
      body: JSON.stringify(values)
    })
      .then(response => {
        console.log(response);
        disableLoading();
      })
      .catch(err => {
        disableLoading();
        console.log(err);
      });
  };

  return (
    <div className="kt-login__body">
      <div
        className="kt-login__form"
        style={{ paddingLeft: "10%", paddingRight: "10%" }}
      >
        <div className="kt-login__title" style={{ marginBottom: "auto" }}>
          <h1
            style={{
              color: "#4F4F6F",
              fontWeight: "normal",
              fontFamily: "Open Sans",
              fontSize: "48px"
            }}
          >
            {props.title}
          </h1>
          <p
            style={{
              color: "#4CA9F9",
              fontSize: "16px",
              fontFamily: "Open Sans",
              fontWeight: "normal"
            }}
          >
            {props.subtitle}
          </p>
        </div>

        <Formik
          initialValues={{
            fullname: "",
            email: "",
            message: ""
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }

            if (!values.fullname) {
              errors.fullname = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.message) {
              errors.message = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            sendEmail(values);
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}
              <div className="form-group mb-0">
                <RoundTextField
                  type="text"
                  margin="normal"
                  headerLabel="Name"
                  name="fullname"
                  placeholder="Full Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstname}
                  helperText={touched.fullname && errors.fullname}
                  error={Boolean(touched.fullname && errors.fullname)}
                />
              </div>

              <div className="form-group mb-0">
                <RoundTextField
                  type="text"
                  margin="normal"
                  name="email"
                  headerLabel="Email"
                  placeholder="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="form-group mb-0">
                <RoundTextArea
                  margin="normal"
                  name="message"
                  headerLabel="Message"
                  placeholder="Write your message"
                  multiline={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.message}
                  rows={5}
                  helperText={touched.message && errors.message}
                  error={Boolean(touched.message && errors.message)}
                />
              </div>

              <div
                className="kt-login__actions"
                style={{ marginTop: "25px", marginBottom: "25px" }}
              >
                <button
                  // disabled={isSubmitting}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  Send
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, [])(ContactUs));
