import React from 'react';
import { PieChart } from 'react-chartkick';
// import LineChartLabel from "./LineChartLabel";
import CategoryChartLabel from "./CategoryChartLabel";
import "../css/category_chart.scss";

export default function ProgramSalesChart(props) {
    // const data = [
    //     { "name": "Workout", "data": { "2017-01-01": 3, "2017-01-02": 4, "2017-01-03": 7, "2017-01-04": 9, "2017-01-05": 3, } },
    //     { "name": "Call parents", "data": { "2017-01-01": 5, "2017-01-02": 3, "2017-01-03": 4, "2017-01-04": 2, "2017-01-05": 3, } }
    // ];
    const data = props.chartData;
    const colors = props.chartColors;

    const [chartData, setChartData] = React.useState({});
    const [chartColors, setChartColors] = React.useState({});
    const [total, setTotal] = React.useState(0);

    // const handleClick = () => {
        
    // }

    React.useEffect(() => {
        if (Object.keys(data).length > 0 && Object.keys(colors).length > 0) {
            setChartData(data);
            setChartColors(colors);
            let total = 0;
            Object.values(data).forEach(value => { total += Number(value) })
            setTotal(total);
        }
    }, [data, colors])

    return (
        <div style={{ background: "white", borderRadius: "5px", padding: "20px 30px" }}>
            <div className="row programchart_title" style={{
            }}
            >
                <div className="col-xl-7">
                    <h5 style={{ fontSize: "18px", fontWeight: "bold", color: "#333333" }}
                    >
                        MATERIAL DISTRIBUTED BY CATEGORY
                    </h5>
                </div>
                {/* <div className="col-xl-5">
                    <div className="row">
                        <div className="col-6">
                            <LineChartLabel
                                color={"#36435C"}
                                name={"category_language"}
                                title={"English"}
                                fontColor="#333333"
                                fontSize="14px"
                                fontWeight="normal"
                                handleClick={handleClick}
                            />
                        </div>
                        <div className="col-6">
                            <LineChartLabel
                                color={"#36435C"}
                                name={"category_language"}
                                title={"Spanish"}
                                fontColor="#333333"
                                fontSize="14px"
                                fontWeight="normal"
                                handleClick={handleClick}
                            />
                        </div>
                    </div>
                </div> */}

            </div>
            <div style={{ textAlign: "center" }}>
                <h5 style={{ fontSize: "14px", fontWeight: "500", color: "#111111", marginBottom: "4px" }}>TOTAL SALES</h5>
                <h4 style={{ fontSize: "24px", fontWeight: "bold", color: "#111111", fontFamily: "Montserrat", marginBottom: "0" }}>{total}</h4>
                <PieChart
                    id="category_chart"
                    data={chartData}
                    donut={true}
                    width={"210px"}
                    height={"210px"}
                    colors={Object.values(chartColors).map(color => {
                        return `rgba(
                            ${color.r}, 
                            ${color.g}, 
                            ${color.b}, 
                            ${color.a}
                            )`
                    })}
                    legend={false}
                    dataset={{
                        hoverBorderWidth: 10,
                        hoverBorderColor: Object.values(chartColors).map(color => {
                            return `rgba(
                                ${color.r}, 
                                ${color.g}, 
                                ${color.b}, 
                                ${color.a}
                                )`
                        }),
                    }}
                    library={{ cutoutPercentage: 62 }}
                />
            </div>
            {/* <div style={{ textAlign: "right" }}>
                <CategoryChartLabel color={"#9F9C4A"} label={"Dropdowns"} value={"738"} />
            </div> */}
            <div className="row">
                {
                    chartData ?
                        Object.keys(chartData).map((name, i) => {
                            return <div key={i} className="col-md-4">
                                <CategoryChartLabel
                                    color={`rgba(
                                        ${chartColors[name].r}, 
                                        ${chartColors[name].g}, 
                                        ${chartColors[name].b}, 
                                        ${chartColors[name].a}
                                        )`}
                                    label={name}
                                    value={data[name]}
                                />
                            </div>
                        })
                        : null
                }
            </div>
        </div>
    )
}
