import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
import "../css/add_new_product.scss";
import MyDropDownField from "../inventory_component/MyDropDownField";
import Fab from "@material-ui/core/Fab";
import PublishIcon from "@material-ui/icons/Publish";
import { makeStyles } from "@material-ui/core/styles";
import WithHeaderWindow from "../common_component/WithHeaderWindow";
import LineChartLabel from "../dashboard_component/LineChartLabel";
import { toAbsoluteUrl } from "../../../../_metronic";
import CircularIntegration from "../common_component/CircularIntegration";
import { shallowEqual, useSelector } from "react-redux";
import app from "../../../components/base";

const useStyles = makeStyles(theme => ({
  fab: {
    margin: 5,
    background: "#36425C",
    color: "white",
    width: 40,
    height: 40
  }
}));

export default function SettingsProfile() {
  const { currentUser } = useSelector(
    ({ auth }) => ({ currentUser: auth.user }),
    shallowEqual
  );

  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [role, setRole] = useState("");

  const [profileLoading, setProfileLoading] = useState(false);
  const [profileSuccess, setProfileSuccess] = useState(false);

  const [orgLoading, setOrgLoading] = useState(false);
  const [orgSuccess, setOrgSuccess] = useState(false);

  const roleOptions = [];

  useEffect(() => {
    app
      .database()
      .ref(`admins/${currentUser.user.uid}`)
      .once("value", snapshot => {
        const admin = snapshot.val();
        console.log(admin);
        setFirstName(admin.firstname);
        setLastName(admin.lastname);
        setEmail(currentUser.user.email);
        setPhonenumber(admin.phonenumber);
      });
  }, [currentUser]);

  const handleRoleClick = () => {};

  const handleProfileSave = () => {
    if (!profileLoading) {
      setProfileSuccess(false);
      setProfileLoading(true);
    }
    var adminRef = app.database().ref(`admins/${currentUser.user.uid}`);
    adminRef.once("value", snapshot => {
      const password = snapshot.val().password;
      adminRef.update({
        firstname: firstName,
        lastname: lastName,
        phonenumber: phonenumber
      });
      const adminUser = currentUser.user;
      app
        .auth()
        .signInWithEmailAndPassword(adminUser.email, password)
        .then(function(userCredential) {
          userCredential.user.updateEmail(email);
          //   userCredential.user.updatePassword(password);
          setProfileSuccess(true);
          setProfileLoading(false);
        });
      setProfileSuccess(true);
      setProfileLoading(false);
    });
  };

  const handleOrgSave = () => {
    if (!orgLoading) {
      setOrgSuccess(false);
      setOrgLoading(true);
    }
    setOrgSuccess(true);
    setOrgLoading(false);
  };

  return (
    <>
      <WithHeaderWindow
        title="My Profile"
        style={{ marginTop: "25px" }}
        penIcon={true}
      >
        <div
          className="content row"
          style={{
            // border: "1px solid #D4D6D9",
            padding: "40px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px"
            // background: "white"
          }}
        >
          <div className="col-xl-9 col-md-8">
            <Form
              style={{
                paddingRight: 30
              }}
            >
              <Form.Row>
                <Form.Group as={Col} sm="4" controlId="formGridFirstname">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => {
                      setFirstName(e.target.value);
                    }}
                    value={firstName}
                    placeholder="First Name"
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="4"
                  controlId="formGridLastname"
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Form.Label></Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => {
                      setLastName(e.target.value);
                    }}
                    value={lastName}
                    placeholder="Last Name"
                  />
                </Form.Group>
                <Form.Group as={Col} sm="4" controlId="formGridRole">
                  <Form.Label>Role</Form.Label>
                  <MyDropDownField
                    value={role}
                    options={roleOptions}
                    handleClick={handleRoleClick}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm="4" controlId="formGridEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    placeholder=""
                  />
                </Form.Group>
                <Form.Group as={Col} sm="5" controlId="formGridPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    placeholder=""
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  sm="3"
                  controlId="formGridChange"
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Form.Label style={{ marginRight: 20 }}>Change?</Form.Label>
                  <LineChartLabel color={"#EAECEE"} name={""} title={""} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm="4" controlId="formGridPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => {
                      setPhonenumber(e.target.value);
                    }}
                    value={phonenumber}
                    placeholder=""
                  />
                </Form.Group>
                <Form.Group as={Col} sm="5">
                  <Form.Label>Profile Picture</Form.Label>
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid #B8B9BC",
                      borderRadius: "3px",
                      textAlign: "center"
                    }}
                  >
                    <Fab aria-label="Add" className={classes.fab}>
                      <PublishIcon />
                    </Fab>
                  </div>
                </Form.Group>
              </Form.Row>

              {/* <Button
                // type="submit"
                style={{
                  background: "#36435C",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                  width: "216px",
                  height: "44px",
                  borderRadius: "3px"
                }}
                onClick={handleSave}
              >
                Save
              </Button> */}
              <CircularIntegration
                caption="Save"
                loading={profileLoading}
                success={profileSuccess}
                handleButtonClick={handleProfileSave}
              />
            </Form>
          </div>
          <div
            className="col-xl-3 col-md-4"
            style={{
              border: "1px solid rgba(112, 112, 112, 0.57)",
              borderRadius: "5px",
              padding: "20px 14px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <img
                alt="notification"
                width="85px"
                // style={{border: "3px solid white"}}
                className="img-fluid rounded-circle"
                src={toAbsoluteUrl("/media/users/camacho2x.png")}
              />
              <div style={{ marginLeft: "10px" }}>
                <h4
                  style={{ fontSize: 18, fontWeight: "bold", color: "#576377" }}
                >
                  Ricardo Camacho
                </h4>
                <span
                  style={{ fontSize: 18, fontWeight: "500", color: "#576377" }}
                >
                  Admin
                </span>
              </div>
            </div>
            <p
              style={{
                fontSize: 20,
                fontWeight: "normal",
                color: "#707070",
                marginTop: 20
              }}
            >
              rcamacho@cccsda.org
              <br />
              559-234-2122
            </p>
          </div>
        </div>
      </WithHeaderWindow>

      <WithHeaderWindow
        title="My Organization"
        style={{ marginTop: "47px" }}
        penIcon={true}
      >
        <div
          className="content row"
          style={{
            // border: "1px solid #D4D6D9",
            padding: "40px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px"
            // background: "white"
          }}
        >
          <div className="col-xl-9 col-md-8">
            <Form
              style={{
                paddingRight: 30
              }}
            >
              <Form.Row>
                <Form.Group as={Col} sm="8" controlId="formGridOrganization">
                  <Form.Label>Name of Organization</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Central California Conference"
                  />
                </Form.Group>
                <Form.Group as={Col} sm="4" controlId="formGridAbbreviation">
                  <Form.Label>Abbreviation</Form.Label>
                  <Form.Control type="text" placeholder="CCC" />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm="8" controlId="formGridAddress">
                  <Form.Label>Organization Address</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
                <Form.Group as={Col} sm="4" controlId="formGridCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm="4" controlId="formGridState">
                  <Form.Label>State</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
                <Form.Group as={Col} sm="3" controlId="formGridZip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
              </Form.Row>

              {/* <Button
                style={{
                  background: "#36435C",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                  width: "216px",
                  height: "44px",
                  borderRadius: "3px"
                }}
              >
                Save
              </Button> */}
              <CircularIntegration
                caption="Save"
                loading={orgLoading}
                success={orgSuccess}
                handleButtonClick={handleOrgSave}
              />
            </Form>
          </div>
          <div
            className="col-xl-3 col-md-4"
            style={{
              border: "1px solid rgba(112, 112, 112, 0.57)",
              borderRadius: "5px",
              padding: "20px 14px"
            }}
          >
            <h4 style={{ fontSize: 20, fontWeight: "bold", color: "#707070" }}>
              Central California Conference
            </h4>
            <p style={{ fontSize: 20, fontWeight: "normal", color: "#707070" }}>
              CCC <br />
              2820 Willow Ave. <br />
              Clovis, CA 93612
            </p>
          </div>
        </div>
      </WithHeaderWindow>
    </>
  );
}
