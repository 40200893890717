import React, { useState, useEffect } from "react";
import ls from 'local-storage';
import { Container, Card, Button, Table, InputGroup, FormControl } from "react-bootstrap";
import { connect, shallowEqual, useSelector } from "react-redux";
import "../../../_metronic/_assets/sass/pages/login/login_custom.scss";
import PaymentForm from '../PaymentForm';
import * as auth from "../../store/ducks/auth.duck";
import { PAYMENT, SANDBOX_APPID } from "../constants";
import RoundTextField from "../RoundTextField";
import { toAbsoluteUrl } from "../../../_metronic"
import app from "../../components/base";
import "../../pages/css/payment.css";
import "../../pages/css/cuponstyle.css";

const styles = {
    cupon: {
        borderTopLeftRadius: "30px",
        borderBottomLeftRadius: "30px",
        height: "72px",
        textAlign: "center",
        fontSize: "27px",
        fontWeight: "bold",
    },
}

function createObject([name, year, dates]) {
    return { name, year, start_date: dates.split("-")[0].trim(), end_date: dates.split("-")[1].trim() };
}

function AuthBilling(props) {
    const { currentStep, programCount } = useSelector(({ auth }) =>
        ({
            currentStep: auth.activeStep,
            programCount: auth.programCount
        }),
        shallowEqual
    );

    const nameObj = ls.get('youthrush_signup_info');
    const orgObj = ls.get('youthrush_org');
    const program_storage_values = ls.get('youthrush_program_values');
    const program_values = program_storage_values ? Object.values(program_storage_values) : null;

    const programs = [];

    if (program_values != null) {
        for (var i = 0; i < program_values.length / 3; i++) {
            programs.push(createObject(program_values.slice(i * 3, i * 3 + 3)));
        }
    }

    const yearlySubscription = 99;
    const programCost = 49 * programCount;
    const subTotal = yearlySubscription + programCost;
    const tax = Math.round(subTotal * 7.975) / 100;
    const total = subTotal + tax;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        let sqPaymentScript = document.createElement('script');
        sqPaymentScript.src = "https://js.squareupsandbox.com/v2/paymentform";
        sqPaymentScript.type = "text/javascript"
        sqPaymentScript.async = false;
        sqPaymentScript.onload = () => {
            setLoaded(true);
        };
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    }, []);

    const handleCupon = () => {
        props.setActiveStep(currentStep + 1);
        app.auth().onAuthStateChanged(function (user) {
            if (user != null) {
                var adminsRef = app.database().ref().child("admins");
                adminsRef.child(user.uid).update({ "subflag": true });

                let update_data = {};
                var programsRef = app.database().ref().child("programs");
                programs.forEach((program) => {
                    program.adminId = user.uid;
                    const key = programsRef.push(program).key;
                    if (!update_data[program.year]) {
                        update_data[program.year] = [];
                    }
                    update_data[program.year].push(key);
                })
                Object.keys(update_data).forEach((year) => {
                    var updateRef = adminsRef.child(user.uid + "/programIds/" + year);
                    if (updateRef) {
                        updateRef.once('value', snapshot => {
                            if (snapshot.val()) {
                                const arr = [...snapshot.val(), ...update_data[year]];
                                updateRef.set(arr);
                            } else {
                                updateRef.set(update_data[year]);
                            }
                        })
                    }
                })
            } else {
                // this.name = "Unknown";
                // alert("Login failed");
            }
        });

    }

    const backFunction = () => {
        props.setActiveStep(currentStep - 1);
    }

    const handleSquare = () => {
        props.setActiveStep(currentStep + 1);
        app.auth().onAuthStateChanged(function (user) {
            if (user != null) {
                var adminsRef = app.database().ref().child("admins");
                adminsRef.child(user.uid).update({ "subflag": true });

                let update_data = {};
                var programsRef = app.database().ref().child("programs");
                programs.forEach((program) => {
                    program.adminId = user.uid;
                    const key = programsRef.push(program).key;
                    if (!update_data[program.year]) {
                        update_data[program.year] = [];
                    }
                    update_data[program.year].push(key);
                })
                Object.keys(update_data).forEach((year) => {
                    var updateRef = adminsRef.child(user.uid + "/programIds/" + year);
                    if (updateRef) {
                        updateRef.once('value', snapshot => {
                            if (snapshot.val()) {
                                const arr = [...snapshot.val(), ...update_data[year]];
                                updateRef.set(arr);
                            } else {
                                updateRef.set(update_data[year]);
                            }
                        })
                    }
                })
            } else {
                // this.name = "Unknown";
                // alert("Login failed");
            }
        });
    }

    // const handleForceSignOut = () => {
    //     props.logout();
    // }

    return (
        <>
            <div className="" style={{ marginTop: "70px", paddingBottom: "79px" }}>
                <div
                    id="kt_login"
                    style={{ background: "white" }}
                >
                    <Container>
                        <div
                            className="row"
                        >
                            <div className="col-md-6">
                                <Card style={{ border: "none" }}>
                                    <Card.Body>
                                        <Card.Title
                                            style={{
                                                fontSize: "25px",
                                                fontWeight: "bold",
                                                fontFamily: "Open Sans",
                                                color: "#959595"
                                            }}
                                        >
                                            {nameObj.firstname + " " + nameObj.lastname}
                                        </Card.Title>
                                        <Card.Text
                                            style={{
                                                fontSize: "25px",
                                                fontWeight: "normal",
                                                fontFamily: "Open Sans",
                                                color: "#959595"
                                            }}
                                        >
                                            {orgObj ? orgObj.orgname : null}<br />
                                            {orgObj ? orgObj.orgaddress1 : null}<br />
                                            {orgObj ? orgObj.orgaddress2 : null}<br />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <div style={{ marginTop: "47px" }}>
                                    <div className="form-group mb-0">
                                        <RoundTextField
                                            type="text"
                                            margin="normal"
                                            headerLabel="Name on Card"
                                            name="cardname"
                                            placeholder="Name on Card"
                                        // onBlur={handleBlur}
                                        // onChange={handleChange}
                                        // value={values.cardname}
                                        // helperText={touched.cardname && errors.cardname}
                                        // error={Boolean(touched.cardname && errors.cardname)}
                                        />
                                        <label style={{
                                            fontSize: "14px", fontWeight: "500", fontFamily: "Open Sans", color: "black"
                                        }}>Credit Card</label>
                                    </div>
                                    {loaded ?
                                        <PaymentForm
                                            paymentForm={window.SqPaymentForm}
                                            handleSquare={handleSquare}
                                            price={total}
                                            paymentUrl={PAYMENT}
                                            appId={SANDBOX_APPID}
                                            backFunction={backFunction}
                                        /> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 style={{
                                    marginTop: "44px",
                                    fontSize: "27px",
                                    fontFamily: "Open Sans",
                                    fontWeight: "bold",
                                    color: "#3692EE",
                                    padding: "17px 40px",
                                    background: "rgba(242, 239, 239, .47)",
                                    border: "2px solid #EBEBEB",
                                    borderRadius: "25px"
                                }}
                                >
                                    Add Different Billing
                                    <span style={{ fontSize: "36px", fontWeight: "normal", marginTop: "18px", fontFamily: "Open Sans", color: "#959595", padding: "0 23px" }}>+</span>
                                </h3>
                                <Card style={{ marginTop: "30px", textAlign: "center", borderRadius: "20px", backgroundColor: "rgba(242, 239, 239, .47)" }}>
                                    <Card.Body>
                                        <Card.Title style={{ color: "#3692EE", fontFamily: "Open Sans", fontSize: "27px", fontWeight: "bold" }}>Order Overview</Card.Title>
                                        <Table responsive style={{ color: "#959595", fontSize: "25px", fontFamily: "Open Sans", fontWeight: "normal" }}>
                                            <tbody>
                                                <tr>
                                                    <td>Yearly Subscription</td>
                                                    <td>${yearlySubscription}</td>
                                                </tr>
                                                <tr>
                                                    <td>Programs({programCount}) $49 each</td>
                                                    <td>${programCost}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td>${subTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sales Tax(7.975%)</td>
                                                    <td>${tax}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr style={{ fontSize: "28px", fontWeight: "bold" }}>
                                                    <td>Total</td>
                                                    <td>${total}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <InputGroup className="mb-3" style={{ borderRadius: "20px" }}>
                                            <FormControl
                                                placeholder="Coupon Code"
                                                aria-label="Coupon Code"
                                                aria-describedby="basic-addon2"
                                                style={styles.cupon}
                                                id="cupon_input"
                                            />
                                            <InputGroup.Append>
                                                <Button variant="primary"
                                                    style={{
                                                        borderTopRightRadius: "30px",
                                                        borderBottomRightRadius: "30px",
                                                        height: "72px",
                                                        paddingLeft: "20px",
                                                        paddingRight: "20px",
                                                        fontSize: "27px",
                                                        fontWeight: "bold",
                                                        fontFamily: "Open Sans"
                                                    }}
                                                    onClick={handleCupon}
                                                >
                                                    Apply
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Card.Body>
                                </Card>

                            </div>
                        </div>
                    </Container>
                </div>
                <div style={{ marginTop: "290px" }}>
                    <img
                        alt="bottom-logo"
                        className="img-fluid"
                        style={{ maxWidth: "348px", marginRight: "10%", float: "right" }}
                        src={toAbsoluteUrl("/media/blog/stripe-cc-payments1.png")}
                    />
                </div>
            </div>
        </>
    );
}


export default connect(
    null,
    auth.actions
)(AuthBilling);
