import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SalesTotal from "./sales_component/SalesTotal";
import SalesLeaders from "./sales_component/SalesLeaders";
import SalesStudents from "./sales_component/SalesStudents";
import SalesWeekly from "./sales_component/SalesWeekly";
import SalesInventory from "./sales_component/SalesInventory";
import SalesTransactions from "./sales_component/SalesTransactions";
import SalesMaps from "./sales_component/SalesMaps";
import app from "../../components/base";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "none",
        // backgroundColor: theme.palette.background.paper,
    },
}));

export default function Settings() {
    const { programId } = useParams();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [teams, setTeams] = useState([]);
    const [transactions, setTransactions] = useState({});
    const [teamUsers, setTeamUsers] = useState({});
    const [programInfo, setProgramInfo] = useState({});
    const [fetchState, setFetchState] = useState(false);

    useEffect(() => {
        if (!fetchState) {
            setFetchState(true);
            app.database().ref().child(`programs/${programId}`).once('value')
                .then(programSnap => {
                    setProgramInfo(programSnap.val());
                    if (programSnap.val().teams) {
                        const teams = programSnap.val().teams.today;
                        setTeams(teams);
                        let myTransactions = {};
                        // let myTeamUsers = {};
                        app.database().ref('users').once('value', snapshot => {
                            const users = snapshot.val();
                            setTeamUsers(users);
                        })
                        teams.forEach(team => {
                            Object.keys(team).forEach(key => {
                                app.database().ref('transactions').orderByChild('teamId').equalTo(key).once('value', transactionSnap => {
                                    const teamTransactions = transactionSnap.val();
                                    myTransactions[key] = teamTransactions;
                                    setTransactions(myTransactions);
                                    // if (teamTransactions) {
                                    //     Object.keys(teamTransactions).forEach(tranKey => {
                                    //         const transaction = teamTransactions[tranKey];
                                    //         const userId = transaction.userId;
                                    //         app.database().ref().child(`users/${userId}`).once('value', snapshot => {
                                    //             myTeamUsers[snapshot.key] = snapshot.val();
                                    //             setTeamUsers(myTeamUsers);
                                    //         })
                                    //     })
                                    // }
                                })
                                // app.database().ref('users').orderByChild('teamId').equalTo(key).once('value', snapshot => {
                                //     const users = snapshot.val();
                                //     if (users) {
                                //         Object.keys(users).forEach(key => {
                                //             myTeamUsers[key] = users[key]
                                //             setTeamUsers(myTeamUsers);
                                //         })
                                //     }
                                // })
                            })
                        })
                    }
                })
        }

    }, [fetchState, programId])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="row inventory_submenu">
                <h4
                    style={{
                        fontSize: "30px",
                        fontWeight: "normal",
                        color: "#111111",
                        marginRight: "36px"
                    }}
                >
                    Sales
      </h4>
            </div>
            <div className={classes.root}>
                <AppBar position="static" style={{ background: "none", color: "inherit", boxShadow: "none" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="settings" variant="scrollable">
                        <Tab label="Total" {...a11yProps(0)} />
                        <Tab label="Leaders" {...a11yProps(1)} />
                        <Tab label="Students" {...a11yProps(2)} />
                        <Tab label="Weekly" {...a11yProps(3)} />
                        <Tab label="Inventory" {...a11yProps(4)} />
                        <Tab label="Transactions" {...a11yProps(5)} />
                        <Tab label="Maps" {...a11yProps(6)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <SalesTotal teams={teams} transactions={transactions} users={teamUsers} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SalesLeaders
                        teams={teams}
                        transactions={transactions}
                        users={teamUsers}
                        programInfo={programInfo}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <SalesStudents />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <SalesWeekly />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <SalesInventory />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <SalesTransactions />
                </TabPanel>
                <TabPanel value={value} index={6}>
                    <SalesMaps />
                </TabPanel>
            </div>
        </>
    );
}