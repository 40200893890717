import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import RoundTextField from "../../components/RoundTextField";
import app from '../../components/base';

class ForgotPassword extends Component {
  state = { isRequested: false };

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;

    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form" style={{ padding: "120px 10%" }}>
            <div className="kt-login__title" style={{ marginBottom: "40px" }}>
              <h1 style={{ color: "#4F4F6F", fontWeight: "400", fontFamily: "Open Sans", fontSize: "40px" }}>
                <FormattedMessage id="AUTH.FORGOT.TITLE" />
              </h1>
            </div>

            <Formik
              initialValues={{ email: "" }}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD"
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                app.auth().sendPasswordResetEmail(values.email)
                  .then(() => {
                    setSubmitting(false);
                    setStatus("Password reset email sent successfully! Please check your email.");
                  })
                  .catch(error => {
                    setSubmitting(false);
                    setStatus(error.message);
                  })
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                  <form onSubmit={handleSubmit} className="kt-form">
                    {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}

                    <div className="form-group">
                      <RoundTextField
                        type="email"
                        headerLabel="Email"
                        margin="normal"
                        placeholder="Your email address"
                        fullWidth={true}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </div>

                    <div className="kt-login__actions"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "3rem 0"
                      }}>
                      <Link to="/auth">
                        <button
                          type="button"
                          className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                          style={{
                            height: "50px",
                            paddingLeft: "2.75rem",
                            paddingRight: "2.75rem",
                            borderRadius: "20px"
                          }}
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary btn-elevate kt-login__btn-primary"
                        disabled={isSubmitting}
                        style={{
                          height: "50px",
                          paddingLeft: "2.75rem",
                          paddingRight: "2.75rem",
                          borderRadius: "20px"
                        }}
                      >
                        Submit
                    </button>
                    </div>
                  </form>
                )}
            </Formik>
          </div>
        </div>
      </div >
    );
  }
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(ForgotPassword)
);
