import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LargeSearchBar from './common_component/LargeSearchBar';
import WhiteButton from "./common_component/WhiteButton";
// import SelectTableEntryCountBar from "./inventory_component/SelectTableEntryCountBar";
import UserTable from "./program_component/UserTable";
import { GET_USER } from "../../components/constants";

export default function Users() {
    const { programId } = useParams();
    const history = useHistory();

    const [rows, setRows] = useState({});
    const USER_URL = GET_USER + "/" + programId;

    useEffect(() => {
        const abortController = new AbortController();

        fetch(USER_URL, {
            method: "GET", 
            signal: abortController.signal
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                setRows(JSON.parse(data));
            })

        return () => {
            abortController.abort();
        }
    }, [USER_URL])

    return (
        <div className="row">
            <div className="col-xl-9">
                <div className="row inventory_submenu">
                    <div className="col-md-3">
                        <h4
                            style={{
                                fontSize: "30px",
                                fontWeight: "normal",
                                color: "#111111",
                                marginRight: "36px"
                            }}
                        >
                            Users
                    </h4>
                    </div>
                    <div className="col-md-9">
                        <LargeSearchBar placeholder="Search students, leaders…" />
                    </div>
                </div>

                <div className="row button_bar" style={{ marginTop: "43px" }}>
                    <div className="col-sm-4">
                        <WhiteButton caption="View All Users" />
                    </div>
                    <div className="col-sm-4">
                        <WhiteButton caption="Add New User" handleClick={() => { history.push(`/add-new-user/${programId}`) }} addIcon />
                    </div>
                </div>

                <div className="user_list" style={{ marginTop: "62px", background: "white", padding: "26px 29px" }}>
                    {/* <h5 style={{ fontSize: "14px", fontWeight: "bold", color: "#333333", marginBottom: "30px" }}>Product Listing</h5>
                    <SelectTableEntryCountBar /> */}
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
                        <h5 style={{ fontSize: "14px", fontWeight: "bold", color: "#333333", marginBottom: 0 }}>All Users</h5>
                        <IconButton>
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <UserTable rows={rows} />
                </div>
            </div>
        </div>
    )
}
