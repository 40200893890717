import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

export default function SalesTotalYesterday(props) {
    const yesterdayAmount = props.yesterdayAmount;
    const totalStudents = props.totalStudents;
    const yesterdayAverage = yesterdayAmount / totalStudents;
    return (
        <div className="row" style={{ marginTop: 20, padding: "0 10px", marginBottom: 36 }}>
            <div style={{ width: "50%" }}>
                <div style={{
                    background: "rgba(54, 68, 92, 0.83)",
                    paddingTop: 3, 
                    paddingBottom: 3, 
                    paddingLeft: 33,
                    paddingRight: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <span
                        style={{
                            fontSize: "22px",
                            fontWeight: "500",
                            color: "white"
                        }}
                    >
                        Yesterdays Average</span>

                    <IconButton style={{ float: "right" }}>
                        <MenuIcon style={{ color: "white", fontSize: "25px" }} />
                    </IconButton>
                </div>
                <div
                    style={{ background: "white" }}
                >
                    <p
                        style={{
                            fontSize: 40,
                            fontWeight: 500,
                            color: "#36445C",
                            textAlign: "center",
                            padding: "34px 0",
                            marginBottom: 0
                        }}
                    >${yesterdayAverage.toFixed(2)}</p>
                </div>
            </div >
            <div style={{ width: "50%" }}>
                <div style={{
                    background: "rgba(54, 68, 92, 0.83)",
                    paddingTop: 3, 
                    paddingBottom: 3, 
                    paddingLeft: 33,
                    paddingRight: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <span
                        style={{
                            fontSize: "22px",
                            fontWeight: "500",
                            color: "white"
                        }}
                    >
                        Yesterdays Sales</span>

                    <IconButton style={{ float: "right" }}>
                        <MenuIcon style={{ color: "white", fontSize: "25px" }} />
                    </IconButton>
                </div>
                <div
                    style={{ background: "white" }}
                >
                    <p
                        style={{
                            fontSize: 40,
                            fontWeight: 500,
                            color: "#36445C",
                            textAlign: "center",
                            padding: "34px 0",
                            marginBottom: 0
                        }}
                    >${yesterdayAmount.toFixed(2)}</p>
                </div>
            </div >
        </div>
    );
}