import React from 'react';
import WithHeaderWindow from "../common_component/WithHeaderWindow";
// import AllProgramsTable from "./AllProgramsTable";
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import "../css/add_new_product.scss";
import MyDropDownField from "../inventory_component/MyDropDownField";
// import Fab from '@material-ui/core/Fab';
// import PublishIcon from '@material-ui/icons/Publish';
// import { makeStyles } from '@material-ui/core/styles';
// import LineChartLabel from "../dashboard_component/LineChartLabel";
// import { toAbsoluteUrl } from "../../../../_metronic";

export default function SettingsProgram() {
    return (
        <div className="row">
            <div className="col-xl-9">
                <WithHeaderWindow title="How can we help?" style={{ marginTop: "25px" }} penIcon={true}>
                    <div
                        className="content row"
                        style={{
                            // border: "1px solid #D4D6D9",
                            padding: "40px",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px",
                            // background: "white"
                        }}
                    >
                        <div
                            className="col-xl-9 col-md-8"
                        >
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridFirstName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="First Name" />
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridLastName" style={{ display: "flex", alignItems: "flex-end" }}>
                                        <Form.Label></Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridEmail">
                                        <Form.Label>E-Mail</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridPhoneNumber">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} sm="12" xl="10" controlId="formGridissue">
                                        <Form.Label>What is the issue?</Form.Label>
                                        <Form.Control as="textarea" rows="15" placeholder="" style={{height: "auto"}} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridPriority">
                                        <Form.Label>Priority</Form.Label>
                                        <MyDropDownField />
                                    </Form.Group>
                                </Form.Row>
                                <Button
                                    style={{
                                        background: "#36435C",
                                        color: "white",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        width: "216px",
                                        height: "44px",
                                        borderRadius: "3px",
                                        marginTop: 50
                                    }}
                                >
                                    Send
                                </Button>
                            </Form>
                        </div>

                    </div>
                </WithHeaderWindow>

            </div>
        </div >
    );
}