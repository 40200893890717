import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
// const app = firebase.initializeApp({
//     apiKey: "AIzaSyB9op9HjkM0_mRkIMP8iH1w5Y8QIyFbybQ",
//     authDomain: "youthrush-11c7e.firebaseapp.com",
//     databaseURL: "https://youthrush-11c7e.firebaseio.com",
//     projectId: "youthrush",
//     storageBucket: "youthrush.appspot.com",
//     messagingSenderId: "544776462880",
//     appId: "1:544776462880:web:1c49b08cf51d51e900d245"
// });

const app = firebase.initializeApp({
    apiKey: "AIzaSyBR7FaDfjQClBIDft-ER8IuPp4s58kaaI4",
    authDomain: "youth-rush-web-app.firebaseapp.com",
    databaseURL: "https://youth-rush-web-app.firebaseio.com",
    projectId: "youth-rush-web-app",
    storageBucket: "youth-rush-web-app.appspot.com",
    messagingSenderId: "884879205856",
    appId: "1:884879205856:web:cc4346e7c44441f6ef7785",
    measurementId: "G-QR8T6NX4G7"
});

export default app;