import React, { useEffect } from 'react';
// import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

export default function MenuListComposition(props) {
    const options = props.options;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [showState, setShowState] = React.useState(false);
    const anchorRef = React.useRef(null);

    useEffect(() => {
        if (options.length > 0) {
            if (!showState) {
                setShowState(true);
                setTitle(options[0]);
            }
        }
    }, [options, showState])
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleMenuItem = (event, i) => {
        setTitle(options[i]);
        setSelectedIndex(i);
        props.handleProgramClick(i);
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div
                style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
            >
                <h4
                    ref={anchorRef}
                    style={{ fontSize: "20px", fontWeight: "bold", color: "#707070" }}>{title}</h4>
                {/* <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                > */}
                <IconButton

                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <MenuIcon />
                </IconButton>
                {/* </Button> */}
                <Popper style={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {
                                            options.map((option, i) => {
                                                return <MenuItem
                                                    key={i}
                                                    style={{
                                                        fontSize: "20px",
                                                        fontWeight: "500",
                                                        background: selectedIndex === i ? "rgba(0, 0, 0, 0.1)" : null
                                                    }}
                                                    onClick={(event) => handleMenuItem(event, i)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            })
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}