import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export default function StatisticsSlider(props) {
    const PrettoSlider = withStyles({
        root: {
            // color: '#52af77',
            height: 4,
        },
        thumb: {
            height: 11,
            width: 11,
            backgroundColor: '#36435C',
            border: '1px solid #707070',
            marginTop: -4,
            marginLeft: -4,
            '&:focus,&:hover,&$active': {
                boxShadow: 'inherit',
            },
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 4,
            borderRadius: 4,
        },
        rail: {
            height: 4,
            borderRadius: 4,
            opacity: 1
        },
    })(Slider);

    return (
        <div>
            <h3>{props.title}</h3>
            <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={props.value} />
        </div>
    )
}
