import React from 'react';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';


export default function MyCustomRadio(props) {
    // const [radioCheck, setRadioCheck] = useState(false);

    // const handleClick = () => {
    //     setRadioCheck(!radioCheck);
    // }

    const CustomRadio = withStyles({
        root: {
            color: props.color,
            '&$checked': {
                color: props.color,
            },
        },
        checked: {},
    })(attrs => <Radio checked={props.checked} color="default" {...attrs} />);

    return (
        <CustomRadio />
    )
}
