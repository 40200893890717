
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function CircularIntegration(props) {
    const classes = useStyles();
    // const [loading, setLoading] = React.useState(false);
    // const [success, setSuccess] = React.useState(false);
    // const timer = React.useRef();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: props.success,
    });

    React.useEffect(() => {
        return () => {
            // clearTimeout(timer.current);
        };
    }, []);

    // function handleButtonClick() {
    //     if (!props.loading) {
    //         props.setSuccess(false);
    //         props.setLoading(true);
    //         timer.current = setTimeout(() => {
    //             props.setSuccess(true);
    //             props.setLoading(false);
    //         }, 2000);
    //     }
    // }

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Fab
                    aria-label="Save"
                    color="primary"
                    className={buttonClassname}
                    onClick={props.handleButtonClick}
                >
                    {props.success ? <CheckIcon /> : <SaveIcon />}
                </Fab>
                {props.loading && <CircularProgress size={68} className={classes.fabProgress} />}
            </div>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    // color="primary"
                    className={buttonClassname}
                    disabled={props.loading}
                    onClick={props.handleButtonClick}
                    style={{
                        background: "#36435C",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "bold",
                        width: "216px",
                        height: "44px",
                        borderRadius: "3px"
                    }}
                >
                    {props.caption}
                </Button>
                {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </div>
    );
}
