import React from "react";

const styles = {
    position: "fixed", 
    cursor: "pointer", 
    bottom: "30px", 
    right: "30px", 
    color: "#ffffff", 
    backgroundColor: "rgba(130, 183, 0, 0.3)", 
    zIndex: 1, 
    width: "45px", 
    textAlign: "center", 
    height: "45px", 
    borderRadius: "10px", 
    lineHeight: "46px", 
    WebkitTransition: "0.5s", 
    transition: "0.5s", 
}

export default class GoTop extends React.Component {
    constructor(){
        super();
        this.state = {
            intervalId: 0,
            thePosition: false
        };
   
    }

    componentDidMount() {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                this.setState({ thePosition: true })
            } else {
                this.setState({ thePosition: false })
            }
        });
        window.scrollTo(0, 0);
    }
    
    onScrollStep = () => {
        if (window.pageYOffset === 0){
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
        let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    renderGoTopIcon = () => {
        if (this.state.thePosition){
            return (
                <div className="go-top" style={styles} onClick={this.scrollToTop}>
                    ↑
                </div>
            )
        }
    }

    render(){
        return (
            <React.Fragment>
                {this.renderGoTopIcon()}
            </React.Fragment>
        )
    }
}
