import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import { useParams } from "react-router";
import CircularIntegration from "./common_component/CircularIntegration";
import WithHeaderWindow from "./common_component/WithHeaderWindow";
import FormDateRangePicker from "./program_component/FormDateRangePicker";
import app from "../../components/base";

export default function ProgramSettings() {
  const { programId } = useParams();

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalGoals, setTotalGoals] = useState("");
  const [program, setProgram] = useState({});

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showState, setShowState] = useState(false);

  useEffect(() => {
    if (!showState) {
      setShowState(true);

      app
        .database()
        .ref(`programs/${programId}`)
        .once("value", programSnap => {
          const program = programSnap.val();
          setProgram(program);
          setName(program.name);
          setStartDate(program.start_date);
          setEndDate(program.end_date);
          if (program.totalGoals) {
            setTotalGoals(program.totalGoals);
          }
        });
    }
  }, [showState, programId]);

  const handleSubmit = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
    }

    const currentProgram = program;
    currentProgram.name = name;
    currentProgram.start_date = startDate;
    currentProgram.end_date = endDate;
    currentProgram.totalGoals = totalGoals;

    app
      .database()
      .ref(`programs/${programId}`)
      .update(currentProgram);

    setSuccess(true);
    setLoading(false);
  };

  const handleDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-9">
          <WithHeaderWindow
            title="Program Setting"
            style={{ marginTop: "47px" }}
            penIcon={true}
          >
            <div
              className="content row"
              style={{
                // border: "1px solid #D4D6D9",
                padding: "40px",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px"
                // background: "white"
              }}
            >
              <div className="col-xl-12 col-md-12">
                <Form
                  style={{
                    paddingRight: 30
                  }}
                >
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      sm="8"
                      controlId="formGridOrganization"
                    >
                      <Form.Label>Name of Program</Form.Label>
                      <Form.Control
                        value={name}
                        type="text"
                        placeholder=""
                        onChange={event => {
                          setName(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} sm="8" controlId="formGridAddress">
                      <Form.Label>Duration of Program</Form.Label>
                      {startDate && endDate ? (
                        <FormDateRangePicker
                          defaultValue={startDate + "-" + endDate}
                          handleRange={(start, end) =>
                            handleDateRange(start, end)
                          }
                        />
                      ) : null}
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} sm="8" controlId="formGridTotalGoals">
                      <Form.Label>Total Goals</Form.Label>
                      <Form.Control
                        value={totalGoals}
                        type="text"
                        placeholder=""
                        onChange={event => {
                          setTotalGoals(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <CircularIntegration
                    caption="Save"
                    loading={loading}
                    success={success}
                    handleButtonClick={handleSubmit}
                  />
                </Form>
              </div>
            </div>
          </WithHeaderWindow>
        </div>
      </div>
    </>
  );
}
