import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, IconButton, CardContent } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import "./css/new_program_card.scss"


const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
        background: "white",
        color: "#36425C",
    },
}));


export default function NewProgramCard() {
    const history = useHistory();
    const classes = useStyles();
    return (
        <div className="new_program_card" style={{ maxWidth: "375px" }}>
            <Card
                style={{
                    paddingTop: "19px",
                    paddingBottom: "30px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    background: "linear-gradient(110deg, #3D62B1, 90%, #16388E)"
                }}
            >
                <CardHeader
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        paddingBottom: "9px"
                    }}

                    action={
                        <IconButton aria-label="Settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title="Purchase New Program"
                    titleTypographyProps={{ variant: 'h4' }}
                />

                <CardContent
                    style={{
                        padding: "9px 35px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <span
                        style={{
                            fontSize: "28px",
                            fontWeight: "bold",
                            color: "#E4E4E4",
                        }}
                    >$49 Each</span>
                    <button
                        style={{
                            padding: "3px 19px",
                            color: "#36415C",
                            fontSize: "28px",
                            fontWeight: "bold",
                            background: "white",
                            borderRadius: "3px",
                            border: "none",
                        }}
                    >
                        1
                    </button>

                    <Fab aria-label="Add" className={classes.fab} onClick={() => { history.push("/purchase-new-program") }}>
                        <AddIcon />
                    </Fab>
                </CardContent>
            </Card>

        </div>
    )
}
