import React from 'react';
import WithHeaderWindow from "../common_component/WithHeaderWindow";
import AllProgramsTable from "./AllProgramsTable";
import { Form, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import "../css/add_new_product.scss";
import MyDropDownField from "../inventory_component/MyDropDownField";
import { toAbsoluteUrl } from "../../../../_metronic";

export default function SettingsProgram() {
    return (
        <div className="row">
            <div className="col-xl-9" style={{marginTop: 25}}>
                <WithHeaderWindow title="All Programs">
                    <AllProgramsTable />
                </WithHeaderWindow>
                <WithHeaderWindow title="My Profile" style={{ marginTop: "30px" }} penIcon={true}>
                    <div
                        className="content row"
                        style={{
                            // border: "1px solid #D4D6D9",
                            padding: "40px",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px",
                            // background: "white"
                        }}
                    >
                        <div
                            className="col-xl-9 col-md-8"
                        >
                            <Form
                                style={{
                                    paddingRight: 30
                                }}
                            >
                                <Form.Row>
                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridProgramName">
                                        <Form.Label>Program Name</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridProgramYear">
                                        <Form.Label>Program Year</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridProgramGoal">
                                        <Form.Label>Program Goal</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridWeeks">
                                        <Form.Label>How many weeks?</Form.Label>
                                        <MyDropDownField />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridPro">
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" xl="5" controlId="formGridWeeks">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <img
                                                alt="notification"
                                                width="94px"
                                                style={{ border: "3px solid #AAAAAA" }}
                                                className="img-fluid rounded-circle"
                                                src={toAbsoluteUrl("/media/users/user5.jpg")}
                                            />
                                            <div style={{ marginLeft: "10px" }}>
                                                <h4
                                                    style={{ fontSize: 22, fontWeight: "regular", color: "#36435C" }}
                                                >Program Head</h4>
                                                <span
                                                    style={{ fontSize: 14, fontWeight: "500", color: "#333333" }}
                                                >Maggie Hernandez</span>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form.Row>


                                <Button
                                    style={{
                                        background: "#36435C",
                                        color: "white",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        width: "216px",
                                        height: "44px",
                                        borderRadius: "3px",
                                        marginTop: 60
                                    }}
                                >
                                    Send
                                </Button>
                            </Form>

                        </div>
                        <div
                            className="col-xl-3 col-md-4"
                            style={{
                                border: "1px solid rgba(112, 112, 112, 0.57)",
                                borderRadius: "5px",
                                padding: "20px 14px",
                                textAlign: "center"
                            }}
                        >

                            <h3
                                style={{ fontSize: 30, fontWeight: "bold", color: "#111111" }}
                            >
                                Sonoma 2019
                            </h3>
                            <p
                                style={{ fontSize: 20, fontWeight: "normal", color: "#111111" }}
                            >
                                Goal: $100,000
                            </p>

                            <div style={{
                                marginTop: 140, 
                                display: "flex", 
                                alignItems: "center", 
                                justifyContent: "center"
                            }}>
                                <div
                                    style={{
                                        width: 104, 
                                        height: 83, 
                                        marginRight: 20, 
                                        borderRadius: 5, 
                                        padding: "12px 17px", 
                                        background: "rgba(87, 99, 119, 0.31)"
                                    }}
                                >
                                    <p
                                        style={{ fontSize: 10, fontWeight: "bold", color: "#111111" }}
                                    >Total Leaders</p>
                                    <span
                                        style={{ fontSize: 24, fontWeight: "bold", color: "#576377" }}
                                    >4</span>
                                </div>
                                <div
                                    style={{
                                        width: 104, 
                                        height: 83, 
                                        borderRadius: 5, 
                                        padding: "12px 17px", 
                                        background: "rgba(87, 99, 119, 0.31)"
                                    }}
                                >
                                    <p
                                        style={{ fontSize: 10, fontWeight: "bold", color: "#111111" }}
                                    >Total Students</p>
                                    <span
                                        style={{ fontSize: 24, fontWeight: "bold", color: "#576377" }}
                                    >18</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </WithHeaderWindow>

            </div>
        </div >
    );
}