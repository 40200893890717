// const baseUrl = "http://localhost:5000";
const baseUrl = "https://youth-rush-web-app.firebaseapp.com";


export const PAYMENT = baseUrl + "/payment";
export const SIGNUP_PAYMENT = baseUrl + "/process-payment";
export const PROGRAM_PAYMENT = baseUrl + "/program-payment";
export const GET_CATEGORY = baseUrl + "/categories";
export const GET_PRODUCT = baseUrl + "/products";
export const GET_USER = baseUrl + "/users";
export const GET_ONE_USER = baseUrl + "/user";


export const SEND_TRANSACTION_EMAIL = baseUrl + "/send-transaction-email";
export const SEND_INTEREST_EMAIL = baseUrl + "/send-interest-email";
export const SEND_RESET_PASSWORD_EMAIL = baseUrl + "/send-reset-password-email";
export const SEND_CONTACTUS_EMAIL = baseUrl + "/send-contactus-email";

export const SANDBOX_APPID = "sandbox-sq0idb-FLRYo7fFJw0xK2g3MhoJ8Q";

// export const GOOGLE_MAP_API_KEY = "AIzaSyCmRnMA4rniMgY0giUjkUjXVZO11zdQfHg";
export const GOOGLE_MAP_API_KEY = "AIzaSyDAjb-rBbnqQPZhcSlNlRz7_6p2CbgNJGk";