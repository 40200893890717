import React from 'react';
import { LineChart } from 'react-chartkick';
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LineChartLabel from "./LineChartLabel";
import "../css/program_sales_chart.scss";
import 'chart.js'

export default function ProgramSalesChart(props) {
    const programName = props.programName;
    const data = props.programSalesData;
    const colors = props.programSalesColors;
    const checkStates = props.labelCheckStates;
    // const data = [
    //     { "name": "Workout", "data": { "2017-01-01": 3, "2017-01-02": 4, "2017-01-03": 7, "2017-01-04": 9, "2017-01-05": 3, } },
    //     { "name": "Call parents", "data": { "2017-01-01": 5, "2017-01-02": 3, "2017-01-03": 4, "2017-01-04": 2, "2017-01-05": 3, } }
    // ];
    const [graphData, setGraphData] = React.useState([]);
    const [graphColors, setGraphColors] = React.useState([]);
    const [labelsChecked, setLabelsChecked] = React.useState([]);

    React.useEffect(() => {
        if (data.length > 0 && colors.length > 0) {
            setGraphData(data);
            setGraphColors(colors);
            setLabelsChecked(checkStates);
        }
    }, [data, colors, checkStates])


    const handleClick = (i) => {
        let tempCheckStates = labelsChecked.map((check, index) => {
            return index === i ? !check : check;
        });
        setLabelsChecked(tempCheckStates);

        const check = labelsChecked[i];

        let temp = graphData.map(oneData => { return oneData });
        let tempColors = graphColors.map(color => { return color });
        if (check) {
            if (i < temp.length) {
                temp.splice(i, 1);
                tempColors.splice(i, 1);
            } else {
                temp.pop();
                tempColors.pop();
            }
        } else {
            if (i < temp.length) {
                temp.splice(i, 0, data[i]);
                tempColors.splice(i, 0, colors[i]);
            } else {
                temp.push(data[i]);
                tempColors.push(colors[i]);
            }
        }
        setGraphData(temp);
        setGraphColors(tempColors);
    }

    return (
        <div style={{ background: "white", borderRadius: "5px", padding: "49px 30px" }}>
            <IconButton aria-label="Settings" style={{ float: "right", marginTop: "-15px", marginRight: "-25px" }}>
                <MoreVertIcon />
            </IconButton>
            <div className="row programchart_title" >
                <div className="col-sm-6">
                    <h5 style={{ fontSize: "18px", fontWeight: "bold", color: "#333333" }}
                    >
                        {programName} Sales
                    </h5>
                </div>
                <div className="col-sm-6">
                    <div className="row">
                        {
                            data.length > 0 ?
                                data.map((oneData, i) => {
                                    return <div key={i} className="col-sm-6 col-lg-4">
                                        <LineChartLabel
                                            color={colors[i]}
                                            title={oneData.name}
                                            checked={labelsChecked[i]}
                                            fontColor={"#666666"}
                                            fontSize="12px"
                                            fontWeight="normal"
                                            handleClick={() => handleClick(i)}
                                        />
                                    </div>
                                }) : null
                        }
                    </div>
                </div>

            </div>
            <LineChart data={graphData} colors={graphColors} legend={false} />
        </div>
    )
}
