import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
// import { Link, useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import RoundTextField from "../RoundTextField";
import app from "../../components/base";
import ls from "local-storage";

function AuthOrganization(props) {
    const initialValues = ls.get('youthrush_org');
    const { intl } = props;
    return (
        <>
            <div style={{ textAlign: "center", marginTop: "70px" }} >
                <h4
                    onClick={() => {
                        ls.set("youthrush_org", null);
                        props.skipFunction();
                    }}
                    style={{
                        textDecoration: "underline",
                        fontSize: "21px",
                        fontWeight: "normal",
                        color: "#014386",
                        fontFamily: "Open Sans",
                        cursor: "pointer"
                    }}
                >
                    Skip this step and enter later (Non-Organization Entity)
                </h4>
            </div>
            <div className="row" style={{ marginTop: "54px" }}>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <Formik
                        initialValues={initialValues || {
                            orgname: "", 
                            orgaddress1: "", 
                            orgaddress2: "", 
                            city: "", 
                            zip: "", 
                            state: "", 
                            country: ""
                        }}
                        validate={values => {
                            const errors = {};

                            if (!values.orgname) {
                                errors.orgname = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.orgaddress1 && !values.orgaddress2) {
                                errors.orgaddress1 = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                                errors.orgaddress2 = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.city) {
                                errors.city = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (!values.zip) {
                                errors.zip = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (!values.state) {
                                errors.state = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (!values.country) {
                                errors.country = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            app.auth().onAuthStateChanged(function (user) {
                                if (user != null) {
                                    var usersRef = app.database().ref().child("admins");
                                    usersRef.child(user.uid).update(values);
                                    ls.set('youthrush_org', values);
                                    props.skipFunction();
                                } else {
                                    // alert("Login failed");
                                }
                            });
                        }}
                    >
                        {({
                            values,
                            status,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    {status && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{status}</div>
                                        </div>
                                    )}

                                    <div className="form-group mb-0">
                                        <RoundTextField
                                            type="text"
                                            margin="normal"
                                            headerLabel="Organization Name"
                                            name="orgname"
                                            placeholder="Enter Organization Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.orgname}
                                            helperText={touched.orgname && errors.orgname}
                                            error={Boolean(touched.orgname && errors.orgname)}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <RoundTextField
                                            type="text"
                                            margin="normal"
                                            name="orgaddress1"
                                            headerLabel="Organization Address"
                                            placeholder="Address1"
                                            lastStyle={{}}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.orgaddress1}
                                            helperText={touched.orgaddress1 && errors.orgaddress1}
                                            error={Boolean(touched.orgaddress1 && errors.orgaddress1)}
                                        />
                                    </div>
                                    <div className="form-group mb-0">
                                        <RoundTextField
                                            type="text"
                                            margin="normal"
                                            name="orgaddress2"
                                            placeholder="Address2"
                                            lastStyle={{}}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.orgaddress2}
                                            helperText={touched.orgaddress2 && errors.orgaddress2}
                                            error={Boolean(touched.orgaddress2 && errors.orgaddress2)}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-0 col-6">
                                            <RoundTextField
                                                type="text"
                                                margin="normal"
                                                name="city"
                                                placeholder="City"
                                                lastStyle={{}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.city}
                                                helperText={touched.city && errors.city}
                                                error={Boolean(touched.city && errors.city)}
                                            />
                                        </div>
                                        <div className="form-group mb-0 col-6">
                                            <RoundTextField
                                                type="text"
                                                margin="normal"
                                                name="zip"
                                                placeholder="zip"
                                                lastStyle={{}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.zip}
                                                helperText={touched.zip && errors.zip}
                                                error={Boolean(touched.zip && errors.zip)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-0 col-6">
                                            <RoundTextField
                                                type="text"
                                                margin="normal"
                                                name="state"
                                                placeholder="State"
                                                lastStyle={{}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.state}
                                                helperText={touched.state && errors.state}
                                                error={Boolean(touched.state && errors.state)}
                                            />
                                        </div>
                                        <div className="form-group mb-0 col-6">
                                            <RoundTextField
                                                type="text"
                                                margin="normal"
                                                name="country"
                                                placeholder="Country"
                                                lastStyle={{}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.country}
                                                helperText={touched.country && errors.country}
                                                error={Boolean(touched.country && errors.country)}
                                            />
                                        </div>
                                    </div>
                                    <div className="kt-login__actions" style={{ marginTop: "25px", marginBottom: "25px" }}>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="btn btn-primary btn-elevate kt-login__btn-primary"
                                            style={{ width: "100%", borderRadius: "20px", height: "50px" }}
                                        >
                                            Next
                                    </button>
                                    </div>
                                </form>
                            )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default injectIntl(
    connect(
        null,
        []
    )(AuthOrganization)
);