import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import LargeSearchBar from './common_component/LargeSearchBar';
import WhiteButton from "./common_component/WhiteButton";
import SelectTableEntryCountBar from "./inventory_component/SelectTableEntryCountBar";
import InterestTypesTable from "./interest_component/InterestTypesTable";
import { useHistory } from "react-router-dom";
import app from "../../components/base";

export default function InterestTypes() {
    const history = useHistory();
    const { programId } = useParams();

    const [rows, setRows] = useState({});
    const [stateFlag, setStateFlag] = useState(false);

    // function createData(category, products, color, parent, tags) {
    //     return { category, products, color, parent, tags };
    // }

    // const rows = [
    //     createData('Message', 5, "#F76C2E", '', ''),
    //     createData('Health', 5, '#51A1E3', '', ''),
    //     createData('Childrens', 5, '#8DE08C', '', ''),
    //     createData('Dropdown', 5, '#9F9D4A', '', ''),
    // ];

    useEffect(() => {
        if (!stateFlag) {
            setStateFlag(true);
            const programRef = app.database().ref().child('programs/' + programId);
            programRef.once('value', snapshot => {
                const program = snapshot.val();
                const activeIntTypeIds = program.activeIntTypeIds ? program.activeIntTypeIds : [];

                app.database().ref('interestTypes').once('value')
                    .then(snapshot => {
                        const rows = snapshot.val();
                        if (rows) {
                            Object.keys(rows).forEach((key, i) => {
                                rows[key].active = activeIntTypeIds.indexOf(key) > -1;
                            })
                        }
                        setRows(rows);
                    })

            })

        }

    }, [stateFlag, programId])


    return (
        <div className="row">
            <div className="col-xl-9">
                <div className="row inventory_submenu">
                    <div className="col-md-4">
                        <h4
                            style={{
                                fontSize: "30px",
                                fontWeight: "normal",
                                color: "#111111",
                                marginRight: "36px"
                            }}
                        >
                            All Interest Types
                    </h4>
                    </div>
                    <div className="col-md-8">
                        <LargeSearchBar placeholder="Search interest type, tag" />
                    </div>
                </div>

                <div className="row button_bar" style={{ marginTop: "43px" }}>
                    <div className="col-sm-4">
                        <WhiteButton caption="View All Interest Types" />
                    </div>
                    <div className="col-sm-4">
                        <WhiteButton handleClick={() => { history.push(`/add-new-interest-type/${programId}`) }} caption="Add New Interest Type" addIcon />
                    </div>
                </div>

                <div className="product_list" style={{ marginTop: "62px", background: "white", padding: "26px 29px" }}>
                    <h5 style={{ fontSize: "14px", fontWeight: "bold", color: "#333333", marginBottom: "30px" }}>Interest Type Listing</h5>
                    <SelectTableEntryCountBar />
                    <div style={{ height: "25px" }}></div>
                    <InterestTypesTable rows={rows} programId={programId} />
                </div>
            </div>
        </div>
    )
}
