import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

export default function WithHeaderWindow(props) {
    return (
        <div style={props.style}>
            <div style={{
                height: 55,
                background: "rgba(54, 68, 92, 0.83)",
                paddingLeft: 33,
                paddingRight: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <span
                    style={{
                        fontSize: "22px",
                        fontWeight: "500",
                        color: "white"
                    }}
                >
                    {props.title}</span>

                {props.penIcon ?
                    <IconButton style={{ float: "right" }}>
                        <EditIcon style={{ color: "white", fontSize: "25px" }} />
                    </IconButton>
                    : null
                }
            </div>
            <div
                style={{ background: "white" }}
            >
                {props.children}
            </div>
        </div >
    );
}