import React from 'react';
import { Card, CardHeader, IconButton, CardContent } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuIcon from "@material-ui/icons/Menu";
import StatisticsSlider from "../dashboard_component/StatisticsSlider";
import "../css/dashboard_statistics.scss";

export default function DashboardStatistics(props) {
    const totalProgramAmount = props.totalProgramAmount;
    const totalStudents = props.totalStudents;
    const programAmountOfLastWeek = props.programAmountOfLastWeek;
    const averageAmountOfLastWeek = programAmountOfLastWeek / totalStudents;
    const todayTransactions = props.todayTransactions;

    return (
        <div className="row dashboard_statistics">
            <div className="col-md-6" style={{ marginTop: "20px" }}>
                <Card style={{ paddingTop: "19px", paddingBottom: "30px", fontSize: "20px", fontWeight: "bold" }}>
                    <CardHeader
                        style={{
                            paddingLeft: "30px",
                            paddingRight: "10px",
                            paddingBottom: "9px"
                        }}

                        action={
                            <IconButton aria-label="Settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Total Program Sales"
                        titleTypographyProps={{ variant: 'h4' }}
                    />

                    <CardContent
                        style={{
                            paddingTop: "9px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}
                    >
                        <StatisticsSlider title={`$${totalProgramAmount.toFixed(2)}`} value={36} />
                        <div>
                            <div style={{ float: "left" }}>
                                <span>36%</span> Of program Goal
                            </div>
                            <div style={{ float: "right" }}>
                                To Goal: <span>$65,580</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3" style={{ marginTop: "20px" }}>
                <Card style={{ paddingTop: "19px", paddingBottom: "30px", fontSize: "20px", fontWeight: "bold" }}>
                    <CardHeader
                        style={{
                            paddingLeft: "30px",
                            paddingRight: "10px",
                            paddingBottom: "9px"
                        }}
                        action={
                            <IconButton aria-label="Settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Student Average"
                        titleTypographyProps={{ variant: 'h4' }}
                    />

                    <CardContent
                        style={{
                            paddingTop: "9px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}
                    >
                        <StatisticsSlider title={`$${averageAmountOfLastWeek.toFixed(2)}`} value={10.4} />
                        <div>
                            <div style={{ float: "left" }}>
                                <span style={{ fontSize: "14px", fontWeight: "normal", color: "#FD5722" }}>+</span>
                                <span>10.4%</span>
                            </div>
                            <div style={{ float: "right", marginTop: 2 }}>
                                Last week
                                <MenuIcon />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3" style={{ marginTop: "20px" }}>
                <Card style={{ paddingTop: "19px", paddingBottom: "30px", fontSize: "20px", fontWeight: "bold" }}>
                    <CardHeader
                        style={{
                            paddingLeft: "30px",
                            paddingRight: "10px",
                            paddingBottom: "9px"
                        }}
                        action={
                            <IconButton aria-label="Settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Total Transactions"
                        titleTypographyProps={{ variant: 'h4' }}
                    />

                    <CardContent
                        style={{
                            paddingTop: "9px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}
                    >
                        <StatisticsSlider title={todayTransactions} value={46} />
                        <div>
                            <div style={{ float: "left" }}>
                                <span style={{ fontSize: "14px", fontWeight: "normal", color: "#43C702" }}>+</span>
                                <span>46%</span>
                            </div>
                            <div style={{ float: "right", marginTop: 2 }}>
                                Today
                                <MenuIcon />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}
